import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useParams,
    useNavigate
} from "react-router-dom";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { Tooltip } from "react-tooltip";
import { DialogTitle } from "@headlessui/react";

import * as hi from "@heroicons/react/24/outline";
import {
    TbList,
    TbTable,
    TbTablePlus
} from "react-icons/tb";
import {
    ChevronDownIcon,
    ChevronUpIcon,
    DocumentTextIcon,
    PencilIcon,
    RocketLaunchIcon,
    TrashIcon
} from "@heroicons/react/24/outline";
import {
    Cog6ToothIcon,
    XMarkIcon
} from "@heroicons/react/24/solid";
import { QuestionMarkCircleIcon } from "@heroicons/react/20/solid";

import * as t from "../lib/types";
import * as c from "../lib/consts";
import {
    Backend,
    BackendObj
} from "../lib/backend";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    classNames,
    isValidCodeName,
    newUuid,
    setDocumentTitle,
    isFlatField,
    deepCopyTyped
} from "../lib/utils";
import {
    IContextFieldOverride,
    IContextOverride,
    IContextOverrideTrigger,
    IContextRowValidator,
    IContextValidator,
    ILookupMapFilter,
    IScrapesRenderColumnConfig
} from "../lib/backend/extractions.types.generated";
import { canMoveContextBeforeRest } from "../lib/scraper_utils";

import {
    Button,
    ButtonGroup
} from "../components/Button";
import { FieldSettings, FieldsTable } from "../components/FieldsTable";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { OrgPill } from "../components/OrgPill";
import { TemplateFacts } from "../components/TemplateFacts";
import {
    CompactTabs,
    ITab,
    Tabs
} from "../components/Tabs";
import { EndpointOutputColumns } from "../components/EndpointOutputColumns";
import { Dropdown } from "../components/Dropdown";
import {
    WizardButtonIcon,
    WizardDocumentButtonImage
} from "../components/WizardButton";
import { ConfirmModal } from "../components/ConfirmModal";
import { HierarchicalFieldsTable } from "../components/HierarchicalFieldsTable";
import { Textbox } from "../components/Textbox";
import { Checkbox } from "../components/Checkbox";
import { ErrorMessageBar } from "../components/ErrorMessageBar";
import AuditLogHistory, { AuditLogEntity } from "./AuditLogHistory";
import { NewFieldOverrideDialog } from "../components/OverrideDialog";
import {
    SidePanel,
    SidePanelRaw
} from "../components/SidePanel";
import {
    ProcessFlowBody,
    ProcessFlowHead
} from "../components/ProcessFlow";
import {
    ContextDetailsSettings,
    ContextRules,
    LookupTableContextDetails,
    NewContextModal,
    NewContextModalButton
} from "../components/ContextDetails";
import { ExtractButton } from "../components/ExtractButton";
import { IExample, TemplateEval } from "../components/TemplateEval";
import { PageFilters } from "../components/PageFilters";
import { TwoRowHeader } from "../components/Header";

export const DEFAULT_NEW_TEMPLATE: t.ITemplateNoUUID = {
    org_uuid: "",
    name: "",
    details: c.DEFAULT_PREPROCESS_PARAMS,
    facts: []
}

export const DEFAULT_NEW_CONTEXT: t.IContextNoUUID = {
    name: "",
    code: "",
    org_uuid: "",
    facts: [],
    fields: [{ uuid: "", name: "", datatype: "string", type: "extract" }],
    postprocess: {},
    row_validators: [],
    context_validators: [],
    type: c.CONTEXT_TYPES.array,
    weight_score: 0,
    extract_params: {
        prompt_output_format: "tsv",
        remove_duplicate_records: false,
        default_decimal_separator: c.DEFAULT_DECIMAL_SEPARATOR,
        detect_decimal_separator: true,
        try_auto_heal: true,
        extraction_strategy: "standard",
        max_partial_responses: 5,
        models_overrides: {},
        admin_prompts: {},
        lookup_table_filter: {},
        classifiers: [],
        input_page_filters: { filters: [], invert: false },
        context_injects: [],
        omit_input_text: false,
        skip_on_confirm: false,
        ok_to_be_empty: false
    },
    overrides: []
}

export function createNewDefaultContext(context_type?: t.ContextType): t.IContextNoUUID & { uuid: string } {
    const context: t.IContextNoUUID & { uuid: string } = {
        ...deepCopyTyped(DEFAULT_NEW_CONTEXT),
        uuid: "",
        type: context_type ?? c.CONTEXT_TYPES.array
    };
    if (context.type === c.CONTEXT_TYPES.classifier) {
        context.name = "Classification";
    }
    for (const field of context.fields) {
        field.uuid = newUuid();
    }
    return context;
}

type TemplateProperties = "scrape_debug" | "preprocess_excel_strategy" | "preprocess_ocr_strategy" | "orientation_segments_strategy" | "preprocess_ocr_table_strategy";

interface IOverrideContext {
    type: "context";
    context_idx: number;
    context: t.IContextNoUUID & { uuid: string };
    idx: number;
    value: IContextOverride;
    changes: string[];
}

interface IOverrideField {
    type: "field";
    context_uuid: string;
    field: t.IContextField;
    idx: number;
    value: IContextFieldOverride;
    changes: string[];
}

export function NewTemplate() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === c.ORG_TYPES.business && (membership.role === c.ORG_ROLES.editor || membership.role === c.ORG_ROLES.admin))?.org.uuid ||
        memberships.find((membership) => membership.org.type === c.ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";
    // get list of admin or editor orgs, since only admin can create or edit templates
    const admin_orgs = memberships.filter((m) => (m.role === c.ORG_ROLES.admin || m.role === c.ORG_ROLES.editor)).map((m) => m.org);
    // check if we have a non-personal org, to make it worthwhile to show org selector
    const is_business_orgs = admin_orgs.some((org) => org.type === c.ORG_TYPES.business);

    // check if user is admin
    const is_admin = user.role === c.USER_ROLES.admin;

    // parse parameters from URL and props
    const { init_template_type, template_uuid } = useParams<{ init_template_type: string | undefined, template_uuid: string | undefined }>();

    const [is_init, setIsInit] = useState<boolean>(true);
    const [selected_tab_key, setSelectedTab] = useState<string>("basic_info");
    const [is_edit_template_valid, setIsEditTemplateValid] = useState<boolean | undefined>(undefined);
    const [org_uuid, setOrgUuid] = useState<string>(default_org_uuid);
    const [template_name, setTemplateName] = useState<string>("");
    const [template_details, setTemplateDetails] = useState<t.ITemplateDetails>(deepCopyTyped(DEFAULT_NEW_TEMPLATE.details));
    const [template_facts, setTemplateFacts] = useState<t.IContextFact[]>([]);
    const [contexts, setContexts] = useState<(t.IContextNoUUID & { uuid: string })[]>([createNewDefaultContext()]);
    const [init_contexts_consistent, setInitContextsConsistent] = useState<boolean>(true);
    const [selected_details_context_idx, setSelectedDetailsContextIdx] = useState<number | undefined>(undefined);
    const [context_details_selected_tab, setContextDetailsSelectedTab] = useState<string>("general");
    const [examples, setExamples] = useState<IExample[] | undefined>(undefined);
    const [contexts_metrics, setContextsMetrics] = useState<t.IContextEvalMetrics[] | undefined>(undefined);
    const [is_processing, setIsProcessing] = useState<boolean>(false);
    const [is_committing, setIsCommitting] = useState<boolean>(false);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [all_lookup_tables, setAllLookupTables] = useState<t.ILookupTableBase[]>([]);
    const [models, setModels] = useState<t.IModel[]>([]);
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);
    const [is_new_field_override_open, setIsNewFieldOverrideOpen] = useState<boolean>(false);
    const [override_settings_idx, setOverrideSettingsIdx] = useState<number | undefined>(undefined);
    const [is_valid_admin_json, setIsValidAdminJSON] = useState<boolean>(true);
    const [is_valid_admin_json_template, setIsValidAdminJSONTemplate] = useState<boolean>(true);
    const [is_back_modal_open, setIsBackModalOpen] = useState<boolean>(false);
    const [is_new_context_modal_open, setIsNewContextModalOpen] = useState<boolean>(false);

    const tabs: ITab[] = [
        { name: "Process Template", key: "basic_info" },
        { name: "Details", key: "details" },
        { name: "Evaluate", key: "examples" },
        { name: "History", key: "history", only_admin: true }
    ];

    const context_details_tabs: ITab[] = [
        { name: "General", hide: (selected_details_context_idx === undefined || contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.classifier), key: "general" },
        {
            name: "Context", key: "context_injects", hide:
                selected_details_context_idx === undefined ||
                selected_details_context_idx === 0 ||
                contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.lookup_table ||
                contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.classifier
        },
        { name: "Rules", hide: (selected_details_context_idx === undefined || contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.classifier), key: "rules" },
        { name: "Details", key: "details" }
    ];

    useEffect(() => {
        if (selected_details_context_idx !== undefined) {
            const is_classifier = contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.classifier;
            const selected_tab_idx = context_details_tabs.findIndex((tab) => tab.key === context_details_selected_tab);

            if (selected_tab_idx === -1 || context_details_tabs[selected_tab_idx].hide) {
                // Set "details" as default for classifier type, otherwise use "general"
                setContextDetailsSelectedTab(is_classifier ? "details" : "general");
            }
        }
    }, [selected_details_context_idx]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setErrorMessage(undefined);
        if (template_uuid !== undefined) {
            BackendObj.extractions.getTemplate({ template_uuid }).then(({ template }) => {
                if (template === undefined) {
                    setIsEditTemplateValid(false);
                } else {
                    setIsEditTemplateValid(true);
                    setOrgUuid(template.org_uuid);
                    setTemplateName(template.name);
                    setTemplateDetails(template.details);
                    setTemplateFacts(template.facts);
                    // we sort contexts by weight score and then reset the weight score to the index
                    const sorted_contexts = template.contexts
                        .map((context) => ({ ...context }))
                        .sort((a, b) => a.weight_score - b.weight_score)
                        .map((context, idx) => ({ ...context, weight_score: idx }));
                    setContexts(sorted_contexts);
                    // check if the loaded contexts are consistent with respect to field references
                    const fields_consistency_message = checkContextsFieldsConsistency(sorted_contexts);
                    setInitContextsConsistent(fields_consistency_message === undefined);
                    if (is_admin && fields_consistency_message !== undefined) {
                        setErrorMessage(`ADMIN: ${fields_consistency_message}`);
                    }
                    setExamples(undefined);
                }
            });
        } else {
            setIsEditTemplateValid(false);
            setOrgUuid(default_org_uuid);
            setTemplateName("");
            setTemplateDetails(deepCopyTyped(DEFAULT_NEW_TEMPLATE.details));
            setTemplateFacts([]);
            setContexts([createNewDefaultContext()]);
            setInitContextsConsistent(true);
            setExamples(undefined);
        }
        setIsValidAdminJSON(true);
    }, [template_uuid, default_org_uuid]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        const fetchTemplate = async () => {
            if (template_uuid === undefined && init_template_type !== undefined && is_init) {
                try {
                    const new_template = await BackendObj.extractions.getWizardTemplate({
                        wizard_template_type: init_template_type as t.WizardTemplateType
                    });
                    setTemplateName(deepCopyTyped(new_template.wizard_template.template_name));
                    setTemplateDetails(deepCopyTyped(DEFAULT_NEW_TEMPLATE.details));
                    setTemplateFacts([]);
                    const new_contexts = new_template.wizard_template.contexts.map((new_context, idx) => ({
                        ...createNewDefaultContext(),
                        name: new_context.context_name,
                        fields: deepCopyTyped(new_context.fields),
                        type: deepCopyTyped(new_context.type),
                        weight_score: idx
                    }));
                    setContexts(new_contexts);
                    // check if the new contexts are consistent with respect to field references
                    const fields_consistency_message = checkContextsFieldsConsistency(new_contexts);
                    setInitContextsConsistent(fields_consistency_message === undefined);
                    if (is_admin && fields_consistency_message !== undefined) {
                        setErrorMessage(`ADMIN: ${fields_consistency_message}`);
                    }
                    setExamples(undefined);
                } catch (error) {
                    console.error("Error fetching template:", error);
                }
            }
        };
        fetchTemplate();
    }, [template_uuid, init_template_type, is_init]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (template_uuid === undefined) {
            setDocumentTitle("New Process Template", env);
        } else if (is_edit_template_valid) {
            setDocumentTitle(`Edit Process Template - ${template_name}`, env);
        }
    }, [template_uuid, template_name, is_edit_template_valid, env]);

    useEffect(() => {
        Backend.getLookupTables().then((tables) => {
            setAllLookupTables(tables);
        });

    }, [org_uuid]);

    useEffect(() => {
        BackendObj.extractions.getModels({})
            .then(({ models }) => {
                setModels(models);
            });
    }, []);

    const is_edit = template_uuid !== undefined;
    const lookup_tables = all_lookup_tables.filter((table) => table.org_uuid === org_uuid);

    const trimContextFields = (fields: t.IContextField[]): { new_fields: t.IContextField[], is_modified: boolean } => {
        const new_fields = fields.filter((field) => field.name.length > 0);
        let is_modified = fields.length !== new_fields.length;
        for (const field of new_fields) {
            // trim field name
            const field_name = field.name.trim();
            is_modified = is_modified || field.name !== field_name;
            field.name = field_name;
            // trim format if we have it
            if (field.extract && field.extract.format) {
                const field_format = field.extract.format.trim();
                is_modified = is_modified || field.extract.format !== field_format;
                field.extract.format = field_format;
            }
        }
        return { new_fields, is_modified };
    };

    const trimContextsFields = (): (t.IContextNoUUID & { uuid: string })[] => {
        const modified_contexts = [];
        for (const context of contexts) {
            const { new_fields, is_modified } = trimContextFields(context.fields);
            if (is_modified && new_fields.length > 0) {
                setIsInit(false);
                modified_contexts.push({ ...context, fields: new_fields });
            } else {
                modified_contexts.push(context);
            }
        }
        // add a new context if we have no contexts
        if (modified_contexts.length === 0) {
            modified_contexts.push(createNewDefaultContext());
        }
        modified_contexts.forEach((context, idx) => context.weight_score = idx);
        setContexts(modified_contexts);
        return modified_contexts;
    };

    const onCommit = async () => {
        setErrorMessage(undefined);
        // avoid double commit
        if (is_committing) { return; }
        setIsCommitting(true);

        try {
            // create template
            const { template_uuid: new_template_uuid } = await BackendObj.extractions.createTemplate({
                template: {
                    name: template_name,
                    org_uuid,
                    details: template_details,
                    facts: template_facts
                },
                contexts: trimContextsFields()
            });
            // redirect to edit page for the new context
            setIsInit(true);
            setIsCommitting(false);
            setIsEditTemplateValid(true);
            navigate(`/template/${new_template_uuid}/edit`);
        } catch (err: any) {
            setErrorMessage(`Failed to save template.`);
            setIsCommitting(false);
        }
    };

    const onUpdate = async () => {
        setErrorMessage(undefined);
        // make sure we have valid context uuid
        if (template_uuid === undefined) { return; }
        // avoid double commit
        if (is_committing) { return; }
        setIsCommitting(true);
        try {
            const { template } = await BackendObj.extractions.updateTemplate({
                template_uuid,
                template: {
                    name: template_name,
                    org_uuid,
                    details: template_details,
                    facts: template_facts
                },
                new_contexts: trimContextsFields().filter((context) => context.uuid === ""),
                existing_contexts: trimContextsFields().filter((context) => context.uuid !== "")
            });
            if (template !== undefined) {
                setContexts(template.contexts
                    .map((context) => ({ ...context }))
                    .sort((a, b) => a.weight_score - b.weight_score)
                    .map((context, idx) => ({ ...context, weight_score: idx }))
                );
            }
            // mark done
            setIsCommitting(false);
            setIsInit(true);
        } catch (err: any) {
            setErrorMessage(`Failed to update template.`);
            setIsCommitting(false);
        }
    };

    const handleEvaluateTemplate = async (example_item_uuids: string[]): Promise<string | undefined> => {
        if (template_uuid === undefined) { return; }
        setErrorMessage(undefined);
        const { job_uuid } = await BackendObj.extractions.evaluateTemplate({
            template_uuid,
            template_name,
            template_details: template_details,
            template_facts: template_facts,
            contexts: trimContextsFields(),
            example_item_uuids,
            org_uuid
        });
        return job_uuid;
    }

    const handleOrgChange = (org_uuid: string) => {
        setIsInit(false);
        setOrgUuid(org_uuid);
        setContexts(prev_context => prev_context.map((context) => ({ ...context, org_uuid })));
    }

    const newContext = (type: t.ContextType | "none") => {
        setIsNewContextModalOpen(false);
        if (type === "none") { return; }
        setIsInit(false);
        setContexts(prev_context => {
            const new_context = createNewDefaultContext(type);
            const org_lookup_tables = all_lookup_tables.filter((table) => table.org_uuid === org_uuid);
            if (type === c.CONTEXT_TYPES.lookup_table && org_lookup_tables.length > 0) {
                const lookup_table = org_lookup_tables[0];
                new_context.fields = getLookupTableFields(lookup_table);
                new_context.extract_params.lookup_table_filter = { lookup_table_uuid: lookup_table.uuid };
            }
            const new_contexts = new_context.type === c.CONTEXT_TYPES.classifier ? [new_context, ...prev_context] : [...prev_context, new_context];
            new_contexts.forEach((context, idx) => context.weight_score = idx);
            return new_contexts;
        });
    }

    const moveContext = (idx: number, direction: "up" | "down") => {
        setIsInit(false);
        if (direction === "up" && idx > 0) {
            // validate we can move context up the steps
            const message = canMoveContextBeforeRest(contexts[idx], [contexts[idx - 1]]);
            if (message) { setErrorMessage(message); return; }
            // we can
            setContexts(prev_context => {
                const new_contexts = [...prev_context];
                new_contexts.splice(idx - 1, 0, new_contexts.splice(idx, 1)[0]);
                new_contexts.forEach((context, idx) => context.weight_score = idx);
                return new_contexts;
            });
            setSelectedDetailsContextIdx(idx - 1);
        } else if (direction === "down" && idx < contexts.length - 1) {
            // validate we can move context down the steps
            const message = canMoveContextBeforeRest(contexts[idx + 1], [contexts[idx]]);
            if (message) { setErrorMessage(message); return; }
            // we can
            setContexts(prev_context => {
                const new_contexts = [...prev_context];
                new_contexts.splice(idx + 1, 0, new_contexts.splice(idx, 1)[0]);
                new_contexts.forEach((context, idx) => context.weight_score = idx);
                return new_contexts;
            });
            setSelectedDetailsContextIdx(idx + 1);
        }
    }

    const removeContext = (idx: number) => {
        // we do not allow to remove the last context
        if (contexts.length === 1) { return; }
        // check if we can remove the context or if some other context depends on it
        const remove_context_uuid = contexts[idx].uuid;
        for (const [context_idx, context] of contexts.entries()) {
            if (context_idx === idx) { continue; }
            // check lookup table filters
            if (context.type === "lookup_table") {
                for (const key of context.extract_params.lookup_table_filter?.keys ?? []) {
                    if (contexts[idx].fields.some((field) => field.uuid === key.field_uuid)) {
                        setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${context_idx + 1} uses it in a lookup cascade.`);
                        return;
                    }
                }
            }
            // check overrides
            for (const override of context.overrides) {
                if (override.override_trigger.data.context_uuid === remove_context_uuid) {
                    setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${context_idx + 1} exception rules depend on it.`);
                    return;
                }
            }
            // check injects
            for (const inject of context.extract_params.context_injects) {
                if (inject.context_uuid === remove_context_uuid) {
                    setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${context_idx + 1} uses it as an additional context.`);
                    return;
                }
            }
            // check fallback sequence
            for (const field of context.fields) {
                if (field.compute?.type === "fallback_sequence" && field.compute.fallback_sequence !== undefined) {
                    for (const fallback of field.compute.fallback_sequence.sequence) {
                        if (fallback.type === "fallback_field") {
                            // check if fallback.field_uuid is in the context
                            if (contexts[idx].fields.some((field) => field.uuid === fallback.field_uuid)) {
                                setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${context_idx + 1} uses it in a lookup cascade.`);
                                return;
                            }
                        } else if (fallback.type === "fallback_lookup_map") {
                            // check if any filter references a field from contexts[idx]
                            for (const filter of fallback.filters) {
                                if (filter.field_uuid !== undefined && contexts[idx].fields.some((field) => field.uuid === filter.field_uuid)) {
                                    setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${context_idx + 1} uses it in a lookup cascade.`);
                                    return;
                                }
                            }
                        }
                    }
                    // check final_fallback_field_uuid
                    if (field.compute?.fallback_sequence?.final_fallback_field_uuid !== undefined) {
                        if (contexts[idx].fields.some((field) => field.uuid === field.compute?.fallback_sequence?.final_fallback_field_uuid)) {
                            setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${context_idx + 1} uses it in a lookup cascade.`);
                            return;
                        }
                    }
                }
            }
        }
        // check if context being deleted is classifier and if any other classifier depends on it
        if (contexts[idx].type === "classifier") {
            for (const [other_context_idx, other_context] of contexts.entries()) {
                if (other_context.uuid === remove_context_uuid) { continue; }
                if (other_context.extract_params.input_page_filters.filters.length > 0) {
                    // there can be only once classifier, so if this context filters it depends on it
                    setErrorMessage(`Cannot remove Step ${idx + 1} because Step ${other_context_idx + 1} uses it in a filter.`);
                    return;
                }
            }
        }
        setErrorMessage(undefined);
        // remove context
        setIsInit(false);
        setContexts(prev_context => {
            const new_contexts = prev_context.filter((_context, i) => i !== idx);
            new_contexts.forEach((context, idx) => context.weight_score = idx);
            return new_contexts;
        });
        if (idx > 0) {
            setSelectedDetailsContextIdx(idx - 1);
        }
    }

    const handleContextNameChange = (context_idx: number, name: string) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ? { ...context, name } : context));
    }

    const handleFieldsChange = (context_idx: number, fields: t.IContextField[]): Promise<boolean> => {
        setIsInit(false);
        return new Promise(resolve => {
            setContexts(prev_context => {
                const new_contexts = prev_context.map((context, idx) => idx === context_idx ? { ...context, fields } : context);
                const message = checkContextsFieldsConsistency(new_contexts);
                if (message) {
                    // when initial context was consistent, we do not allow it to become inconsistent
                    if (init_contexts_consistent) {
                        setErrorMessage(message);
                        resolve(false);
                        return prev_context;
                    } else if (is_admin) {
                        // when admin, we provide warning
                        setErrorMessage(`ADMIN: ${message}`);
                    }
                }
                resolve(true);
                return new_contexts;
            });
        });
    }

    const handleFieldContextChange = (context_idx: number, field_uuid: string, context_uuid: string) => {
        setIsInit(false);
        setContexts((prev_contexts) => {
            // get old and new context
            const old_context = prev_contexts[context_idx];
            if (old_context === undefined) { return prev_contexts; }
            const new_context = prev_contexts.find((context) => context.uuid === context_uuid);
            if (new_context === undefined) { return prev_contexts; }
            // get field
            const field = old_context.fields.find((field) => field.uuid === field_uuid);
            if (field === undefined) { return prev_contexts; }
            // remove field from old context
            old_context.fields = old_context.fields.filter((field) => field.uuid !== field_uuid);
            // add field to new context
            new_context.fields = [...new_context.fields, field];
            // check if new context is valid
            const new_contexts = prev_contexts.map((context) =>
                context.uuid === old_context.uuid ? old_context :
                    (context.uuid === new_context.uuid ? new_context : context));
            const message = checkContextsFieldsConsistency(new_contexts);
            if (message) {
                if (init_contexts_consistent) {
                    setErrorMessage(message);
                    return prev_contexts;
                } else if (is_admin) {
                    setErrorMessage(`ADMIN: ${message}`);
                }
            }
            // return new contexts
            return new_contexts;
        });
    }

    const handleFactsChange = (context_idx: number, facts: t.IContextFact[]) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ? { ...context, facts } : context));
    }

    const handleTemplateFactsChange = (facts: t.IContextFact[]) => {
        setIsInit(false);
        setTemplateFacts(facts);
    }

    const handleLookupTableFilterChange = (context_idx: number, lookup_table_uuid: string) => {
        setIsInit(false);
        const lookup_table = lookup_tables.find((table) => table.uuid === lookup_table_uuid);
        if (lookup_table === undefined) { return; }
        setContexts(prev_contexts => {
            const new_contexts = prev_contexts.map((context, idx) => idx === context_idx ? {
                ...context,
                // if we change lookup table, we need to update the fields to match the new lookup table
                fields: (context.extract_params.lookup_table_filter.lookup_table_uuid === lookup_table_uuid) ?
                    context.fields : getLookupTableFields(lookup_table),
                extract_params: {
                    ...context.extract_params,
                    lookup_table_filter: { ...context.extract_params.lookup_table_filter, lookup_table_uuid }
                }
            } : context);
            // check if new contexts are valid
            const message = checkContextsFieldsConsistency(new_contexts);
            if (message) {
                if (init_contexts_consistent) {
                    setErrorMessage(message);
                    return prev_contexts;
                } else if (is_admin) {
                    setErrorMessage(`ADMIN: ${message}`);
                }
            }
            return new_contexts;
        });
    }

    const handleLookupTableKeysChange = (context_idx: number, keys: ILookupMapFilter[]) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ? {
            ...context,
            extract_params: {
                ...context.extract_params,
                lookup_table_filter: { ...context.extract_params.lookup_table_filter, keys }
            }
        } : context));
    }

    const handleTemplateDetailsChange = (key: TemplateProperties, value: boolean | string) => {
        setIsInit(false);
        if (key === "scrape_debug") {
            setTemplateDetails({ ...template_details, scrape_debug: value as boolean });
        } else if (key === "preprocess_excel_strategy") {
            setTemplateDetails({ ...template_details, preprocess_excel_strategy: value as t.PreprocessExcelStrategies });
        } else if (key === "preprocess_ocr_strategy") {
            setTemplateDetails({ ...template_details, preprocess_ocr_strategy: value as t.PreprocessOcrRotationStrategies });
        } else if (key === "orientation_segments_strategy") {
            setTemplateDetails({ ...template_details, orientation_segments_strategy: value as t.PreprocessOcrOrientationStrategies });
        } else if (key === "preprocess_ocr_table_strategy") {
            setTemplateDetails({ ...template_details, preprocess_ocr_table_strategy: value as t.PreprocessOcrTableStrategies });
        }
    }

    const getLookupTableFields = (lookup_table: t.ILookupTableBase): t.IContextField[] => {
        return lookup_table.headers.map((header, header_idx) => ({
            uuid: newUuid(),
            name: header,
            datatype: "string",
            type: "lookup_table_value",
            lookup_table_value: {
                value_header_idx: header_idx
            }
        }));
    }

    const handleTypeChange = (context_idx: number, new_context_type: t.ContextType) => {
        setIsInit(false);
        if (new_context_type === c.CONTEXT_TYPES.lookup_table) {
            // this is not allowed
            setErrorMessage("Cannot change step type to lookup table, must create a new step instead.");
            return;
        } else {
            setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
                { ...context, type: new_context_type } : context));
        }
    }

    const handleCodeChange = (context_idx: number, code: string) => {
        setIsInit(false);
        if (code === "" || isValidCodeName(code)) {
            setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
                { ...context, code } : context));
        }
    }

    const handleExtractParamsChange = (context_idx: number, extract_params: t.IExtractParams) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
            { ...context, extract_params } : context));
    }

    const handleRowValidatorsChange = (context_idx: number, row_validators: IContextRowValidator[]) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
            { ...context, row_validators } : context));
    }

    const handleContextValidatorsChange = (context_idx: number, context_validators: IContextValidator[]) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
            { ...context, context_validators } : context));
    }

    type ModelFields = "default" | "default_fast" | "scrape_extract" | "scrape_heal" | "scrape_summarize" | "scrape_focused_summarize" | "decimal_separator" | "oc_date_validation" | "classify_document";

    const handleModelNameChange = (context_idx: number, model_name: string, field_name: ModelFields) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
            {
                ...context,
                extract_params: {
                    ...context.extract_params,
                    models_overrides: {
                        ...context.extract_params.models_overrides,
                        [field_name]: (model_name === "/") ? undefined : model_name
                    }
                }
            } : context));
    };

    type AdminPrompts = "admin_after_scrape_system" | "admin_after_scrape_user" | "admin_after_partial_scrape_system" | "admin_after_partial_scrape_user";

    const handleAdminPromptChange = (context_idx: number, prompt_type: AdminPrompts, value: string | undefined) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
            {
                ...context,
                extract_params: {
                    ...context.extract_params,
                    admin_prompts: {
                        ...context.extract_params.admin_prompts,
                        [prompt_type]: value
                    }
                }
            } : context));
    };

    const handleEnableAdminPromptChange = (context_idx: number, prompt_type: AdminPrompts, value: boolean) => {
        setIsInit(false);
        handleAdminPromptChange(context_idx, prompt_type, value ? "" : undefined);
    }

    const handlePostprocessChange = (context_idx: number, value: string) => {
        setIsInit(false);
        setContexts(prev_context => prev_context.map((context, idx) => idx === context_idx ?
            { ...context, postprocess: value !== "" ? { formula: value } : {} } : context));
    }

    const handleOverrideSubmit = (
        selected_trigger_context_uuid: string,
        selected_trigger_field_uuid: string,
        value: string,
        override_type: "context" | "field",
        override_context_uuid: string,
        override_field_uuid: string
    ) => {
        const override_trigger: IContextOverrideTrigger = {
            type: "context_field_equals",
            data: {
                context_uuid: selected_trigger_context_uuid,
                field_uuid: selected_trigger_field_uuid,
                value: value
            }
        };
        // find override context
        const override_context = contexts.find((context) => context.uuid === override_context_uuid);
        if (override_context === undefined) { return; }
        if (override_type === "context") {
            // add override to context
            if (override_context.overrides === undefined) {
                override_context.overrides = [];
            }
            override_context.overrides.push({ override_trigger });
        } else if (override_type === "field") {
            // find override field
            const override_field = override_context.fields.find((field) => field.uuid === override_field_uuid);
            if (override_field === undefined) { return; }
            // add override to field
            if (override_field.overrides === undefined) {
                override_field.overrides = [];
            }
            override_field.overrides.push({ override_trigger });
        }
        setIsInit(false);
        setIsNewFieldOverrideOpen(false);
    }

    const deleteContextOverride = (context_uuid: string, override_idx: number) => {
        setContexts(prev_context => prev_context.map((context) => {
            if (context.uuid === context_uuid) {
                return {
                    ...context,
                    overrides: context.overrides?.filter((_, idx) => idx !== override_idx)
                };
            }
            return context;
        }));
    }

    const deleteFieldOverride = (context_uuid: string, field_uuid: string, override_idx: number) => {
        setContexts(prev_context => prev_context.map((context) => {
            if (context.uuid === context_uuid) {
                return {
                    ...context,
                    fields: context.fields.map((field, idx) => {
                        if (field.uuid === field_uuid) {
                            return {
                                ...field,
                                overrides: field.overrides?.filter((_, idx) => idx !== override_idx)
                            };
                        }
                        return field;
                    })
                };
            }
            return context;
        }));
    }

    const overrides: (IOverrideContext | IOverrideField)[] = [];
    const override_name_map: Record<string, string> = {};
    for (const [context_idx, context] of contexts.entries()) {
        override_name_map[context.uuid] = context.name;
        if (context.overrides) {
            for (const [idx, override] of context.overrides.entries()) {
                // prepare list of what is being overridden
                const override_changes: string[] = [];
                if (override.extract_params !== undefined) { override_changes.push("Extraction parameters"); }
                if (override.facts !== undefined) { override_changes.push("Facts"); }

                overrides.push({
                    type: "context",
                    context,
                    context_idx,
                    idx,
                    value: override,
                    changes: override_changes
                });
            }
        }
        for (const field of context.fields) {
            override_name_map[field.uuid] = field.name;
            if (field.overrides) {
                for (const [idx, override] of field.overrides.entries()) {
                    // prepare list of what is being overridden
                    const override_changes: string[] = [];
                    if (override.extract?.enum_data !== undefined) { override_changes.push("Labels"); }
                    if (override.extract?.guidelines !== undefined) { override_changes.push("Guidelines"); }
                    if (override.extract?.synonyms !== undefined) { override_changes.push("Synonyms"); }
                    if (override.extract?.examples !== undefined) { override_changes.push("Examples"); }
                    if (override.extract?.negative_examples !== undefined) { override_changes.push("Negative examples"); }
                    if (override.extract?.prefixes !== undefined) { override_changes.push("Prefixes"); }
                    if (override.extract?.suffixes !== undefined) { override_changes.push("Suffixes"); }
                    if (override.extract?.verbatim !== undefined) { override_changes.push("Verbatim"); }
                    if (override.extract?.format !== undefined) { override_changes.push("Format"); }
                    if (override.extract?.translate !== undefined) { override_changes.push("Translate"); }
                    if (override.compute !== undefined) { override_changes.push("computation"); }

                    overrides.push({
                        type: "field",
                        context_uuid: context.uuid,
                        field: field,
                        idx,
                        value: override,
                        changes: override_changes
                    });
                }
            }
        }
    }

    const handleOverrideSettingsContextFactsChange = (override_idx: number, new_facts: t.IContextFact[]) => {
        setIsInit(false);

        const override = overrides[override_idx];
        if (override.type !== "context") { return; }

        setContexts(prev_context => prev_context.map((context) => {
            if (context.uuid === override.context.uuid) {
                return {
                    ...context,
                    overrides: context.overrides?.map((o, idx) => {
                        if (idx === override_idx) {
                            return {
                                ...o,
                                facts: new_facts
                            };
                        }
                        return o;
                    })
                };
            }
            return context;
        }));
    }

    const handleOverrideSettingsContextExtractParamsChange = (override_idx: number, new_extract_params: t.IExtractParams) => {
        setIsInit(false);

        const override = overrides[override_idx];
        if (override.type !== "context") { return; }

        setContexts(prev_context => prev_context.map((context) => {
            if (context.uuid === override.context.uuid) {
                return {
                    ...context,
                    overrides: context.overrides?.map((o, idx) => {
                        if (idx === override_idx) {
                            return {
                                ...o,
                                extract_params: new_extract_params
                            };
                        }
                        return o;
                    })
                };
            }
            return context;
        }));
    }

    const handleOverrideSettingsFieldChange = (override_idx: number, new_field: t.IContextField) => {
        setIsInit(false);

        const override = overrides[override_idx];
        if (override.type !== "field") { return; }

        setContexts(prev_context => prev_context.map((context) => {
            if (context.uuid === override.context_uuid) {
                const new_context = {
                    ...context,
                    fields: context.fields.map((field) => {
                        if (override.field.uuid === field.uuid) {
                            return {
                                ...field,
                                overrides: field.overrides?.map((o, idx) => {
                                    if (idx === override.idx) {
                                        return {
                                            override_trigger: o.override_trigger,
                                            extract: new_field.extract,
                                            compute: new_field.compute
                                        };
                                    }
                                    return o;
                                })
                            };
                        }
                        return field;
                    })
                };
                return new_context;
            }
            return context;
        }));
    }

    const handleCheckBack = () => {
        if (is_init) {
            navigate(template_uuid ? `/template/${template_uuid}` : "/templates");
        } else {
            setIsBackModalOpen(true);
        }
    }

    const handleBack = (result: boolean) => {
        if (result) {
            navigate(template_uuid ? `/template/${template_uuid}` : "/templates");
        }
        setIsBackModalOpen(false);
    }

    const validateJSFormula = async (formula: string, context_name: string) => {
        const result = await BackendObj.extractions.validateJsCode({ code: formula });
        if (!result.valid) {
            setErrorMessage(`Error in postprocessing formula for context ${context_name}: ${result.error}`);
        }
    }

    const showContextInjects = (context_idx: number): "yes" | "not_saved" | "not_applicable" => {
        // do not show context injects if some context is not saved
        if (contexts.some((context) => context.uuid === "")) { return "not_saved"; }
        return (context_idx > 0 &&
            (contexts[context_idx].type === c.CONTEXT_TYPES.object || contexts[context_idx].type === c.CONTEXT_TYPES.array)
        ) ? "yes" : "not_applicable";
    }

    const getValidContextInjectFields = (context: (t.IContextNoUUID & { uuid: string })): t.IContextField[] => {
        return context.fields
            .filter((field) =>
                context.type !== c.CONTEXT_TYPES.hierarchical ||
                isFlatField(field))
    }

    const getValidContextInjectColumns = (context: (t.IContextNoUUID & { uuid: string })): IScrapesRenderColumnConfig[] => {
        return getValidContextInjectFields(context).map((field) => ({
            name: field.name,
            type: "field",
            field: { context_uuid: context.uuid, field_uuid: field.uuid }
        }));
    }

    const updateContextInjects = (current_context_idx: number, new_context_injects: t.IContextInject[]) => {
        if (current_context_idx >= contexts.length) { return; }
        setIsInit(false);
        setContexts(prev_contexts => prev_contexts.map((context, idx) => {
            if (idx === current_context_idx) {
                return { ...context, extract_params: { ...context.extract_params, context_injects: new_context_injects } };
            } else {
                return context;
            }
        }));
    }

    const addContextInject = (current_context_idx: number) => {
        if (current_context_idx >= contexts.length) { return; }
        // check if we have at least one context
        if (contexts.length === 0) { return; }
        setIsInit(false);
        const first_context = contexts[0];
        // add new context inject
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];

        updateContextInjects(current_context_idx, [
            ...old_context_injects,
            {
                name: first_context.name,
                context_uuid: first_context.uuid,
                columns: getValidContextInjectColumns(first_context),
                render_type: "tsv"
            }
        ]);
    };

    const changeContextInjectContext = (current_context_idx: number, context_inject_idx: number, context_uuid: string) => {
        // context_idx - the index of the context we are editing
        // context_inject_idx - the index of a context_inject in context_injects we are changing
        // context_uuid - the new context uuid for the context_inject
        // find context
        if (current_context_idx >= contexts.length) { return; }
        const new_context = contexts?.find((context) => context.uuid === context_uuid);
        if (new_context === undefined) { return; }
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        const old_context = old_context_injects[context_inject_idx];
        if (old_context === undefined) { return; }
        setIsInit(false);
        const is_context_name = (old_context_injects[context_inject_idx].name === old_context.name);
        new_context_injects[context_inject_idx].context_uuid = context_uuid;
        new_context_injects[context_inject_idx].columns = getValidContextInjectColumns(new_context);
        // we change name only if it was the same as old context name
        if (is_context_name) { new_context_injects[context_inject_idx].name = new_context.name; }
        updateContextInjects(current_context_idx, new_context_injects);
    };

    const changeContextInjectName = (current_context_idx: number, context_inject_idx: number, name: string) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        new_context_injects[context_inject_idx].name = name;
        updateContextInjects(current_context_idx, new_context_injects);
    }

    const changeContextInjectGuidelines = (current_context_idx: number, context_inject_idx: number, guidelines: string) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        new_context_injects[context_inject_idx].guidelines = guidelines;
        updateContextInjects(current_context_idx, new_context_injects);
    }

    const changeContextInjectRenderType = (current_context_idx: number, context_inject_idx: number, render_type: "tsv" | "json" | "table") => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        new_context_injects[context_inject_idx].render_type = render_type;
        updateContextInjects(current_context_idx, new_context_injects);
    }

    const deleteContextInject = (current_context_idx: number, context_inject_idx: number) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        new_context_injects.splice(context_inject_idx, 1);

        // If this was the last context inject, also set omit_input_text to false
        if (new_context_injects.length === 0) {
            setContexts(prev_contexts => prev_contexts.map((context, idx) => {
                if (idx === current_context_idx) {
                    return {
                        ...context,
                        extract_params: {
                            ...context.extract_params,
                            context_injects: new_context_injects,
                            omit_input_text: false
                        }
                    };
                }
                return context;
            }));
        } else {
            updateContextInjects(current_context_idx, new_context_injects);
        }
    }

    const addContextInjectColumn = (current_context_idx: number, context_inject_idx: number) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...context_injects];
        const context_inject = new_context_injects[context_inject_idx];
        if (context_inject === undefined) { return; }

        // add new column and use first valid field as default
        const context = contexts.find((context) => context.uuid === context_inject.context_uuid);
        if (context === undefined) { return; }
        const valid_fields = getValidContextInjectFields(context);
        if (valid_fields.length === 0) { return; }

        new_context_injects[context_inject_idx].columns.push({
            name: valid_fields[0].name,
            type: "field",
            field: { context_uuid: context_inject.context_uuid, field_uuid: valid_fields[0].uuid }
        });
        updateContextInjects(current_context_idx, new_context_injects);
    };

    const setContextInjectColumn = (current_context_idx: number, context_inject_idx: number, column_idx: number, column: IScrapesRenderColumnConfig) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        new_context_injects[context_inject_idx].columns[column_idx] = column;
        updateContextInjects(current_context_idx, new_context_injects);
    };

    const moveContextInjectColumn = (current_context_idx: number, context_inject_idx: number, curr_idx: number, direction: "up" | "down") => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        const new_idx = direction === "up" ? curr_idx - 1 : curr_idx + 1;
        if (new_idx >= 0 && new_idx < new_context_injects[context_inject_idx].columns.length) {
            const temp = new_context_injects[context_inject_idx].columns[curr_idx];
            new_context_injects[context_inject_idx].columns[curr_idx] = new_context_injects[context_inject_idx].columns[new_idx];
            new_context_injects[context_inject_idx].columns[new_idx] = temp;
        }
        updateContextInjects(current_context_idx, new_context_injects);
    };

    const deleteContextInjectColumn = (current_context_idx: number, context_inject_idx: number, column_idx: number) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        if (column_idx >= new_context_injects[context_inject_idx].columns.length) { return; }
        // don't allow to delete the last column
        if (new_context_injects[context_inject_idx].columns.length === 1) { return; }
        new_context_injects[context_inject_idx].columns.splice(column_idx, 1);
        updateContextInjects(current_context_idx, new_context_injects);
    };

    const deleteAllButFirstContextInjectColumn = (current_context_idx: number, context_inject_idx: number) => {
        if (current_context_idx >= contexts.length) { return; }
        if (context_inject_idx >= (contexts[current_context_idx].extract_params.context_injects?.length ?? 0)) { return; }
        setIsInit(false);
        const old_context_injects = contexts[current_context_idx].extract_params.context_injects || [];
        const new_context_injects = [...old_context_injects];
        // keep only the first column
        new_context_injects[context_inject_idx].columns = new_context_injects[context_inject_idx].columns.slice(0, 1);
        updateContextInjects(current_context_idx, new_context_injects);
    }

    const getPrevFields = (context_idx: number) => {
        return contexts.slice(0, context_idx).map((c) => c.fields.map((f) => ({
            context_name: c.name,
            context_type: c.type,
            field_name: f.name,
            field_uuid: f.uuid,
        }))).flat();
    }

    // if we open in edit mode, check that we have valid context and that it is loaded
    if (is_edit) {
        if (is_edit_template_valid === undefined) {
            return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
                <LoadingSpinner />
            </div>;
        }

        if (!is_edit_template_valid) {
            return <div className="px-6 py-4">
                <div className="py-10">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Invalid Template</h2>
                    <p className="mt-3 max-w-3xl text-sm leading-6 text-gray-600">
                        Template does not exist.
                    </p>
                </div>
            </div>;
        }
    }

    // helpers
    const is_template_name_invalid = template_name.length === 0;
    const is_fields_invalid = contexts.some((context) => (context.type !== "classifier" && (context.fields.length === 0 || context.fields.every((field) => field.name.length === 0))));
    const model_names = models.map((model) => model.name);
    // some features are only available when both template has UUID (is_edit) and all contexts have UUID (not "")
    const template_contexts_exist = is_edit && contexts.every((context) => context.uuid !== "");

    // previous saved contexts given the selected context
    const previous_contexts = contexts
        .map((context, context_idx) => ({ uuid: context.uuid, name: context.name, idx: context_idx }))
        .filter((context, context_idx) => context_idx < (selected_details_context_idx ?? 0) && context.uuid !== "");

    // prepare Save template tooltip:
    let save_tooltip: string | undefined = undefined;
    if (is_template_name_invalid) {
        save_tooltip = "Please provide a name for the process template";
    } else if (contexts.some(ctx => ctx.type !== c.CONTEXT_TYPES.classifier && ctx.fields.length === 0)) {
        const fail_contexts = contexts.map((c, idx) => [c.fields.length, idx]).filter(([len, _idx]) => len === 0).map(([_, idx]) => idx + 1);
        save_tooltip = fail_contexts.length === 1 ?
            `Please provide at least one field for the step ${fail_contexts[0]}` :
            `Please provide at least one field for the steps ${fail_contexts.join(", ")}`;
    } else if (contexts.filter(ctx => ctx.type !== c.CONTEXT_TYPES.classifier).flatMap(c => c.fields).some(f => f.name.length === 0)) {
        const fail_contexts = contexts.map((c, idx) => [c.fields.filter(f => f.name.length === 0).length, idx]).filter(([len, _idx]) => len > 0).map(([_, idx]) => idx + 1);
        save_tooltip = fail_contexts.length === 1 ?
            `Please provide a name for each field in the step ${fail_contexts[0]}` :
            `Please provide a name for each field in the steps ${fail_contexts.join(", ")}`;
    }

    const tooltip = (save_tooltip !== undefined) ? save_tooltip :
        (is_edit && !is_init) ? "Unsaved changes to template" :
            undefined;

    const history_entities: AuditLogEntity[] = [
        { uuid: template_uuid ?? "", type: "template", name: template_name },
        ...contexts.map((context, idx) => ({ uuid: context.uuid, type: "context" as const, name: `Step ${idx + 1}. ${context.name}` }))
    ];

    let exception_rules_tooltip: string | undefined = undefined;
    if (!is_edit) {
        exception_rules_tooltip = "Please save the template before defining exception rules";
    } else if (contexts.some((context) => context.uuid === "")) {
        exception_rules_tooltip = "Template contains new steps since last save. Please save the template before defining or editing exception rules";
    } else if (contexts.length < 2) {
        exception_rules_tooltip = "You need at least two steps to define exception rules";
    }

    const template_org = memberships.find(({ org }) => org.uuid === org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <TwoRowHeader
            title={template_name.length > 0 ? template_name : "<no name>"}
            buttons={<Fragment>
                {!is_edit && <Button text="Create"
                    highlight={true}
                    disabled={is_template_name_invalid || is_fields_invalid || is_committing}
                    loading={is_committing}
                    tooltip={save_tooltip}
                    onClick={onCommit} />}
                {is_edit && is_init && <ExtractButton template_uuid={template_uuid} />}
                {is_edit && !is_init && <Button text="Save Changes"
                    highlight={true}
                    disabled={is_template_name_invalid || is_fields_invalid || is_committing}
                    loading={is_committing}
                    tooltip={save_tooltip}
                    onClick={onUpdate} />}
            </Fragment>}
            subtitle={is_edit ? "Edit Process Template" : "Create a Process Template"}
            org_name={template_org?.org.name}
            tooltip={tooltip}
            tooltip_type={save_tooltip !== undefined ? "error" : undefined}
            onBack={handleCheckBack}
        />
        <ConfirmModal
            open={is_back_modal_open}
            title="Unsaved changes"
            message={["There are unsaved changes to the template.", "Are you sure you want to go back?"]}
            cancel="No"
            confirm="Yes"
            onClose={handleBack} />

        <div className="p-8 max-w-5xl">
            <Tabs tabs={tabs} selected_tab_key={selected_tab_key} setSelectedTab={setSelectedTab} />
        </div>

        {selected_tab_key === "basic_info" && <Fragment>
            <div className="px-10 max-w-5xl">
                {is_business_orgs && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 py-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Organization
                    </label>
                    <div className="flex gap-2 mt-2 sm:col-span-3 sm:mt-0">
                        {admin_orgs.map((org, idx) => (<OrgPill key={idx} name={org.name} type={org.type} selected={org.uuid === org_uuid} onClick={() => handleOrgChange(org.uuid)} />))}
                    </div>
                </div>}

                <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 pb-6">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Name
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                        <div className="flex w-full">
                            <Textbox
                                placeholder="Please provide a name for the process template"
                                value={template_name}
                                onChange={(value) => { setIsInit(false); setTemplateName(value); }} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="sm:gap-4 sm:pt- sm:pb-6">
                {contexts.map((context, idx) => (<Fragment key={idx}>
                    <div className="px-10 max-w-5xl">
                        <ProcessFlowHead label={`Step ${idx + 1}`} active={selected_details_context_idx === idx}>
                            <div className="flex flex-row items-center font-normal">
                                <Textbox
                                    placeholder="You can name the step"
                                    value={context.name}
                                    onChange={(value) => handleContextNameChange(idx, value)} />
                                <Button icon={Cog6ToothIcon} onClick={() => setSelectedDetailsContextIdx(idx)} highlight={true} highlight_color="gray" />
                            </div>
                        </ProcessFlowHead>
                    </div>
                    <div className="px-10">
                        <ProcessFlowBody show_scrollbar={true}>
                            <div className="mt-6 mb-10 sm:space-y-0 sm:pb-0 outer-div">
                                {[c.CONTEXT_TYPES.array, c.CONTEXT_TYPES.object, c.CONTEXT_TYPES.lookup_table].includes(context.type) && <FieldsTable
                                    fields={context.fields}
                                    prev_fields={getPrevFields(idx)}
                                    context_type={context.type}
                                    extract_params={context.extract_params}
                                    disabled={is_processing || is_committing}
                                    is_editable={true}
                                    show_settings={true}
                                    lookup_tables={lookup_tables}
                                    contexts={contexts.map((c) => ({ uuid: c.uuid, name: c.name, type: c.type }))}
                                    selected_context_uuid={context.uuid}
                                    setInvalidate={() => setIsInit(false)}
                                    setFields={(f) => handleFieldsChange(idx, f)}
                                    setFieldContext={(field_uuid, context_uuid) => handleFieldContextChange(idx, field_uuid, context_uuid)} />}
                                {context.type === c.CONTEXT_TYPES.hierarchical && <HierarchicalFieldsTable
                                    fields={context.fields}
                                    disabled={is_processing || is_committing || !is_admin}
                                    lookup_tables={lookup_tables}
                                    setFields={(f: any) => handleFieldsChange(idx, f)} />}
                            </div>
                        </ProcessFlowBody>
                    </div>
                </Fragment>))}
                <div className="px-10 max-w-5xl">
                    <ProcessFlowHead label="Done">
                        <Button text="Add Another Step" onClick={() => setIsNewContextModalOpen(true)} disabled={is_processing || is_committing} />
                    </ProcessFlowHead>
                    <NewContextModal open={is_new_context_modal_open} show_classifier={contexts.find(ctx => ctx.type === c.CONTEXT_TYPES.classifier) === undefined} show_lookup_table={all_lookup_tables.length > 0} onClose={newContext} />
                </div>
            </div>

            {(selected_details_context_idx === undefined || selected_details_context_idx >= contexts.length) && <SidePanelRaw key="context_settings" open={false} />}

            {selected_details_context_idx !== undefined && selected_details_context_idx < contexts.length && <SidePanelRaw
                key="context_settings"
                title={`Settings: Step ${selected_details_context_idx + 1}. ${contexts[selected_details_context_idx].name}`}
                open={true}
                size="2xl"
                onClose={() => {
                    setSelectedDetailsContextIdx(undefined);
                    setIsValidAdminJSON(true);
                }}
            >
                <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                        <DialogTitle className="text-base font-semibold leading-6 text-gray-900">
                            Settings: Step {selected_details_context_idx + 1}. {contexts[selected_details_context_idx].name}
                        </DialogTitle>
                        <div className="ml-3 flex h-7 items-center">
                            <ButtonGroup buttons={[
                                { text: "", icon: ChevronUpIcon, onClick: () => moveContext(selected_details_context_idx, "up"), disabled: selected_details_context_idx === 0, tooltip: "Move up" },
                                { text: "", icon: ChevronDownIcon, onClick: () => moveContext(selected_details_context_idx, "down"), disabled: selected_details_context_idx === contexts.length - 1, tooltip: "Move down" },
                                { text: "", icon: TrashIcon, onClick: () => removeContext(selected_details_context_idx), disabled: is_processing || is_committing, tooltip: "Remove" },
                            ]} disabled={contexts.length === 1} tiny={true} />
                            <button
                                type="button"
                                className="ml-4 relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-space_blue-500 focus:ring-offset-2"
                                onClick={() => setSelectedDetailsContextIdx(undefined)}
                            >
                                <span className="absolute -inset-2.5" />
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="relative mt-6 px-4 sm:px-6 w-full">
                    <div className="mt-6">
                        <CompactTabs tabs={context_details_tabs} selected_tab_key={context_details_selected_tab} setSelectedTab={setContextDetailsSelectedTab} />
                    </div>

                    {context_details_selected_tab === "general" && <Fragment>
                        {["object", "array"].includes(contexts[selected_details_context_idx].type) && <div className="max-w-5xl sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Type
                            </label>
                            <div className="mt-2 flex flex-row">
                                <NewContextModalButton
                                    title="Table"
                                    description="Useful when you have multiple rows of data."
                                    icon={TbTable}
                                    selected={contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.array}
                                    onClick={() => handleTypeChange(selected_details_context_idx, c.CONTEXT_TYPES.array)} />
                                <NewContextModalButton
                                    title="List"
                                    description="Useful when you have one value per field."
                                    icon={TbList}
                                    selected={contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.object}
                                    onClick={() => handleTypeChange(selected_details_context_idx, c.CONTEXT_TYPES.object)} />
                            </div>
                        </div>}

                        {(contexts.find(c => c.type === "classifier")?.extract_params.classifiers || []).length > 0 && (["array", "object"].includes(contexts[selected_details_context_idx].type)) && <div className="py-6 border-t border-gray-900/10">
                            <PageFilters
                                classifiers={contexts.find(c => c.type === "classifier")?.extract_params.classifiers || []}
                                input_page_filters={contexts[selected_details_context_idx].extract_params.input_page_filters || { filters: [], invert: false }}
                                onChange={(filters) => handleExtractParamsChange(selected_details_context_idx, { ...contexts[selected_details_context_idx].extract_params, input_page_filters: filters })}
                                isDisabled={is_processing || is_committing}
                            />
                        </div>}

                        {["object", "array", "hierarchical"].includes(contexts[selected_details_context_idx].type) && <TemplateFacts
                            facts={contexts[selected_details_context_idx].facts}
                            setFacts={(facts) => handleFactsChange(selected_details_context_idx, facts)} />}

                        {contexts[selected_details_context_idx].type === c.CONTEXT_TYPES.lookup_table && <LookupTableContextDetails
                            prev_contexts={contexts.filter((_, idx) => idx < selected_details_context_idx)}
                            context={contexts[selected_details_context_idx]}
                            disabled={is_processing || is_committing}
                            lookup_tables={lookup_tables}
                            updateLookupTableFilter={(lookup_table_uuid) => handleLookupTableFilterChange(selected_details_context_idx, lookup_table_uuid)}
                            updateLookupTableKeys={(keys) => handleLookupTableKeysChange(selected_details_context_idx, keys)} />}
                    </Fragment>}

                    {context_details_selected_tab === "context_injects" && <Fragment>
                        {showContextInjects(selected_details_context_idx) === "yes" && <div className="max-w-5xl py-6 text-sm flex flex-col gap-4 border-t border-gray-900/10">
                            <div className="flex flex-row items-start sm:items-center sm:gap-4 max-w-3xl">
                                <p className="font-medium leading-6 text-gray-900 pr-2">
                                    Use previous steps as additional context
                                </p>
                                <QuestionMarkCircleIcon
                                    className="w-5 h-5 ml-1 text-gray-400"
                                    data-tooltip-id="context_injects-tooltip-id"
                                    data-tooltip-html={
                                        `<p class="max-w-sm">
                                            By default the current step does not use or see the results from previous steps as context or
                                            background information. If you want to include the results from previous steps, you can do it here.
                                            Just list the steps and fields you want to include as context, like you would in Excel.
                                        </p>`
                                    }
                                />
                                <Tooltip id="context_injects-tooltip-id" />
                                <div className="flex-grow" />
                                <Button icon={hi.PlusIcon} text="Add step" onClick={() => addContextInject(selected_details_context_idx)} />
                            </div>

                            {contexts[selected_details_context_idx].extract_params.context_injects.map((context_inject, context_inject_idx) => (
                                <div key={context_inject_idx} className="flex flex-col gap-2">
                                    <div className="flex flex-row items-center gap-2">
                                        <span className="text-sm font-semibold pr-4">Table {context_inject_idx + 1}</span>
                                        <div className="flex-grow" />
                                        <Button icon={hi.TrashIcon} onClick={() => deleteContextInject(selected_details_context_idx, context_inject_idx)} />
                                    </div>
                                    <div className="grid grid-cols-2 items-center gap-2">
                                        <div>
                                            <div className="text-sm pb-1">Title</div>
                                            <Textbox value={context_inject.name} onChange={(name) => changeContextInjectName(selected_details_context_idx, context_inject_idx, name)} />
                                        </div>
                                        <div>
                                            <div className="text-sm pb-1">Base step</div>
                                            <Dropdown
                                                ids={previous_contexts.map((context) => context.uuid)}
                                                values={previous_contexts.map((context) => `${context.idx + 1}. ${context.name}`)}
                                                selected={context_inject.context_uuid}
                                                onChange={(context_uuid) => changeContextInjectContext(selected_details_context_idx, context_inject_idx, context_uuid)} />
                                        </div>
                                    </div>
                                    <div className="items-center gap-2">
                                        <div>
                                            <div className="text-sm pb-1">Guidelines:</div>
                                            <Textbox
                                                value={context_inject.guidelines ?? ""}
                                                placeholder="Optionally add guidelines on how to use the background information"
                                                onChange={(guidelines) => changeContextInjectGuidelines(selected_details_context_idx, context_inject_idx, guidelines)} />
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Checkbox
                                            id={`require_nonempty_inject_${context_inject_idx}`}
                                            checked={context_inject.require_nonempty_inject ?? false}
                                            setChecked={(value) => {
                                                setIsInit(false);
                                                const old_context_injects = contexts[selected_details_context_idx].extract_params.context_injects || [];
                                                const new_context_injects = [...old_context_injects];
                                                new_context_injects[context_inject_idx].require_nonempty_inject = value;
                                                updateContextInjects(selected_details_context_idx, new_context_injects);
                                            }} />
                                        <span className="text-sm">Table must be non-empty</span>
                                        <QuestionMarkCircleIcon
                                            className="w-5 h-5 ml-1 text-gray-400"
                                            data-tooltip-id={`require-nonempty-inject-tooltip-${context_inject_idx}`}
                                            data-tooltip-html={
                                                `<p class="max-w-sm">
                                                    If checked, requires the table to contain data before proceeding with extraction. If unchecked, extraction will proceed even if the table is empty.
                                                </p>`
                                            }
                                        />
                                        <Tooltip id={`require-nonempty-inject-tooltip-${context_inject_idx}`} />
                                    </div>
                                    {is_admin && <div className="grid grid-cols-2 items-center gap-2">
                                        <div>
                                            <div className="text-sm pb-1 font-medium text-gray-400">Admin: Output format:</div>
                                            <Dropdown
                                                values={["TSV", "JSON", "Table"]}
                                                ids={["tsv", "json", "table"]}
                                                selected={context_inject.render_type}
                                                onChange={(render_type) => changeContextInjectRenderType(selected_details_context_idx, context_inject_idx, render_type as "tsv" | "json" | "table")} />
                                        </div>
                                    </div>}
                                    <div className="py-2">
                                        <EndpointOutputColumns
                                            context={contexts.find((context) => context.uuid === context_inject.context_uuid) as t.IContextBase}
                                            object_contexts={contexts
                                                .filter((context, context_idx) =>
                                                    context.type === c.CONTEXT_TYPES.object &&
                                                    context.uuid !== context_inject.context_uuid &&
                                                    context_idx < selected_details_context_idx &&
                                                    context.uuid !== "")}
                                            columns={context_inject.columns}
                                            show_hash_key={false}
                                            allow_zero_hash_keys={true}
                                            addColumn={() => addContextInjectColumn(selected_details_context_idx, context_inject_idx)}
                                            setColumn={(column_idx, column) => setContextInjectColumn(selected_details_context_idx, context_inject_idx, column_idx, column)}
                                            moveColumn={(curr_idx, direction) => moveContextInjectColumn(selected_details_context_idx, context_inject_idx, curr_idx, direction)}
                                            deleteColumn={(column_idx) => deleteContextInjectColumn(selected_details_context_idx, context_inject_idx, column_idx)}
                                            deleteAllButFirstColumn={() => deleteAllButFirstContextInjectColumn(selected_details_context_idx, context_inject_idx)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>}

                        {showContextInjects(selected_details_context_idx) === "not_saved" && <div className="max-w-5xl py-6 text-sm flex flex-col gap-4 border-t border-gray-900/10">
                            <div className="flex flex-row items-start sm:items-center sm:gap-4 max-w-3xl">
                                <p className="text-gray-900 pr-2">
                                    You added new steps to the process template, which requires you to first save the process template before you can add previous steps as context.
                                </p>
                            </div>
                        </div>}

                        {contexts[selected_details_context_idx].extract_params.context_injects.length > 0 && <div className="flex items-center gap-2 mb-4">
                            <Checkbox
                                id="omit_input_text"
                                checked={contexts[selected_details_context_idx].extract_params.omit_input_text ?? false}
                                disabled={is_processing || is_committing}
                                setChecked={(value) => {
                                    setIsInit(false);
                                    setContexts(prev_contexts => prev_contexts.map((context, idx) => {
                                        if (idx === selected_details_context_idx) {
                                            return {
                                                ...context,
                                                extract_params: {
                                                    ...context.extract_params,
                                                    omit_input_text: value
                                                }
                                            };
                                        }
                                        return context;
                                    }));
                                }} />
                            <span className="text-sm">Extraction ignores input document</span>
                            <QuestionMarkCircleIcon
                                className="w-5 h-5 ml-1 text-gray-400"
                                data-tooltip-id="omit_input_text-tooltip-id"
                                data-tooltip-html={
                                    `<p class="max-w-sm">
                                        By default, the input document is used for extraction during this step.
                                        If you prefer to use only the context from previous steps and exclude the input document, you can select this option.
                                    </p>`
                                }
                            />
                            <Tooltip id="omit_input_text-tooltip-id" />
                        </div>}
                    </Fragment>}

                    {context_details_selected_tab === "rules" && <Fragment>
                        <ContextRules
                            context={contexts[selected_details_context_idx]}
                            setRowValidators={(row_validators: IContextRowValidator[]) => handleRowValidatorsChange(selected_details_context_idx, row_validators)}
                            setContextValidators={(context_validators: IContextValidator[]) => handleContextValidatorsChange(selected_details_context_idx, context_validators)}
                        />
                    </Fragment>}

                    {context_details_selected_tab === "details" && <Fragment>
                        <ContextDetailsSettings
                            context={contexts[selected_details_context_idx]}
                            show_admin_details={show_admin_details}
                            isDisabled={is_processing || is_committing}
                            setType={(type) => handleTypeChange(selected_details_context_idx, type)}
                            setCode={(code) => handleCodeChange(selected_details_context_idx, code)}
                            setExtractParams={(extract_params) => handleExtractParamsChange(selected_details_context_idx, extract_params)}
                        />

                        {contexts[selected_details_context_idx]?.type !== c.CONTEXT_TYPES.classifier && show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-6 max-w-5xl sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Extract
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.scrape_extract || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "scrape_extract"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Heal
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.scrape_heal || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "scrape_heal"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Summarize
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.scrape_summarize || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "scrape_summarize"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Focused Summarize
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.scrape_focused_summarize || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "scrape_focused_summarize"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Decimal Separator
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.decimal_separator || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "decimal_separator"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                OC Date Validation
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.oc_date_validation || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "oc_date_validation"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Classification
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <div className="flex w-full max-w-xs rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-sky-600">
                                    <Dropdown
                                        values={["/", ...model_names]}
                                        selected={contexts[selected_details_context_idx].extract_params.models_overrides.classify_document || "/"}
                                        disabled={is_processing || is_committing}
                                        onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "classify_document"); }} />
                                </div>
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Default
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.default || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "default"); }} />
                            </div>

                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                Default fast
                            </label>
                            <div className="mt-2 sm:mt-0 sm:col-span-2">
                                <Dropdown
                                    values={["/", ...model_names]}
                                    selected={contexts[selected_details_context_idx].extract_params.models_overrides.default_fast || "/"}
                                    disabled={is_processing || is_committing}
                                    onChange={(model_name) => { handleModelNameChange(selected_details_context_idx, model_name, "default_fast"); }} />
                            </div>

                        </div>}

                        {contexts[selected_details_context_idx]?.type !== c.CONTEXT_TYPES.classifier && show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                            Prompt overrides
                        </div>}

                        {contexts[selected_details_context_idx]?.type !== c.CONTEXT_TYPES.classifier && show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-6 max-w-5xl sm:items-start sm:gap-4 sm:py-6">
                            <div className="sm:col-span-6 flex items-center space-x-2">
                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                    After scrape system
                                </label>
                                <Checkbox
                                    id="enable_admin_after_scrape_system"
                                    checked={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_scrape_system !== undefined}
                                    disabled={is_processing || is_committing}
                                    setChecked={(value) => handleEnableAdminPromptChange(selected_details_context_idx, "admin_after_scrape_system", value)} />
                            </div>
                            {contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_scrape_system !== undefined && <div className="sm:col-span-6">
                                <textarea
                                    id="admin_after_scrape_system"
                                    name="admin_after_scrape_system"
                                    rows={10}
                                    className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6"
                                    value={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_scrape_system}
                                    disabled={is_processing || is_committing}
                                    onChange={(e) => handleAdminPromptChange(selected_details_context_idx, "admin_after_scrape_system", e.target.value)} />
                            </div>}
                            <div className="sm:col-span-6 flex items-center space-x-2">
                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                    After scrape user
                                </label>
                                <Checkbox
                                    id="enable_admin_after_scrape_user"
                                    checked={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_scrape_user !== undefined}
                                    disabled={is_processing || is_committing}
                                    setChecked={(value) => handleEnableAdminPromptChange(selected_details_context_idx, "admin_after_scrape_user", value)} />
                            </div>
                            {contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_scrape_user !== undefined && <div className="sm:col-span-6">
                                <textarea
                                    id="admin_after_scrape_user"
                                    name="admin_after_scrape_user"
                                    rows={10}
                                    className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6"
                                    value={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_scrape_user}
                                    disabled={is_processing || is_committing}
                                    onChange={(e) => handleAdminPromptChange(selected_details_context_idx, "admin_after_scrape_user", e.target.value)} />
                            </div>}
                            <div className="sm:col-span-6 flex items-center space-x-2">
                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                    After partial scrape system
                                </label>
                                <div className="mt-2 sm:mt-0">
                                    <Checkbox
                                        id="enable_admin_after_partial_scrape_system"
                                        checked={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_partial_scrape_system !== undefined}
                                        disabled={is_processing || is_committing}
                                        setChecked={(value) => handleEnableAdminPromptChange(selected_details_context_idx, "admin_after_partial_scrape_system", value)} />
                                </div>
                            </div>
                            {contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_partial_scrape_system !== undefined && <div className="sm:col-span-6">
                                <textarea
                                    id="admin_after_partial_scrape_system"
                                    name="admin_after_partial_scrape_system"
                                    rows={10}
                                    className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6"
                                    value={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_partial_scrape_system}
                                    disabled={is_processing || is_committing}
                                    onChange={(e) => handleAdminPromptChange(selected_details_context_idx, "admin_after_partial_scrape_system", e.target.value)} />
                            </div>}
                            <div className="sm:col-span-6 flex items-center space-x-2">
                                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                                    After partial scrape user
                                </label>
                                <div className="mt-2 sm:mt-0">
                                    <Checkbox
                                        id="enable_admin_after_partial_scrape_user"
                                        checked={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_partial_scrape_user !== undefined}
                                        disabled={is_processing || is_committing}
                                        setChecked={(value) => handleEnableAdminPromptChange(selected_details_context_idx, "admin_after_partial_scrape_user", value)} />
                                </div>
                            </div>
                            {contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_partial_scrape_user !== undefined && <div className="sm:col-span-6">
                                <textarea
                                    id="admin_after_partial_scrape_user"
                                    name="admin_after_partial_scrape_user"
                                    rows={10}
                                    className="block w-full max-w-4xl p-2 rounded-md border-0 py-1.5 text-gray-900 shadow-lg ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-space_blue-600 sm:text-sm sm:leading-6"
                                    value={contexts[selected_details_context_idx].extract_params.admin_prompts.admin_after_partial_scrape_user}
                                    disabled={is_processing || is_committing}
                                    onChange={(e) => handleAdminPromptChange(selected_details_context_idx, "admin_after_partial_scrape_user", e.target.value)} />
                            </div>}
                        </div>}
                        {contexts[selected_details_context_idx]?.type !== c.CONTEXT_TYPES.classifier && show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                            Postprocess script
                        </div>}
                        {contexts[selected_details_context_idx]?.type !== c.CONTEXT_TYPES.classifier && show_admin_details && is_admin && <div className="p max-w-5xl sm:py-6">
                            <div className="w-full shadow border">
                                <CodeMirror
                                    value={contexts[selected_details_context_idx].postprocess.formula || ""}
                                    height="400px"
                                    theme="light"
                                    extensions={[javascript()]}
                                    readOnly={!is_admin || is_processing || is_committing}
                                    onChange={(value) => handlePostprocessChange(selected_details_context_idx, value)}
                                    onBlur={async () => { await validateJSFormula(contexts[selected_details_context_idx].postprocess.formula ?? "", contexts[selected_details_context_idx].name) }}
                                />
                            </div>
                        </div>}

                        {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                            Admin JSON
                        </div>}
                        {show_admin_details && is_admin && <div>
                            <div className="py-4">
                                <div className="my-2 sm:mt-0">
                                    <div className="w-full shadow border">
                                        <CodeMirror
                                            value={JSON.stringify(contexts[selected_details_context_idx], null, 2)}
                                            height="500px"
                                            theme="light"
                                            extensions={[javascript()]}
                                            onChange={(value) => {
                                                try {
                                                    const parsed = JSON.parse(value);
                                                    setContexts(prev_contexts => prev_contexts.map((context, idx) => idx === selected_details_context_idx ? parsed : context));
                                                    setIsValidAdminJSON(true);
                                                    setIsInit(false);
                                                } catch (e) {
                                                    console.error("Invalid JSON", e);
                                                    setIsValidAdminJSON(false);
                                                }
                                            }} />
                                    </div>
                                    <div className={`mt-2 text-sm ${is_valid_admin_json ? "text-mint-600" : "text-red-600"}`}>
                                        {is_valid_admin_json ? "VALID JSON" : "INVALID JSON"}
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                            <Button text="Show admin details" onClick={() => {
                                setShowAdminDetails(true);
                                setIsValidAdminJSON(true);
                            }} icon={ChevronDownIcon} />
                        </div>}

                        {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                            <Button text="Hide admin details" onClick={() => {
                                setShowAdminDetails(false);
                                setIsValidAdminJSON(true);
                            }} icon={ChevronUpIcon} />
                        </div>}
                    </Fragment>}

                    <div className="px-4 py-6 border-t border-gray-200 flex justify-end">
                        <Button text="Close" onClick={() => setSelectedDetailsContextIdx(undefined)} />
                    </div>
                </div>
            </SidePanelRaw>}
        </Fragment>}

        {selected_tab_key === "details" && <form>
            <div className="px-10 max-w-5xl">
                <TemplateFacts
                    facts={template_facts}
                    setFacts={(facts) => handleTemplateFactsChange(facts)} />
            </div>
            {!template_contexts_exist && <div className="px-10 py-3">
                {!is_edit && <div className="max-w-4xl text-gray-600 text-sm">
                    Split by field can only be enabled after you have saved initial version of the process template.
                </div>}
                {is_edit && <div className="max-w-4xl text-gray-600 text-sm">
                    You added new steps ({contexts.filter(c => c.uuid === "").map((c, idx) => c.name.length > 0 ? c.name : `${idx + 1}`).join(", ")}) to
                    the process template, which requires you to first save the process template before you can edit split by field settings.
                </div>}
            </div>}

            <div className="px-10 max-w-5xl">
                {exception_rules_tooltip !== undefined && <div className="py-6 border-t border-gray-200">
                    <div className="text-gray-600 text-sm">{exception_rules_tooltip}</div>
                </div>}

                {exception_rules_tooltip === undefined && <div className="py-6 border-t border-gray-200">
                    <div className="flex flex-row items-center">
                        <div className="grow text-sm font-bold">Exceptions</div>
                        <Button text="Add new rule" onClick={() => setIsNewFieldOverrideOpen(true)} />
                    </div>

                    <div className="mt-4">
                        {overrides.map((override, idx) => <div key={idx} className="flex flex-row items-start gap-4 py-6 px-4 mb-4 text-gray-600 text-sm border border-gray-200">
                            <div className="grow">
                                <div className="grid grid-cols-6 gap-4 w-full">
                                    <span className="font-semibold">Step:</span>
                                    {override.type === "context" && <Fragment>
                                        <span className="col-span-5">{override_name_map[override.context.uuid]}</span>
                                    </Fragment>}
                                    {override.type === "field" && <Fragment>
                                        <span className="col-span-2">{override_name_map[override.context_uuid]}</span>
                                        <span className="font-semibold">Field:</span>
                                        <span className="col-span-2">{override_name_map[override.field.uuid]}</span>
                                    </Fragment>}
                                    <span className="font-semibold">When:</span>
                                    <span className="col-span-5">
                                        {override.value.override_trigger.type === "context_field_equals" && <Fragment>
                                            {override_name_map[override.value.override_trigger.data.context_uuid]} [ {override_name_map[override.value.override_trigger.data.field_uuid]} ] = {override.value.override_trigger.data.value}
                                        </Fragment>}
                                    </span>
                                    <span className="font-semibold">Changes:</span>
                                    <span className="col-span-5">
                                        {override.changes.join(", ")}
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-row">
                                {override.type === "context" && <Button icon={TrashIcon} onClick={() => deleteContextOverride(override.context.uuid, override.idx)} />}
                                {override.type === "context" && <Button icon={PencilIcon} onClick={() => setOverrideSettingsIdx(idx)} />}

                                {override.type === "field" && <Button icon={TrashIcon} onClick={() => deleteFieldOverride(override.context_uuid, override.field.uuid, override.idx)} />}
                                {override.type === "field" && <Button icon={PencilIcon} onClick={() => setOverrideSettingsIdx(idx)} />}
                            </div>
                        </div>)}
                    </div>

                    <NewFieldOverrideDialog
                        contexts={contexts}
                        open={is_new_field_override_open}
                        onClose={() => setIsNewFieldOverrideOpen(false)}
                        onSubmit={handleOverrideSubmit}
                    />

                    {override_settings_idx !== undefined && overrides[override_settings_idx].type === "context" &&
                        <SidePanel
                            title="Detailed Step Settings"
                            open={true}
                            size="2xl"
                            onClose={() => setOverrideSettingsIdx(undefined)}
                        >
                            <div className="">
                                <TemplateFacts
                                    facts={(overrides[override_settings_idx] as IOverrideContext).value.facts || []}
                                    setFacts={(facts) => handleOverrideSettingsContextFactsChange(override_settings_idx, facts)}
                                />
                                <ContextDetailsSettings
                                    context={{
                                        ...(overrides[override_settings_idx] as IOverrideContext).context,
                                        extract_params: {
                                            ...(overrides[override_settings_idx] as IOverrideContext).context.extract_params,
                                            ...(overrides[override_settings_idx] as IOverrideContext).value.extract_params
                                        }
                                    }}
                                    show_admin_details={show_admin_details}
                                    isDisabled={is_processing || is_committing}
                                    setExtractParams={(new_extract_params) => handleOverrideSettingsContextExtractParamsChange(override_settings_idx, new_extract_params)} />
                                {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                                    <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={ChevronDownIcon} />
                                </div>}
                                {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                                    <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={ChevronUpIcon} />
                                </div>}
                            </div>
                        </SidePanel>
                    }

                    {override_settings_idx !== undefined && overrides[override_settings_idx].type === "field" &&
                        <FieldSettings
                            type="override"
                            open={true}
                            field_idx={override_settings_idx}
                            field={deepCopyTyped({
                                ...(overrides[override_settings_idx] as IOverrideField).field,
                                ...(overrides[override_settings_idx] as IOverrideField).value
                            })}
                            prev_fields={[]} // currently cannot select previous context fields in override dialog
                            fields_count={0}
                            setOpen={() => setOverrideSettingsIdx(undefined)}
                            setField={handleOverrideSettingsFieldChange}
                            // following options are not used for override dialog
                            fields={[]}
                            context_type={"object"}
                            lookup_tables={[]}
                            contexts={[]}
                            selected_context_uuid=""
                            moveField={() => { }}
                            deleteField={() => { }}
                            setFieldContext={() => { }} />}
                </div>}

                {show_admin_details && is_admin && <div className="py-6 border-t border-gray-200">
                    <div className="grid grid-cols-4 max-w-5xl items-start gap-6 pt-3 pb-6">
                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            Excel preprocessing strategy
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <Dropdown
                                ids={["col_names_sparse", "col_names_dense_zero", "col_names_dense_empty", "without_col_names"]}
                                values={["Column names (sparse)", "Column names (dense with zero)", "Column names (dense with empty)", "Without column names"]}
                                selected={template_details.preprocess_excel_strategy}
                                disabled={is_processing || is_committing || !template_contexts_exist}
                                onChange={(id) => handleTemplateDetailsChange("preprocess_excel_strategy", id)} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            OCR preprocessing strategy
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <Dropdown
                                ids={["simple", "fix_rotation"]}
                                values={["Simple", "Fix rotations"]}
                                selected={template_details.preprocess_ocr_strategy}
                                disabled={is_processing || is_committing || !template_contexts_exist}
                                onChange={(id) => handleTemplateDetailsChange("preprocess_ocr_strategy", id)} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            Rotation-segment handling strategy
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <Dropdown
                                ids={["as_is", "only_main", "segment"]}
                                values={["Keep rotation as they are", "Only keep the main rotations", "Segment texts by rotation"]}
                                selected={template_details.orientation_segments_strategy}
                                disabled={is_processing || is_committing || !template_contexts_exist}
                                onChange={(id) => handleTemplateDetailsChange("orientation_segments_strategy", id)} />
                        </div>

                        <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                            OCR table-handling strategy
                        </label>
                        <div className="mt-2 sm:mt-0">
                            <Dropdown
                                ids={["plain_text_only", "markdown_only", "markdown_and_plain_text"]}
                                values={["Use normal text only", "Use markdown only", "Use markdown and normal text"]}
                                selected={template_details.preprocess_ocr_table_strategy}
                                disabled={is_processing || is_committing || !template_contexts_exist}
                                onChange={(id) => handleTemplateDetailsChange("preprocess_ocr_table_strategy", id)} />
                        </div>
                        <label htmlFor="template_scrape_debug" className="block text-sm font-medium leading-6 text-gray-400 pt-0.5">
                            Enable Scrape Debug
                        </label>
                        <div className="col-span-3">
                            <Checkbox
                                id="template_scrape_debug"
                                checked={template_details.scrape_debug ?? false}
                                disabled={is_processing || is_committing || !template_contexts_exist}
                                setChecked={(value) => handleTemplateDetailsChange("scrape_debug", value)} />
                        </div>
                    </div>
                </div>}


                {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
                    Admin JSON
                </div>}
                {show_admin_details && is_admin && <div>
                    <div className="py-4">
                        <div className="my-2 sm:mt-0">
                            <div className="w-full shadow border">
                                <CodeMirror
                                    value={JSON.stringify({
                                        details: template_details,
                                        facts: template_facts,
                                        name: template_name,
                                        contexts
                                    }, null, 2)}
                                    height="500px"
                                    theme="light"
                                    extensions={[javascript()]}
                                    onChange={(value) => {
                                        try {
                                            const parsed = JSON.parse(value);
                                            setTemplateDetails(parsed.details);
                                            setTemplateFacts(parsed.facts);
                                            setTemplateName(parsed.name);
                                            setContexts(parsed.contexts);
                                            setIsValidAdminJSONTemplate(true);
                                            setIsInit(false);
                                        } catch (e) {
                                            console.error("Invalid JSON", e);
                                            setIsValidAdminJSONTemplate(false);
                                        }
                                    }} />
                            </div>
                            <div className={`mt-2 text-sm ${is_valid_admin_json_template ? "text-mint-600" : "text-red-600"}`}>
                                {is_valid_admin_json_template ? "VALID JSON" : "INVALID JSON"}
                            </div>
                        </div>
                    </div>
                </div>}

                {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Show admin details" onClick={() => {
                        setShowAdminDetails(true);
                        setIsValidAdminJSON(true);
                        setIsValidAdminJSONTemplate(true);
                    }} icon={ChevronDownIcon} />
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Hide admin details" onClick={() => {
                        setShowAdminDetails(false);
                        setIsValidAdminJSON(true);
                        setIsValidAdminJSONTemplate(true);
                    }} icon={ChevronUpIcon} />
                </div>}
            </div>
        </form>}

        {selected_tab_key === "history" && is_admin && <div className="px-10">
            <div className="max-w-5xl sm:py-6">
                <AuditLogHistory entities={history_entities} />
            </div>
        </div>}

        {selected_tab_key === "examples" && <Fragment>
            {!template_contexts_exist && <div className="px-10">
                {!is_edit && <div className="max-w-4xl text-gray-600 text-sm">
                    Here you can test your process template on examples. Before you can do that, you first need to create a process template.
                    Later you can test the template, including on unsaved changes to the process template.
                </div>}
                {is_edit && <div className="max-w-4xl text-gray-600 text-sm">
                    Here you can test your process template on examples. You added new steps ({contexts.filter(c => c.uuid === "").map(c => c.name).join(", ")}) to
                    the process template, which requires you to first save the process template before you can test it again.
                </div>}
            </div>}

            {template_contexts_exist && <TemplateEval
                template_uuid={template_uuid}
                contexts={contexts}
                disabled={is_processing || is_committing}
                examples={examples}
                contexts_metrics={contexts_metrics}
                handleEvaluateTemplate={handleEvaluateTemplate}
                setExamples={setExamples}
                setContextsMetrics={setContextsMetrics}
                setIsProcessing={setIsProcessing}
            />}
        </Fragment>}

        <ErrorMessageBar message={error_message} clearMessage={() => setErrorMessage(undefined)} />
    </div>;
}

export function NewTemplateInit() {
    const navigate = useNavigate();

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);

    // default is personal org, if not available, use first org
    const default_org_uuid =
        memberships.find((membership) => membership.org.type === c.ORG_TYPES.personal)?.org.uuid ||
        memberships[0].org.uuid ||
        "";

    const [is_creating, setIsCreating] = useState<boolean>(false);

    const onWizardClose = async (init_template_type: t.WizardTemplateType) => {
        // check if init template type refers to hierarchical template
        const init_template = await BackendObj.extractions.getWizardTemplate({ wizard_template_type: init_template_type as t.WizardTemplateType });
        if (init_template.wizard_template.contexts.some((context) => context.type === "hierarchical")) {
            // we cannot edit hierarchical templates, just create and redirect to view
            setIsCreating(true);
            const { template_uuid } = await BackendObj.extractions.createTemplate({
                template: {
                    name: init_template.wizard_template.template_name,
                    org_uuid: default_org_uuid,
                    details: deepCopyTyped(DEFAULT_NEW_TEMPLATE.details),
                    facts: []
                },
                contexts: init_template.wizard_template.contexts.map((new_context, idx) => ({
                    name: new_context.context_name,
                    code: "",
                    org_uuid: default_org_uuid,
                    facts: [],
                    fields: new_context.fields,
                    type: new_context.type,
                    extract_params: deepCopyTyped(DEFAULT_NEW_CONTEXT.extract_params),
                    overrides: deepCopyTyped(DEFAULT_NEW_CONTEXT.overrides),
                    postprocess: {},
                    row_validators: [],
                    context_validators: [],
                    weight_score: idx
                }))
            });
            navigate(`/template/${template_uuid}`);
        } else {
            // we can edit non-hierarchical templates, redirect to edit
            navigate(`/template/new/${init_template_type}`);
        }
    }

    if (is_creating) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    return <div className={classNames("lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className={classNames("z-50 h-16 bg-white border-b-sea_blue-700 border-b lg:fixed lg:right-0", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="px-10 py-4 lg:max-w-5xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        Create new Template
                    </h2>
                </div>
            </div>
        </div>

        <div className="p-6 mt-10 lg:pt-12">
            <div className="px-6 py-8 text-gray-600">
                Select a process template from the list below or create a custom process template.
            </div>
            <div className="pb-8 flex flex-wrap">
                <div className="opacity-100">
                    <WizardButtonIcon title="Upload your document" icon={DocumentTextIcon} onClick={() => navigate("/template/wizard")} />
                </div>
                <div className="opacity-100">
                    <WizardButtonIcon title="Create custom template" icon={TbTablePlus} onClick={async () => onWizardClose("custom")} />
                </div>
                {/* In DEV mode, we provide easy way to create contact information template for testing */}
                {env === "dev" && <div className="opacity-100">
                    <WizardButtonIcon title="DEV TEMPLATE" icon={RocketLaunchIcon} onClick={async () => onWizardClose("dev")} />
                </div>}
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Procurement</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Order Confirmation" image="/wizards/new_template_order_confirmation.png" onClick={() => onWizardClose("order_confirmation")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Sales Quotation" image="/wizards/new_template_sales_quotation.png" onClick={() => onWizardClose("sales_quotation")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Backorders" image="/wizards/new_template_order_confirmation.png" onClick={() => onWizardClose("backorders")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Vendor Evaluation" image="/wizards/new_template_vendor_evaluation.png" onClick={() => onWizardClose("vendor_evaluation")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Short Contract" image="/wizards/new_template_contract.png" onClick={() => onWizardClose("short_contract")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Long Contract" image="/wizards/new_template_contract.png" onClick={() => onWizardClose("long_contract")} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Sales</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Purchase Order" image="/wizards/new_template_purchase_order.png" onClick={() => onWizardClose("purchase_order")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="RFQ" image="/wizards/new_template_rfq.png" onClick={() => onWizardClose("rfq")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Demand Forecast Report" image="/wizards/new_template_demand_forecast_report.png" onClick={() => onWizardClose("demand_forecast_report")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Sales Report" image="/wizards/new_template_sales_report.png" onClick={() => onWizardClose("sales_report")} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Finance</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Invoice" image="/wizards/new_template_invoice.png" onClick={() => onWizardClose("invoice")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Invoice - Xero" image="/wizards/new_template_invoice_xero.png" onClick={() => onWizardClose("invoice_xero")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Bank Statement" image="/wizards/new_template_bank_statement.png" onClick={() => onWizardClose("bank_statement")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Bank Statement - Xero" image="/wizards/new_template_bank_statement_xero.png" onClick={() => onWizardClose("bank_statement_xero")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Annual Report" image="/wizards/new_template_annual_report.png" onClick={() => onWizardClose("annual_report")} />
                </div>

            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Logistics and Shipping</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Bill of Lading" image="/wizards/new_template_bill_of_lading.png" onClick={() => onWizardClose("bill_of_lading")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Delivery Note" image="/wizards/new_template_delivery_note.png" onClick={() => onWizardClose("delivery_note")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Customs Declaration" image="/wizards/new_template_customs_declaration.png" onClick={() => onWizardClose("customs_declaration")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Shipping Label" image="/wizards/new_template_shipping_label.png" onClick={() => onWizardClose("shipping_label")} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Operations</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="BOM" image="/wizards/new_template_bom.png" onClick={() => onWizardClose("bom")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Quality Control" image="/wizards/new_template_quality_control.png" onClick={() => onWizardClose("quality_control")} />
                </div>
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Work Order" image="/wizards/new_template_work_order.png" onClick={() => onWizardClose("work_order")} />
                </div>
            </div>
            <div className="flex flex-col px-6">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">Other</h2>
            </div>
            <div className="pb-4 flex flex-wrap overflow-y-auto">
                <div className="opacity-100">
                    <WizardDocumentButtonImage title="Resume" image="/wizards/new_template_resume.png" onClick={() => onWizardClose("resume")} />
                </div>
            </div>
        </div>
    </div>;
}

function checkContextsFieldsConsistency(contexts: (t.IContextNoUUID & { uuid: string })[]): string | undefined {
    // collect all field uuids and their names
    const all_fields: Map<string, string> = new Map();
    for (const context of contexts) {
        for (const field of context.fields) {
            all_fields.set(field.uuid, `[${context.name}] ${field.name}`);
        }
    }

    const contextName = (context_idx: number, context: t.IContextNoUUID) => {
        return context.name.length > 0 ? context.name : `${context_idx + 1}`;
    }

    // check if all field uuids are present in the map
    for (const [context_idx, context] of contexts.entries()) {
        for (const field of context.fields) {
            if (field.type === "compute") {
                if (field.compute?.type === "fallback_sequence" && field.compute.fallback_sequence !== undefined) {
                    const fallback_sequence = field.compute.fallback_sequence;
                    for (const fallback_field of fallback_sequence.sequence) {
                        if (fallback_field.type === "fallback_field" && !all_fields.has(fallback_field.field_uuid)) {
                            return `Change would break fallback sequence for field ${field.name} in Step ${contextName(context_idx, context)}`;
                        }
                    }
                    if (fallback_sequence.final_fallback_field_uuid !== undefined && !all_fields.has(fallback_sequence.final_fallback_field_uuid)) {
                        return `Change would break fallback sequence for field ${field.name} in Step ${contextName(context_idx, context)}`;
                    }
                }
            }
            for (const condition of field.conditions ?? []) {
                if (condition.field_uuid !== undefined && !all_fields.has(condition.field_uuid)) {
                    return `Change would break condition for field ${field.name} in Step ${contextName(context_idx, context)}`;
                }
            }
            for (const validator of field.validators ?? []) {
                if (validator.type === "pivot_range" && !all_fields.has(validator.key_field_uuid)) {
                    return `Change would break pivot range validator for field ${field.name} in Step ${contextName(context_idx, context)}`;
                }
                if (validator.type === "equals_field" && !all_fields.has(validator.field_uuid)) {
                    return `Change would break equals field validator for field ${field.name} in Step ${contextName(context_idx, context)}`;
                }
                if (validator.type === "oc_date_validation" && !all_fields.has(validator.po_created_date_ISO_field.field_uuid)) {
                    return `Change would break OC date validation for field ${field.name} in Step ${contextName(context_idx, context)}`;
                }
                if (validator.type === "oc_date_validation" && !all_fields.has(validator.po_requested_date_ISO_field.field_uuid)) {
                    return `Change would break OC date validation for field ${field.name} in Step ${contextName(context_idx, context)}`;
                }
                if (validator.type === "oc_date_validation" && !all_fields.has(validator.extracted_part_number_field.field_uuid)) {
                    return `Change would break OC date validation for field ${field.name} in Step ${contextName(context_idx, context)}`;
                }
            }
        }
        for (const override of context.overrides) {
            if (override.override_trigger.type === "context_field_equals" && !all_fields.has(override.override_trigger.data.field_uuid)) {
                return `Change would break step override in Step ${contextName(context_idx, context)}`;
            }
        }
        if (context.extract_params.lookup_table_filter.keys !== undefined) {
            for (const key of context.extract_params.lookup_table_filter.keys) {
                if (!all_fields.has(key.field_uuid)) {
                    return `Change would break lookup table filter in Step ${contextName(context_idx, context)}`;
                }
            }
        }
        for (const inject of context.extract_params.context_injects) {
            for (const column of inject.columns) {
                if (column.type === "field" && column.field !== undefined && !all_fields.has(column.field.field_uuid)) {
                    return `Change would break context inject in Step ${contextName(context_idx, context)}`;
                }
            }
        }
    }
    // if we got till here, we have no errors
    return undefined;
}
