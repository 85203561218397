import { ArrowLeftIcon } from "@heroicons/react/24/outline";

import { classNames } from "../lib/utils";

import { Button } from "./Button";

type HeaderProps = {
    title: string;
    subtitle: string | React.ReactNode;
    org_name?: string;
    buttons: React.ReactNode;
    subbuttons?: React.ReactNode;
    tooltip?: string;
    tooltip_type?: "info" | "error";
    onBack: () => void;
}

// Two row header with structure as follows:
// [Back button] [Title]                              [Buttons]
//               [Subtitle]                           [Tooltip]
// ------------------------------------------------------------

export function TwoRowHeader({ title, subtitle, org_name, tooltip, tooltip_type, onBack, buttons, subbuttons }: HeaderProps) {
    return <div className="h-20 w-full bg-white border-b border-b-sea_blue-700">
        <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
            <Button icon={ArrowLeftIcon} onClick={onBack} />
            <div className="pl-4 flex flex-col justify-start gap-1 overflow-hidden text-ellipsis grow">
                <div className="flex flex-row items-center gap-4">
                    <h2 className="gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight" title={title}>
                        {title}
                    </h2>
                    <div className="grow"></div>
                    <div className="flex flex-row items-center">
                        {buttons}
                    </div>
                </div>
                <div className="flex flex-row items-center gap-4">
                    <div className="text-sm text-gray-400 tracking-tight flex-grow-0 flex flex-row items-center">
                        {subtitle}
                    </div>
                    <div className="grow"></div>
                    <div className="text-right whitespace-nowrap text-sm text-gray-400 pr-1">
                        {org_name && <span className={classNames(tooltip !== undefined || subbuttons !== undefined ? "pr-2" : "")}>[{org_name}]</span>}
                        {tooltip !== undefined && <span className={classNames(tooltip_type === "error" ? "text-red-400" : "text-gray-400")}>{tooltip}</span>}
                        {subbuttons}
                    </div>
                </div>
            </div>
        </div>
    </div>;
}