import { classNames } from "../lib/utils";

export type PillType = "error" | "warning" | "info" | "success" | "default" | "disabled";

export type PillProps = {
    text: string;
    type?: PillType;
    small?: boolean;
    disabled?: boolean;
    onClick?: () => void;
}

const colors = {
    error: "bg-red-100 text-red-800 font-medium",
    warning: "bg-yellow-100 text-yellow-800 font-medium",
    info: "bg-blue-100 text-blue-800 font-medium",
    success: "bg-mint-100 text-mint-800 font-medium",
    default: "bg-gray-100 text-gray-800 font-medium",
    disabled: "bg-gray-100 text-gray-500 font-medium"
};

export function Pill(props: PillProps) {
    const { text, small, disabled, onClick } = props;
    const type = disabled ? "disabled" : (props.type || "default");

    return <span
        className={classNames(
            "inline-flex items-center rounded-full px-2 text-xs truncate flex-shrink-0",
            colors[type],
            onClick !== undefined ? "cursor-pointer" : "cursor-default",
            disabled === true ? "cursor-not-allowed" : "",
            small === true ? "py-0" : "py-1"
        )}
        onClick={() => { if (onClick) { onClick(); } }}
        title={undefined}>
        {text}
    </span>
}
