import { Fragment } from "react";
import { useSelector } from "react-redux";

import { Tooltip } from "react-tooltip";
import {
    Dialog,
    DialogPanel,
    DialogBackdrop,
    DialogTitle
} from "@headlessui/react";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";

import {
    TableCellsIcon,
    TrashIcon,
    TagIcon
} from "@heroicons/react/24/outline";
import {
    TbList,
    TbTable
} from "react-icons/tb";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

import { selectUser } from "../lib/scraper.slice";
import {
    CONTEXT_TYPES,
    context_types,
    USER_ROLES
} from "../lib/consts";
import {
    ContextType,
    IContextNoUUID,
    IExtractParams,
    PreprocessExcelStrategies,
    PreprocessOcrOrientationStrategies,
    PreprocessOcrRotationStrategies,
    PreprocessOcrTableStrategies
} from "../lib/types";
import { DEFAULT_NEW_CONTEXT } from "../screens/NewTemplate";
import {
    IContextRowValidator,
    IContextValidator,
    ILookupTableBase,
    ILookupMapFilter,
    IValidationErrorStatus
} from "../lib/backend/extractions.types.generated";
import {
    classNames,
    getExcelColumnName
} from "../lib/utils";
import { BackendObj } from "../lib/backend";

import { Checkbox } from "./Checkbox";
import { Dropdown } from "./Dropdown";
import { Textbox } from "./Textbox";
import { Button } from "./Button";
import { DropdownMenu, IDropdownMenuItem } from "./DropdownMenu";
import { Classifiers } from "./Classifiers";

type ContextProperties = "type" | "remove_duplicate_records" | "detect_decimal_separator" | "default_decimal_separator" | "extraction_strategy" |
    "preprocess_excel_strategy" | "preprocess_ocr_strategy" | "orientation_segments_strategy" | "preprocess_ocr_table_strategy" |
    "max_partial_responses" | "prompt_output_format" | "try_auto_heal" | "code" | "skip_on_confirm" | "ok_to_be_empty";

type ContextDetailsSettingsProps = {
    context: IContextNoUUID;
    show_admin_details: boolean;
    isDisabled: boolean;
    setType?: (type: ContextType) => void;
    setCode?: (code: string) => void;
    setExtractParams: (extract_params: IExtractParams) => void;
}

export function ContextDetailsSettings({ context, show_admin_details, isDisabled, setType, setCode, setExtractParams }: ContextDetailsSettingsProps) {
    const user = useSelector(selectUser);
    const is_admin = user.role === USER_ROLES.admin;

    const handleContextPropertyChange = (property: ContextProperties, value: string | boolean | undefined) => {
        if (setType && property === "type") {
            setType(value as ContextType);
        } else if (setCode && property === "code") {
            setCode(value as string);
        } else if (property === "remove_duplicate_records") {
            setExtractParams({ ...context.extract_params, remove_duplicate_records: value as boolean });
        } else if (property === "detect_decimal_separator") {
            setExtractParams({ ...context.extract_params, detect_decimal_separator: value as boolean });
        } else if (property === "default_decimal_separator") {
            setExtractParams({ ...context.extract_params, default_decimal_separator: value as "," | "." });
        } else if (property === "extraction_strategy") {
            setExtractParams({ ...context.extract_params, extraction_strategy: value as "standard" | "prepend_header_page" });
        } else if (property === "preprocess_excel_strategy") {
            setExtractParams({ ...context.extract_params, preprocess_excel_strategy: value as PreprocessExcelStrategies | undefined });
        } else if (property === "preprocess_ocr_strategy") {
            setExtractParams({ ...context.extract_params, preprocess_ocr_strategy: value as PreprocessOcrRotationStrategies | undefined });
        } else if (property === "orientation_segments_strategy") {
            setExtractParams({ ...context.extract_params, orientation_segments_strategy: value as PreprocessOcrOrientationStrategies | undefined });
        } else if (property === "preprocess_ocr_table_strategy") {
            setExtractParams({ ...context.extract_params, preprocess_ocr_table_strategy: value as PreprocessOcrTableStrategies | undefined });
        } else if (property === "max_partial_responses") {
            const int_value = parseInt(value as string, 10);
            // hardcoded upper limit
            const final_value = (!isNaN(int_value) && 0 < int_value && int_value <= 20) ? int_value : DEFAULT_NEW_CONTEXT.extract_params.max_partial_responses;
            setExtractParams({ ...context.extract_params, max_partial_responses: final_value });
        } else if (property === "prompt_output_format") {
            setExtractParams({ ...context.extract_params, prompt_output_format: value as "tsv" | "json" });
        } else if (property === "try_auto_heal") {
            // only allow auto heal if we have TSV output
            const final_value = value as boolean && context.extract_params.prompt_output_format === "tsv";
            setExtractParams({ ...context.extract_params, try_auto_heal: final_value });
        } else if (property === "skip_on_confirm") {
            setExtractParams({ ...context.extract_params, skip_on_confirm: value as boolean });
        } else if (property === "ok_to_be_empty") {
            setExtractParams({ ...context.extract_params, ok_to_be_empty: value as boolean });
        }
    }

    const is_array = context.type === CONTEXT_TYPES.array;
    const is_lookup_table = context.type === CONTEXT_TYPES.lookup_table;
    const is_classifier = context.type === CONTEXT_TYPES.classifier;

    return <Fragment>
        {!is_classifier && <div className="sm:grid sm:grid-cols-3 max-w-5xl sm:items-start sm:gap-4 sm:py-6 border-t border-gray-900/10">
            {setType && is_array && <Fragment>
                <label htmlFor="remove_duplicate_records" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                    Remove duplicate records
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Checkbox
                        id="remove_duplicate_records"
                        checked={context.extract_params.remove_duplicate_records}
                        disabled={isDisabled}
                        setChecked={(value) => handleContextPropertyChange("remove_duplicate_records", value)} />
                </div>
            </Fragment>}
            {!is_lookup_table && <Fragment>
                <label htmlFor="detect_decimal_separator" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                    Detect decimal separator
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Checkbox
                        id="detect_decimal_separator"
                        checked={context.extract_params.detect_decimal_separator}
                        disabled={isDisabled}
                        setChecked={(value) => handleContextPropertyChange("detect_decimal_separator", value)} />
                </div>
            </Fragment>}
            <Fragment>
                <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                    {!is_lookup_table && context.extract_params.detect_decimal_separator && "Fallback decimal separator"}
                    {!is_lookup_table && !context.extract_params.detect_decimal_separator && "Decimal separator"}
                    {is_lookup_table && "Decimal separator"}
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Dropdown
                        values={["Decimal comma (example: 1,5)", "Decimal dot (example: 1.5)"]}
                        ids={[",", "."]}
                        selected={context.extract_params.default_decimal_separator}
                        disabled={isDisabled}
                        onChange={(id) => handleContextPropertyChange("default_decimal_separator", id)} />
                </div>
            </Fragment>

            <Fragment>
                <label htmlFor="skip_on_confirm" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                    Hide on confirmation page
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Checkbox
                        id="skip_on_confirm"
                        checked={context.extract_params.skip_on_confirm}
                        disabled={isDisabled}
                        setChecked={(value) => handleContextPropertyChange("skip_on_confirm", value)} />
                </div>
            </Fragment>

            <Fragment>
                <label htmlFor="ok_to_be_empty" className="flex flex-row items-center text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                    Can have empty results
                    <QuestionMarkCircleIcon
                        className="w-5 h-5 ml-1 text-gray-400"
                        data-tooltip-id={`ok-to-be-empty-tooltip`}
                        data-tooltip-html={`<p class="pb-4 max-w-sm">If true, no warning will be displayed in the Results page when step has empty result.</p>`}
                    />
                    <Tooltip id={`ok-to-be-empty-tooltip`} className="z-50" />
                </label>

                <div className="mt-2 sm:col-span-2 sm:mt-0">
                    <Checkbox
                        id="ok_to_be_empty"
                        checked={context.extract_params.ok_to_be_empty}
                        disabled={isDisabled}
                        setChecked={(value) => handleContextPropertyChange("ok_to_be_empty", value)} />
                </div>
            </Fragment>
        </div>}

        {context.type === "classifier" && (
            <div className="mt-2">
                <Fragment>
                    <label className="block text-sm font-medium leading-6 text-gray-900 sm:pt-0.5">
                        Classifiers
                    </label>
                    <div className="mt-2">
                        <Classifiers
                            classifiers={context.extract_params.classifiers || []}
                            isDisabled={isDisabled}
                            setClassifiers={(new_classifiers) => setExtractParams({
                                ...context.extract_params,
                                classifiers: new_classifiers
                            })}
                        />
                    </div>
                </Fragment>
            </div>
        )}


        {show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:pt-6 border-t border-gray-900/10 text-sm font-bold leading-6 text-gray-400">
            Admin settings
        </div>}

        {!is_classifier && show_admin_details && is_admin && <div className="sm:grid sm:grid-cols-4 max-w-5xl sm:items-start sm:gap-4 sm:py-6">
            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Code
            </label>
            <div className="mt-2 sm:mt-0 font-mono">
                <Textbox
                    placeholder="Code"
                    disabled={isDisabled}
                    value={context.code}
                    onChange={(value) => handleContextPropertyChange("code", value)} />
            </div>

            {!is_lookup_table && <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                How many rows do you expect?
            </label>}
            {!is_lookup_table && <div className="mt-2 sm:mt-0">
                <Dropdown
                    values={["Many", "One", "Hierarchical"]}
                    ids={context_types}
                    selected={context.type}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("type", id)} />
            </div>}

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Extraction strategy
            </label>
            <div className="mt-2 sm:mt-0">
                <Dropdown
                    values={["Standard extraction", "Prepend header pages"]}
                    ids={["standard", "prepend_header_page"]}
                    selected={context.extract_params.extraction_strategy}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("extraction_strategy", id)} />
            </div>

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Excel preprocessing strategy
            </label>
            <div className="mt-2 sm:mt-0">
                <Dropdown
                    ids={["derive_from_template", "col_names_sparse", "col_names_dense_zero", "col_names_dense_empty", "without_col_names"]}
                    values={["Inherit from template", "Column names (sparse)", "Column names (dense with zero)", "Column names (dense with empty)", "Without column names"]}
                    selected={context.extract_params.preprocess_excel_strategy || "derive_from_template"}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("preprocess_excel_strategy", id === "derive_from_template" ? undefined : id)} />
            </div>

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                OCR preprocessing strategy
            </label>
            <div className="mt-2 sm:mt-0">
                <Dropdown
                    ids={["derive_from_template", "simple", "fix_rotation"]}
                    values={["Inherit from template", "Simple", "Fix rotations"]}
                    selected={context.extract_params.preprocess_ocr_strategy || "derive_from_template"}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("preprocess_ocr_strategy", id === "derive_from_template" ? undefined : id)} />
            </div>

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Rotation-segment handling strategy
            </label>
            <div className="mt-2 sm:mt-0">
                <Dropdown
                    ids={["derive_from_template", "as_is", "only_main", "segment"]}
                    values={["Inherit from template", "Keep rotation as they are", "Only keep the main rotations", "Segment texts by rotation"]}
                    selected={context.extract_params.orientation_segments_strategy || "derive_from_template"}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("orientation_segments_strategy", id === "derive_from_template" ? undefined : id)} />
            </div>

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                OCR table-handling strategy
            </label>
            <div className="mt-2 sm:mt-0">
                <Dropdown
                    ids={["derive_from_template", "plain_text_only", "markdown_only", "markdown_and_plain_text"]}
                    values={["Inherit from template", "Use normal text only", "Use markdown only", "Use markdown and normal text"]}
                    selected={context.extract_params.preprocess_ocr_table_strategy || "derive_from_template"}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("preprocess_ocr_table_strategy", id === "derive_from_template" ? undefined : id)} />
            </div>

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Maximum partial responses
            </label>
            <div className="mt-2 sm:mt-0">
                <Textbox
                    placeholder="0-20"
                    disabled={isDisabled}
                    value={context.extract_params.max_partial_responses.toString()}
                    onChange={(value) => handleContextPropertyChange("max_partial_responses", value)} />
            </div>

            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Prompt output format
            </label>
            <div className="mt-2 sm:mt-0">
                <Dropdown
                    values={["JSON", "TSV"]}
                    ids={["json", "tsv"]}
                    selected={context.extract_params.prompt_output_format}
                    disabled={isDisabled}
                    onChange={(id) => handleContextPropertyChange("prompt_output_format", id)} />
            </div>

            <label htmlFor="try_auto_heal" className="block text-sm font-medium leading-6 text-gray-400 sm:pt-1.5">
                Try auto-heal
            </label>
            <div className="mt-2 sm:mt-0">
                <Checkbox
                    id="try_auto_heal"
                    checked={context.extract_params.try_auto_heal}
                    disabled={isDisabled}
                    setChecked={(value) => handleContextPropertyChange("try_auto_heal", value)} />
            </div>

        </div>}
    </Fragment>;
}

type LookupTableContextDetailsProps = {
    prev_contexts: (IContextNoUUID & { uuid: string })[];
    context: IContextNoUUID;
    disabled: boolean;
    lookup_tables: ILookupTableBase[];
    updateLookupTableFilter: (lookup_table_uuid: string) => void;
    updateLookupTableKeys: (keys: ILookupMapFilter[]) => void;
}

export function LookupTableContextDetails(props: LookupTableContextDetailsProps) {
    const { prev_contexts, context, disabled, lookup_tables, updateLookupTableFilter, updateLookupTableKeys } = props;

    const prev_fields = prev_contexts
        .map((context, context_idx) => context.fields.map((field) => ({
            context_name: context.name.length > 0 ? `Step ${context_idx + 1}. ${context.name}` : `Step ${context_idx + 1}`,
            field_name: field.name,
            field_uuid: field.uuid,
        }))).flat();

    const getLookupTableHeaderValues = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((header, idx) => `${getExcelColumnName(idx)} - ${header}`);
    };
    const getLookupTableHeaderIds = (lookup_table_uuid: string) => {
        const selected_lookup_table = lookup_tables
            .find((lookup_table) => lookup_table.uuid === lookup_table_uuid);
        const range_lookup_table_headers = selected_lookup_table?.headers || [];
        return range_lookup_table_headers.map((_header, idx) => `${idx}`);
    };

    const updateLookupTableKey = (key_idx: number, key: ILookupMapFilter) => {
        const keys = context.extract_params.lookup_table_filter.keys || [];
        keys[key_idx] = key;
        updateLookupTableKeys(keys);
    }

    const addLookupTableKey = () => {
        if (prev_fields.length === 0) {
            return;
        }
        const keys = context.extract_params.lookup_table_filter.keys || [];
        keys.push({ field_uuid: prev_fields[0]?.field_uuid || "", header_idx: 0 });
        updateLookupTableKeys(keys);
    }

    const delLookupTableKey = (key_idx: number) => {
        const keys = context.extract_params.lookup_table_filter.keys || [];
        keys.splice(key_idx, 1);
        updateLookupTableKeys(keys);
    }

    const selected_lookup_table = lookup_tables.find((lookup_table) => lookup_table.uuid === context.extract_params.lookup_table_filter.lookup_table_uuid);

    return <div className="w-full flex flex-col gap-y-4 border-b border-gray-200 py-6">
        <div className="w-full flex flex-col gap-y-4">
            <label htmlFor="title" className="pt-2  pb-1 block text-sm text-gray-900">
                Lookup Table
            </label>
            <div className="w-full">
                <Dropdown
                    values={lookup_tables.map((lookup_table) => lookup_table.name)}
                    ids={lookup_tables.map((lookup_table) => lookup_table.uuid)}
                    selected={context.extract_params.lookup_table_filter.lookup_table_uuid || ""}
                    disabled={disabled}
                    onChange={(id: string) => updateLookupTableFilter(id)} />
            </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4">
            {(context.extract_params.lookup_table_filter.keys ?? []).length === 0 && <Fragment>
                <div className="pt-4 flex flex-col items-start gap-2 col-span-2 text-red-500">
                    <div className="text-sm font-semibold">
                        No Lookup Table Match Keys defined.
                    </div>
                    <div className="text-sm">
                        This is most likely a mistake.
                        Lookup table has {selected_lookup_table?.active_version?.no_of_rows} rows and when no match keys are defined, all rows will be included.
                    </div>
                </div>
            </Fragment>}
        </div>

        {(context.extract_params.lookup_table_filter.keys ?? []).map((key, key_idx) => <Fragment key={key_idx}>
            <div className="grid grid-cols-1 gap-x-4">
                <div className="pt-4 flex flex-row items-center">
                    <div className="text-sm font-semibold">
                        {key_idx + 1}. Lookup Table Match Key
                    </div>
                    <div className="grow" />
                    <div className="ml-2"><Button icon={TrashIcon} onClick={() => delLookupTableKey(key_idx)} /></div>
                </div>
                <div className="grid grid-cols-5 gap-x-4">
                    <div className="col-span-2">
                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                            Field Key
                        </label>
                        <div className="w-full">
                            <Dropdown
                                values={prev_fields.map((field) => `[${field.context_name}] ${field.field_name}`)}
                                ids={prev_fields.map((field) => field.field_uuid)}
                                selected={key.field_uuid}
                                disabled={disabled}
                                onChange={(field_uuid: string) => updateLookupTableKey(key_idx, { ...key, field_uuid })} />
                        </div>
                    </div>
                    <div className="col-span-1">
                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                            Type
                        </label>
                        <div className="w-full">
                            <Dropdown
                                values={["=", "≈", "contains"]}
                                ids={["exact", "approx", "contains"]}
                                selected={key.comparison || "exact"}
                                disabled={disabled}
                                onChange={(comparison: string) => updateLookupTableKey(key_idx, { ...key, comparison: comparison as "exact" | "approx" | "contains" })} />
                        </div>
                    </div>
                    <div className="col-span-2">
                        <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                            Lookup Key
                        </label>
                        <div className="w-full">
                            <Dropdown
                                values={getLookupTableHeaderValues(context.extract_params.lookup_table_filter.lookup_table_uuid || "")}
                                ids={getLookupTableHeaderIds(context.extract_params.lookup_table_filter.lookup_table_uuid || "")}
                                selected={key.header_idx.toString() || "0"}
                                disabled={disabled}
                                onChange={(key_header_idx: string) => updateLookupTableKey(key_idx, { ...key, header_idx: parseInt(key_header_idx, 10) })} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="py-3 grid grid-cols-3 gap-x-4">
                <fieldset>
                    <legend className="sr-only">Compensate for OCR errors</legend>
                    <div className="space-y-5">
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <Checkbox
                                    checked={key.compensate_ocr_errors ?? false}
                                    setChecked={(checked) => updateLookupTableKey(key_idx, { ...key, compensate_ocr_errors: checked })}
                                    id={`step_filter_compensate_ocr_errors_${key_idx}`}
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                <label htmlFor={`step_filter_compensate_ocr_errors_${key_idx}`} className="font-medium text-gray-900">
                                    Compensate
                                </label>
                                <QuestionMarkCircleIcon
                                    className="w-5 h-5 ml-1 text-gray-400"
                                    data-tooltip-id={`compensate-ocr-errors-tooltip-${key_idx}`}
                                    data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have OCR errors like confusing 0 and O, 1 and I, etc.This will help in matching the OCR extracted value with the lookup table value.</p>`}
                                />
                                <Tooltip id={`compensate-ocr-errors-tooltip-${key_idx}`} className="z-50" />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <legend className="sr-only">Compensate leading zeros in ID numbers</legend>
                    <div className="space-y-5">
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <Checkbox
                                    checked={key.compensate_leading_zeros ?? false}
                                    setChecked={(checked) => updateLookupTableKey(key_idx, { ...key, compensate_leading_zeros: checked })}
                                    id={`step_filter_compensate_leading_zeros_${key_idx}`}
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                <label htmlFor={`step_filter_compensate_leading_zeros_${key_idx}`} className="font-medium text-gray-900">
                                    Leading zeros
                                </label>
                                <QuestionMarkCircleIcon
                                    className="w-5 h-5 ml-1 text-gray-400"
                                    data-tooltip-id={`leading-zeros-tooltip-${key_idx}`}
                                    data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you have ID numbers with leading zeros. This will help in matching the extracted value with the lookup table value even when the number of leading zeros is different.</p>`}
                                />
                                <Tooltip id={`leading-zeros-tooltip-${key_idx}`} className="z-50" />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset>
                    <legend className="sr-only">Case insensitive</legend>
                    <div className="space-y-5">
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <Checkbox
                                    checked={key.case_insensitive ?? true}
                                    setChecked={(checked) => updateLookupTableKey(key_idx, { ...key, case_insensitive: checked })}
                                    id={`step_filter_case_insensitive_${key_idx}`}
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                <label htmlFor={`step_filter_case_insensitive_${key_idx}`} className="font-medium text-gray-900">
                                    Ignore case
                                </label>
                                <QuestionMarkCircleIcon
                                    className="w-5 h-5 ml-1 text-gray-400"
                                    data-tooltip-id={`case-insensitive-tooltip-${key_idx}`}
                                    data-tooltip-html={`<p class="pb-4 max-w-sm">Useful when you do not want to differentiate between uppercase and lowercase letters.</p>`}
                                />
                                <Tooltip id={`case-insensitive-tooltip-${key_idx}`} className="z-50" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            {key.comparison === "contains" && <div className="col-span-2">
                <fieldset>
                    <legend className="sr-only">Match whole words</legend>
                    <div className="space-y-5">
                        <div className="relative flex items-start">
                            <div className="flex h-6 items-center">
                                <Checkbox
                                    checked={key.match_whole_word ?? false}
                                    setChecked={(checked) => updateLookupTableKey(key_idx, { ...key, match_whole_word: checked })}
                                    id={`match_whole_word_${key_idx}`}
                                />
                            </div>
                            <div className="ml-3 text-sm leading-6 flex flex-row items-center">
                                <label htmlFor={`match_whole_word_${key_idx}`} className="font-medium text-gray-900">
                                    Match whole word
                                </label>
                                <QuestionMarkCircleIcon
                                    className="w-5 h-5 ml-1 text-gray-400"
                                    data-tooltip-id={`match-whole-word-tooltip-${key_idx}`}
                                    data-tooltip-html={`<p class="pb-4 max-w-sm">The lookup value has to be contained within the extracted value as a whole word (not as a part of a word).</p>`}
                                />
                                <Tooltip id={`match-whole-word-tooltip-${key_idx}`} className="z-50" />
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>}
        </Fragment>)}
        <div className="flex flex-row items-center justify-end">
            <Button text="Add Key" onClick={addLookupTableKey} disabled={disabled} />
        </div>
    </div>;
}

type NewContextModalProps = {
    open: boolean;
    show_classifier: boolean;
    show_lookup_table: boolean;
    onClose: (result: ContextType | "none") => void;
}

export function NewContextModal(props: NewContextModalProps) {
    const { open, show_classifier, show_lookup_table, onClose } = props;

    return (
        <Dialog open={open} onClose={() => onClose("none")} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <DialogPanel
                        transition
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                    >
                        <div className="mt-3 text-center sm:mt-5">
                            <DialogTitle as="h3" className="text-base font-semibold text-gray-900">
                                Add New Step
                            </DialogTitle>
                        </div>
                        <div className="mt-5 flex flex-col gap-y-2">
                            <NewContextModalButton
                                title="Table"
                                description="Useful when you have multiple rows of data."
                                icon={TbTable}
                                onClick={() => onClose("array")} />
                            <NewContextModalButton
                                title="List"
                                description="Useful when you have one value per field."
                                icon={TbList}
                                onClick={() => onClose("object")} />
                            {show_lookup_table && <NewContextModalButton
                                title="Lookup Table"
                                description="Pull-in information from a lookup table."
                                icon={TableCellsIcon}
                                onClick={() => onClose("lookup_table")} />}
                            {show_classifier && <NewContextModalButton
                                title="Classifier"
                                description="Useful when you have to filter documents or individual pages."
                                icon={TagIcon}
                                onClick={() => onClose("classifier")} />}
                        </div>
                        <div className="mt-5 flex flex-col gap-y-2">
                            <Button text="None" onClick={() => onClose("none")} />
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}

type NewContextModalButtonProps = {
    title: string;
    description: string;
    icon: any;
    selected?: boolean;
    onClick: () => void;
}

export function NewContextModalButton(props: NewContextModalButtonProps) {
    const { title, description, selected, onClick } = props;

    // Add this helper function to determine icon size classes
    const getIconClasses = () => {
        // Check if the icon is TagIcon specifically
        if (props.icon === TagIcon) {
            return "w-20 h-20 text-space_blue-600";
        }
        return "w-12 h-12 text-space_blue-600";
    };

    return <button type="button" onClick={onClick}
        className={classNames(
            "rounded-md mx-1 p-4 ring-1 ring-inset flex flex-row items-center gap-4 hover:bg-gray-50",
            selected ? "bg-space_blue-50 ring-space_blue-300" : "ring-gray-300"
        )}
    >
        <props.icon className={getIconClasses()} />
        <div className="flex-grow">
            <div className="flex flex-col items-start gap-2">
                <span className="text-sm text-left font-medium text-gray-900 overflow-hidden text-ellipsis">{title}</span>
                <span className="text-sm text-left text-gray-500">{description}</span>
            </div>
        </div>
    </button >;
}

type ContextRulesProps = {
    context: IContextNoUUID;
    setRowValidators: (row_validators: IContextRowValidator[]) => void;
    setContextValidators: (context_validators: IContextValidator[]) => void;
}

export function ContextRules(props: ContextRulesProps) {
    const { context, setRowValidators, setContextValidators } = props;

    const user = useSelector(selectUser);
    const is_admin = user && user.role === USER_ROLES.admin;

    const addRowValidator = (type: "formula") => {
        const row_validators = context.row_validators;
        if (type === "formula") {
            row_validators.push({ type: "formula", formula: "", name: "", analytics_tag: "", level: "warn" });
        }
        setRowValidators(row_validators);
    };

    const updateRowValidator = (validator_idx: number, field: "name" | "analytics_tag" | "level" | "formula", value: string) => {
        const row_validators = context.row_validators;
        if (field === "name") {
            row_validators[validator_idx].name = value as string;
        } else if (field === "analytics_tag") {
            row_validators[validator_idx].analytics_tag = value as string;
        } else if (field === "level") {
            row_validators[validator_idx].level = value as IValidationErrorStatus;
        } else if (row_validators[validator_idx].type === "formula" && field === "formula") {
            row_validators[validator_idx].formula = value as string;
        }
        setRowValidators(row_validators);
    };

    const deleteRowValidator = (validator_idx: number) => {
        const row_validators = context.row_validators;
        row_validators.splice(validator_idx, 1);
        setRowValidators(row_validators);
    };

    const addContextValidator = (type: "count" | "formula") => {
        const context_validators = context.context_validators;
        if (type === "count") {
            context_validators.push({ type: "count", name: "", analytics_tag: "", level: "warn" });
        } else if (type === "formula") {
            context_validators.push({ type: "formula", formula: "", name: "", analytics_tag: "", level: "warn" });
        }
        setContextValidators(context_validators);
    };

    const updateContextValidator = (validator_idx: number, field: "name" | "analytics_tag" | "level" | "min_count" | "max_count" | "formula", value: string | number) => {
        const context_validators = context.context_validators;
        const validator = context_validators[validator_idx];
        if (field === "name") {
            validator.name = value as string;
        } else if (field === "analytics_tag") {
            validator.analytics_tag = value as string;
        } else if (field === "level") {
            validator.level = value as IValidationErrorStatus;
        } else if (validator.type === "count" && field === "min_count") {
            const value_str = value as string;
            if (value_str === "") {
                validator.min_count = undefined;
            } else {
                validator.min_count = parseInt(value_str);
            }
        } else if (validator.type === "count" && field === "max_count") {
            const value_str = value as string;
            if (value_str === "") {
                validator.max_count = undefined;
            } else {
                validator.max_count = parseInt(value_str);
            }
        } else if (validator.type === "formula" && field === "formula") {
            validator.formula = value as string;
        }
        setContextValidators(context_validators);
    };

    const deleteContextValidator = (validator_idx: number) => {
        const context_validators = context.context_validators;
        context_validators.splice(validator_idx, 1);
        setContextValidators(context_validators);
    };

    const validateJSFormula = async (formula: string) => {
        const result = await BackendObj.extractions.validateJsCode({ code: formula });
        if (!result.valid) {
            alert(`Error in formula: ${result.error}`);
        }
    }

    const rules_items: IDropdownMenuItem[] = [
        { title: "Row Formula Constraint", separator: is_admin, admin_only: true, onClick: () => { addRowValidator("formula"); } },
        { title: "Step Count Constraint", onClick: () => { addContextValidator("count"); } },
        { title: "Step Formula Constraint", admin_only: true, onClick: () => { addContextValidator("formula"); } }
    ];

    return <div className="max-w-5xl pb-6 text-sm flex flex-col gap-4 border-t border-gray-900/10">
        <div className="pt-4 flex flex-row items-center w-full">
            <DropdownMenu title="Create new rule" items={rules_items} />
        </div>

        {context.row_validators.length > 0 && <div className="flex flex-col gap-y-4">
            {context.row_validators.map((validator, validator_idx) => {
                return <div key={validator_idx}>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                            {validator_idx + 1}.&nbsp;
                            {validator.type === "formula" && "Row Constraint Logic"}
                        </div>
                        <div className="grow" />
                        <div className="mr-3 flex flex-row items-center gap-x-2">
                            <Dropdown
                                values={["Notify", "Require", "Reject"]}
                                ids={["warn", "fail", "reject"]}
                                selected={validator.level}
                                onChange={(level: string) => updateRowValidator(validator_idx, "level", level)}
                            />
                        </div>
                        <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteRowValidator(validator_idx)} /></div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 pb-4">
                        <div className="">
                            <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                Name
                            </label>
                            <div className="w-full">
                                <Textbox
                                    value={validator.name}
                                    onChange={(name: string) => updateRowValidator(validator_idx, "name", name)} />
                            </div>
                        </div>
                        <div className="">
                            <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                Analytics Tag
                            </label>
                            <div className="w-full">
                                <Textbox
                                    value={validator.analytics_tag}
                                    onChange={(analytics_tag: string) => updateRowValidator(validator_idx, "analytics_tag", analytics_tag)} />
                            </div>
                        </div>
                    </div>

                    {validator.type === "formula" && <div className="w-full flex flex-col gap-y-2 font-mono">
                        <div className="w-full shadow border">
                            <CodeMirror
                                value={validator.formula || ""}
                                height="200px"
                                theme="light"
                                extensions={[javascript()]}
                                readOnly={!is_admin}
                                onBlur={async () => { await validateJSFormula(validator.formula ?? "") }}
                                onChange={(formula: string) => updateRowValidator(validator_idx, "formula", formula)} />
                        </div>
                        <div className="w-full text-xs text-gray-400">
                            <span className="font-bold pr-2">Output type:</span>
                            <span className="font-mono">boolean | {"{ status: boolean, reason: string }"}</span>
                        </div>
                    </div>}
                </div>;
            })}
        </div>}

        {context.context_validators.length > 0 && <div className="mt-4">
            {context.context_validators.map((validator, validator_idx) => {
                return <div key={validator_idx}>
                    <div className="flex flex-row items-center">
                        <div className="text-sm font-medium leading-6 text-gray-900">
                            {context.row_validators.length + validator_idx + 1}.&nbsp;
                            {validator.type === "formula" && "Step Constraint Logic"}
                        </div>
                        <div className="grow" />
                        <div className="mr-3 flex flex-row items-center gap-x-2">
                            <Dropdown
                                values={["Notify", "Require", "Reject"]}
                                ids={["warn", "fail", "reject"]}
                                selected={validator.level}
                                onChange={(level: string) => updateContextValidator(validator_idx, "level", level)}
                            />
                        </div>
                        <div className="ml-2"><Button icon={TrashIcon} onClick={() => deleteContextValidator(validator_idx)} /></div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-4 pb-4">
                        <div className="">
                            <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                Name
                            </label>
                            <div className="w-full">
                                <Textbox
                                    value={validator.name}
                                    onChange={(name: string) => updateContextValidator(validator_idx, "name", name)} />
                            </div>
                        </div>
                        <div className="">
                            <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                Analytics Tag
                            </label>
                            <div className="w-full">
                                <Textbox
                                    value={validator.analytics_tag}
                                    onChange={(analytics_tag: string) => updateContextValidator(validator_idx, "analytics_tag", analytics_tag)} />
                            </div>
                        </div>
                    </div>

                    {validator.type === "count" && <div className="grid grid-cols-2 gap-x-4 pb-4">
                        <div className="">
                            <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                Min Count
                            </label>
                            <div className="w-full">
                                <Textbox
                                    value={validator.min_count?.toString() ?? ""}
                                    onChange={(min_count: string) => updateContextValidator(validator_idx, "min_count", min_count)} />
                            </div>
                        </div>
                        <div className="">
                            <label htmlFor="title" className="pt-2 pb-1 block text-sm text-gray-900">
                                Max Count
                            </label>
                            <div className="w-full">
                                <Textbox
                                    value={validator.max_count?.toString() ?? ""}
                                    onChange={(max_count: string) => updateContextValidator(validator_idx, "max_count", max_count)} />
                            </div>
                        </div>
                    </div>}

                    {validator.type === "formula" && <div className="w-full flex flex-col gap-y-2 font-mono">
                        <div className="w-full shadow border">
                            <CodeMirror
                                value={validator.formula || ""}
                                height="200px"
                                theme="light"
                                extensions={[javascript()]}
                                readOnly={!is_admin}
                                onBlur={async () => { await validateJSFormula(validator.formula ?? "") }}
                                onChange={(formula: string) => updateContextValidator(validator_idx, "formula", formula)} />
                        </div>
                        <div className="w-full text-xs text-gray-400">
                            <span className="font-bold pr-2">Output type:</span>
                            <span className="font-mono">boolean | {"{ status: boolean, reason: string }"}</span>
                        </div>
                    </div>}
                </div>;
            })}
        </div>}

    </div>;
}