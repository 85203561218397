import {
    Fragment,
    useEffect,
    useState
} from "react";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams
} from "react-router-dom";
import { useSelector } from "react-redux";

import {
    ArrowPathIcon,
    ClipboardDocumentIcon,
    ExclamationTriangleIcon,
    PencilIcon,
    PlusCircleIcon,
    TrashIcon
} from "@heroicons/react/24/outline";

import {
    classNames,
    flattenScrapeDocuments,
    prettySmartDateTime,
    setDocumentTitle
} from "../lib/utils";
import {
    IItem,
    IItemAttachmentBase,
    IItemValidationSummary,
    IScrapeDocument
} from "../lib/backend/extractions.types.generated";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    CONTEXT_TYPES,
    EXTRACT_CONFIRMATION_STATUS,
    EXTRACT_JOB_STATUS,
    USER_ROLES
} from "../lib/consts";
import { IEvalBundle } from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";

import { PdfViewerHorizontal } from "../components/PdfViewer";
import { ExcelViewer } from "../components/ExcelViewer";
import { Button } from "../components/Button";
import { NewExampleModal, EditExampleModal } from "../components/ExampleModals";
import { ITab, Tabs } from "../components/Tabs";
import { SupportLink } from "../components/SupportLink";
import { ValidationSummary } from "../components/ValidationSummary";
import { ViewItemTables } from "../components/ItemTables";
import { ExtractButton } from "../components/ExtractButton";
import { ConfirmModal } from "../components/ConfirmModal";
import { ButtonMenu, IButtonMenuItem } from "../components/ButtonMenu";
import { LongText } from "../components/LongText";
import { ErrorMessageBar } from "../components/ErrorMessageBar";
import { LoadingSpinner } from "../components/LoadingSpinner";
import { ItemBreadcrumb } from "../components/ItemBreadcrumb";
import { ViewItemScrapeDocuments } from "../components/ItemContent";
import { TwoRowHeader } from "../components/Header";

function EmailContentHtml(props: { item: IItem, html: string }) {
    const { item, html } = props;

    // go over attachments and see if we have any cid: links
    let clean_html = html;
    for (const attachment of item.attachments) {
        if (attachment.cidname !== undefined) {
            const cid = `cid:${attachment.cidname}`;
            const url = `/api/attachment/get?uuid=${attachment.uuid}`;
            clean_html = clean_html.replace(new RegExp(cid, "g"), url);
        }
    }

    return <iframe
        title="email"
        srcDoc={clean_html}
        style={{ width: "100%", height: "400px" }}
        className="border bg-white mt-2"
        sandbox="allow-same-origin" // Allows the iframe content to be treated as being from the same origin
    />;
}

function EmailContent(props: { item: IItem }) {
    const { item } = props;

    const is_html = item.details.email_html !== undefined;

    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <div className="pb-2"><span className="font-bold">From:</span> {item.details.from_email_address}</div>
        {item.details.to_email_addresses && <div className="pb-2"><span className="font-bold">To:</span> {item.details.to_email_addresses?.join(", ")}</div>}
        {item.details.email_subject && <div className="pb-2"><span className="font-bold">Subject:</span> {item.details.email_subject}</div>}
        {/* render html from item.details.email_html in iframe */}
        {is_html && <EmailContentHtml item={item} html={item.details.email_html || ""} />}
        {/* render text from item.text */}
        {!is_html && <LongText text={item.details.email_text || ""} />}
    </div>;
}

function DocumentContent(props: { item: IItem }) {
    const { item } = props;

    return item.attachments.length > 0 ? <Attachment attachment={item.attachments[0]} /> :
        <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
            {item.details.document_subject && <div className="pb-2 mb-2 border-b"><span className="font-bold">Subject:</span> {item.details.document_subject}</div>}
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>;
}

function TextContent(props: { documents: IScrapeDocument[] }) {
    const { documents } = props;

    const onCopy = () => {
        navigator.clipboard.writeText(flattenScrapeDocuments(documents));
    }

    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <div className="flex flex-row">
            <div className="flex-grow" />
            <button
                type="button"
                className="mr-4 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-space_blue-500 focus:ring-offset-2"
                onClick={onCopy}
            >
                <span className="sr-only">Copy to clipboard</span>
                <ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>
        <LongText text={flattenScrapeDocuments(documents)} />
    </div>;
}

function InputTextContent(props: { item: IItem }) {
    const { item } = props;

    return <div className="px-2 py-4 mx-2 border border-gray-200 rounded shadow bg-gray-50">
        <ViewItemScrapeDocuments item={item} contexts={item.template.contexts} />
    </div>;
}

function Attachment(props: { attachment: IItemAttachmentBase }) {
    const { attachment } = props;

    const url = `/api/attachment/get?uuid=${attachment.uuid}`;
    const link = <a
        className="pl-2 pt-2 text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
        href={url} target="_blank" rel="noreferrer">
        [download]
    </a>

    const lc_filename = attachment.filename.toLowerCase();

    if (
        attachment.mimetype.startsWith("text/") ||
        lc_filename.endsWith(".txt")
    ) {
        return <TextContent documents={[attachment.document]} />;
    }

    if (
        attachment.mimetype.startsWith("image/") ||
        lc_filename.endsWith(".jpg") ||
        lc_filename.endsWith(".jpeg") ||
        lc_filename.endsWith(".png") ||
        lc_filename.endsWith(".tiff") ||
        lc_filename.endsWith(".bmp") ||
        lc_filename.endsWith(".gif")
    ) {
        return <div className="mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <img src={url} alt={attachment.filename} />
            {link}
        </div>;
    }

    if (attachment.mimetype === "application/pdf" || lc_filename.endsWith(".pdf")) {
        return <div className="mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50 overflow-y-auto">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <PdfViewerHorizontal display_name={attachment.filename} file={url} max_width={300} />
            {link}
        </div>;
    }

    if (lc_filename.endsWith(".xlsx") || lc_filename.endsWith(".xls") || lc_filename.endsWith(".ods")) {
        return <div className="mx-2 px-4 py-4 border border-gray-200 rounded shadow bg-gray-50">
            <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
            <ExcelViewer attachment_uuid={attachment.uuid} />
            {link}
        </div>;
    }

    const extension = attachment.filename.split(".").pop();
    return <div className="text-sm mx-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
        <h3 className="text-sm p-2 text-gray-600">{attachment.filename}</h3>
        <div className="pb-2" >Attachment of type <span className="font-semibold font-mono">{extension}</span> is not supported for extraction and cannot be displayed.</div>
        {link}
    </div>;
};

export function ItemDetail() {
    const navigate = useNavigate();

    const { item_uuid } = useParams<{ item_uuid: string | undefined }>();
    const [search_params] = useSearchParams();
    const job_uuid = search_params.get("job_uuid");

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const memberships = useSelector(selectMemberships);

    const [item, setItem] = useState<IItem | undefined>(undefined);
    const [validation_summary, setValidationSummary] = useState<IItemValidationSummary | undefined>(undefined);
    const [job_status, setJobStatus] = useState<string | undefined>(undefined);

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selected_tab, setSelectedTab] = useState<string>("");

    const [item_example_uuid, setItemExampleUuid] = useState<string | undefined>(undefined);
    const [is_new_example_modal_open, setIsNewExampleModalOpen] = useState<boolean>(false);
    const [is_edit_example_modal_open, setIsEditExampleModalOpen] = useState<boolean>(false);
    const [show_confirm, setShowConfirm] = useState(false);
    const [allow_remove, setAllowRemove] = useState(true);
    const [message, setMessage] = useState<string | undefined>(undefined);

    useEffect(() => {
        setItem(undefined);
        setValidationSummary(undefined);
        setJobStatus(undefined);
        setMessage(undefined);

        if (item_uuid !== undefined) {
            BackendObj.extractions.getItem({ item_uuid }).then(({ item: new_item, validation_summary: new_validation_summary, message: new_message }) => {
                setItem(new_item);
                setValidationSummary(new_validation_summary);
                setMessage(new_message);
                if (new_item?.details.extract_job_uuid !== undefined) {
                    const job_uuid = new_item.details.extract_job_uuid;
                    // loop for checking status of selected item
                    Backend.getExtractJobStatus({ job_uuid }).then((status) => {
                        // update status
                        setJobStatus(status);
                    });
                }
            });
        }
    }, [item_uuid]);

    useEffect(() => {
        if (item === undefined) {
            setDocumentTitle("Extraction - loading...", env);
        } else {
            setDocumentTitle(`Extraction - ${item.name.length > 0 ? item.name : "[no subject]"}`, env);
        }
    }, [env, item]);

    useEffect(() => {
        if (item === undefined) { return; }
        const tabs: ITab[] = [];
        if (item.details.input_type === "email") {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.input_type === "documents") {
            if (item.attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.from_email_address !== undefined) {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.document_subject !== undefined || item.details.document_filename !== undefined) {
            if (item.attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else {
            tabs.push({ name: "Text", key: "text" });
        }
        for (const attachment of item.attachments) {
            tabs.push({ name: attachment.filename, key: attachment.uuid });
        }
        if (user.role === USER_ROLES.admin) {
            tabs.push({ name: "Input Text", key: "input_text" });
        }
        setTabs(tabs);
        if (tabs.length > 0) {
            setSelectedTab(tabs[0].key);
        }
        setItemExampleUuid(item?.details.clone_example_item_uuid);
    }, [user, item]);

    const navigateBack = () => {
        if (job_uuid) {
            navigate(`/job/${job_uuid}`);
        } else {
            navigate("/items");
        }
    }

    const statusRefresh = async () => {
        setJobStatus(undefined);
        if (item === undefined) { return; }
        if (item.details.extract_job_uuid === undefined) { return; }
        const job_uuid = item.details.extract_job_uuid;
        const status = await Backend.getExtractJobStatus({ job_uuid });
        setJobStatus(status);
        // if job is not running, refresh item to get latest data
        if (status !== EXTRACT_JOB_STATUS.running && item_uuid !== undefined) {
            const { item: new_item, validation_summary: new_validation_summary, message: new_message } = await BackendObj.extractions.getItem({ item_uuid });
            setItem(new_item);
            setValidationSummary(new_validation_summary);
            setMessage(new_message);
        }
    };

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (item_uuid !== undefined && is_remove) {
            setAllowRemove(false);
            // delete from backend
            await Backend.deleteItem({ item_uuid });
            // redirect to items list
            navigate("/items");
        }
    };

    const startCreateExample = () => {
        setIsNewExampleModalOpen(true);
    }

    const createExample = async (example_uuid: string) => {
        setIsNewExampleModalOpen(false);
        if (item === undefined) { return; }
        setItemExampleUuid(example_uuid);
    }

    const downloadEvalBundle = () => {
        if (item === undefined) {
            return;
        }
        // create bundle
        const bundle: IEvalBundle = {
            template: {
                template_name: item.template.name,
            },
            contexts: [],
            texts: [
                {
                    name: item.name,
                    text: flattenScrapeDocuments(item.documents)
                }
            ],
            prompts: [{ prompt_name: "scrape.default", prompts: null }],
            models: [{ model_name: "model.default", params: null }],
        };
        // add contexts
        for (const context of item.template.contexts) {
            bundle.contexts.push({
                context_name: `ORG: ${context.name}`,
                facts: context.facts,
                fields: context.fields,
                extract_params: context.extract_params,
                type: context.type
            });
            bundle.contexts.push({
                context_name: `FIX: ${context.name}`,
                facts: context.facts,
                fields: context.fields,
                extract_params: context.extract_params,
                type: context.type
            });
        }
        // save json
        const dataStr = JSON.stringify(bundle, null, 2);
        const dataUri = 'data:application/json;charset=utf-8,' + encodeURIComponent(dataStr);
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", dataUri);
        downloadAnchorNode.setAttribute("download", `item_eval_bundle_${item.uuid}.json`);
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    if (item === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const scrapes = [];
    for (let i = 0; i < item.scrapes.length; i++) {
        const scrape = item.scrapes[i];
        const context = item.template.contexts[i];
        scrapes.push({ scrape, context });
    }

    const is_item_hierarchical = item?.template.contexts.some(context => context.type === CONTEXT_TYPES.hierarchical);

    const is_custom_download_enabled = item?.endpoint?.details.handlebar_template !== undefined && item?.endpoint?.details.handlebar_template !== "";
    const download_buttons: IButtonMenuItem[] = [
        { title: "Eval", onClick: downloadEvalBundle, admin_only: true },
        { title: "Excel", href: `/api/item/excel?item_uuid=${item?.uuid}`, open_in_new_tab: true },
        { title: "PDF", href: `/api/item/pdf?item_uuid=${item?.uuid}`, open_in_new_tab: true },
        { title: "JSON", href: `/api/item/json?item_uuid=${item?.uuid}`, open_in_new_tab: true, hide: is_item_hierarchical },
        { title: "Custom", href: `/api/item/custom?item_uuid=${item?.uuid}`, open_in_new_tab: true, hide: !is_custom_download_enabled },
    ];

    const is_not_pending_or_rejected = item.extract_confirmations_status !== EXTRACT_CONFIRMATION_STATUS.pending &&
        item.extract_confirmations_status !== EXTRACT_CONFIRMATION_STATUS.rejected;

    const item_org = memberships.find(m => m.org.uuid === item.template.org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <TwoRowHeader
            title={item.name.length > 0 ? item.name : "[no subject]"}
            subtitle={<ItemBreadcrumb
                template_uuid={item.template_uuid}
                template_name={item.template.name}
                endpoint_uuid={item.endpoint?.uuid}
                endpoint_name={item.endpoint?.name}
            />}
            org_name={item_org?.org.name}
            buttons={<Fragment>
                {item_example_uuid === undefined && <Button icon={PlusCircleIcon} text="Example" onClick={startCreateExample} />}
                {item_example_uuid !== undefined && <Button icon={PencilIcon} text="Example" onClick={() => setIsEditExampleModalOpen(true)} />}
                <ButtonMenu title="Download" items={download_buttons} disabled={!item} />
                <Button icon={TrashIcon} onClick={() => setShowConfirm(true)} disabled={!allow_remove || !item} />
                <ExtractButton template_uuid={item.template_uuid} endpoint_uuid={item.endpoint?.uuid} />
            </Fragment>}
            subbuttons={<Fragment>
                <Link to={`/job/${item.details.extract_job_uuid}?item_uuid=${item.uuid}`} className="hover:underline cursor-pointer">Job Details</Link>
                <span className="pl-2">{prettySmartDateTime(item.created_at)}</span>
            </Fragment>}
            onBack={navigateBack}
        />

        {is_new_example_modal_open && <NewExampleModal
            open={is_new_example_modal_open}
            item_uuid={item.uuid}
            template_uuid={item.template_uuid}
            onUpdateExample={createExample}
            onClose={() => setIsNewExampleModalOpen(false)} />}
        {is_edit_example_modal_open && item_example_uuid !== undefined && <EditExampleModal
            open={is_edit_example_modal_open}
            example_item_uuid={item_example_uuid}
            template_uuid={item.template_uuid}
            onClose={() => setIsEditExampleModalOpen(false)} />}
        <ConfirmModal open={show_confirm}
            title="Remove extraction"
            message={["Are you sure you want to remove this extraction?"]}
            confirm="Remove"
            onClose={onRemoveClose} />

        <div className="pl-2 pr-2">
            <div className="px-4 max-w-5xl">
                {job_status === EXTRACT_JOB_STATUS.running && <div className="mt-4 p-4 border bg-yellow-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is still being processed. Please wait for the extraction to finish and refresh.</div>
                        <div className="flex-grow"></div>
                        {statusRefresh && <ArrowPathIcon className="h-5 w-5 cursor-pointer" onClick={statusRefresh} />}
                    </div>
                </div>}
                {job_status === EXTRACT_JOB_STATUS.error && <div className="mt-4 p-4 border bg-red-50 rounded text-sm text-gray-900">
                    <div className="flex flex-col gap-y-2">
                        <p>There was an error processing the item.</p>
                        <p>You can see the error message in the extractions log on the <Link to="/user" className="text-space_blue-500 cursor-pointer hover:underline">profile</Link> page.</p>
                        <p>Please try again or contact <SupportLink />.</p>
                    </div>
                </div>}
                {item.details.retry_new_item_uuids !== undefined && item.details.retry_new_item_uuids.length > 0 && <div className="mt-4 p-4 border bg-red-100 rounded text-sm text-gray-900">
                    {item.details.retry_new_item_uuids.length === 1 && <p>
                        There is a <Link to={`/item/${item.details.retry_new_item_uuids[0]}`} className="text-space_blue-600 underline">new version</Link> of this extraction.
                    </p>}
                    {item.details.retry_new_item_uuids.length > 1 && <p>
                        There are {item.details.retry_new_item_uuids.length} new versions of this extraction: {item.details.retry_new_item_uuids.map((uuid, idx) =>
                            <Fragment key={idx}>{idx > 0 && ", "}<Link to={`/item/${uuid}`} className="text-space_blue-600 underline">version {idx + 1}</Link></Fragment>)}
                    </p>}
                </div>}
                {item.extract_confirmations_status === EXTRACT_CONFIRMATION_STATUS.pending && <div className="mt-4 p-4 border bg-yellow-100 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is pending confirmation. Please validate and confirm the extractions.</div>
                        <div className="flex-grow"></div>
                        <Button text="Go to Confirmation" highlight={true} href={`/confirm/${item.uuid}`} />
                    </div>
                </div>}
                {item.extract_confirmations_status === EXTRACT_CONFIRMATION_STATUS.rejected && <div className="mt-4 p-4 border bg-red-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item was rejected during confirmation and was not sent forward for processing.</div>
                    </div>
                </div>}
                {is_not_pending_or_rejected && item.digest !== undefined && !item.digest.sent && <div className="mt-4 p-4 border bg-yellow-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item is waiting to be sent in a daily digest.</div>
                    </div>
                </div>}
                {is_not_pending_or_rejected && item.digest !== undefined && item.digest.sent && <div className="mt-4 p-4 border bg-mint-50 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2">
                        <div>Item was already sent in a daily digest.</div>
                    </div>
                </div>}
                {item.details.is_input_text_truncated && <div className="mt-4 p-4 border bg-gray-100 rounded text-sm text-gray-900">
                    <div className="flex flex-row items-center gap-x-2 text-sm">
                        <div><ExclamationTriangleIcon className="h-5 w-5 mr-2 text-red-400" /></div>
                        <div className="flex flex-col gap-y-2">
                            <p>Input text was truncated due to its length.</p>
                            {user.role === "free" && <p>Please <a href="https://meetings-eu1.hubspot.com/tomaz/book-a-demo" className="text-space_blue-600" target="_blank" rel="noreferrer">contact our team</a> for more information on how to process longer documents.</p>}
                            {user.role !== "free" && <p>You can process longer document by creating an <Link to="/endpoint/new" className="text-space_blue-600">Integration</Link> for this template</p>}
                        </div>
                    </div>
                </div>}
                {validation_summary && <div className="mt-4">
                    <ValidationSummary summary={validation_summary} hide_empty={true} />
                </div>}
            </div>

            <div className="py-4">
                <ViewItemTables item={item} />
            </div>
            <div className="px-4 py-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
            </div>
            <div className="px-4 pb-6">
                {selected_tab === "email" && <EmailContent item={item} />}
                {selected_tab === "doc" && <DocumentContent item={item} />}
                {item.attachments.map(a => a.uuid).includes(selected_tab) && <Attachment attachment={item.attachments.find(a => a.uuid === selected_tab)!} />}
                {selected_tab === "text" && <TextContent documents={item.documents} />}
                {selected_tab === "input_text" && <InputTextContent item={item} />}
            </div>
        </div>
        <ErrorMessageBar message={message} clearMessage={() => setMessage(undefined)} />
    </div>;
}