import { ReactNode } from "react";

import { classNames } from "../lib/utils";

type ProcessFlowHeadProps = {
    label: string;
    children?: ReactNode;
    active?: boolean;
}

export function ProcessFlowHead({ label, children, active }: ProcessFlowHeadProps) {
    return <div className="flex flex-row items-center text-sm font-semibold text-gray-900">
        <div className={classNames(
            "w-[4.5rem] flex-shrink-0 text-center py-2.5 rounded-xl border-2",
            active ? "bg-mint-300 border-mint-500" : "bg-mint-100 border-mint-200"
        )}>{label}</div>
        <div className="pl-6 border-2 border-white w-full text-sm font-semibold text-gray-900">{children}</div>
    </div>;
}

type ProcessFlowBodyProps = {
    show_scrollbar: boolean;
    children?: ReactNode;
}

export function ProcessFlowBody({ show_scrollbar, children }: ProcessFlowBodyProps) {
    return <div className="flex flex-row">
        <div className="relative w-[4.5rem] flex-shrink-0">
            <div className="absolute inset-y-0 left-1/2 transform -translate-x-1/2 w-0.5 bg-mint-200"></div>
        </div>
        <div className={classNames("pt-2 pb-6 pl-6 flex flex-col", show_scrollbar ? "max-w-full overflow-hidden" : "")}>
            {children}
        </div>
    </div>
}