import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import { useNavigate, } from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";

import {
    classNames,
    prettySmartDateTime
} from "../lib/utils";
import * as t from "../lib/types";
import { ORG_TYPES } from "../lib/consts";
import {
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import {
    Backend,
    BackendObj
} from "../lib/backend";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { LongText } from "../components/LongText";
import { OrgPill } from "../components/OrgPill";
import { DropdownMenu, IDropdownMenuItem } from "../components/DropdownMenu";
import { MultiselectInputField } from "../components/MultiselectInputField";
import { TextboxModal } from "../components/TextboxModal";

function EmptyList() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <Fragment>
        <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <div className="flex justify-center items-center h-screen w-full">
                <div className="text-center">
                    <hi.PuzzlePieceIcon className="mx-auto h-12 w-12 text-gray-400" />
                    <h3 className="mt-2 text-sm font-semibold text-gray-900">No integrations</h3>
                    <p className="mt-1 text-sm text-gray-500">Get started by setting up your first integration.</p>
                    <div className="mt-6">
                        <Button icon={hi.PlusIcon} text="Create Integration" href="/endpoint/new" />
                    </div>
                </div>
            </div>
        </div>
        <div className="lg:hidden flex justify-center pt-20 h-screen w-full">
            <div className="text-center">
                <hi.PuzzlePieceIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No integrations</h3>
                <p className="mt-1 text-sm text-gray-500">Get started by setting up your first integration.</p>
                <div className="mt-6">
                    <Button icon={hi.PlusIcon} text="Create Integration" href="/endpoint/new" />
                </div>
            </div>
        </div>
    </Fragment>;
}

type SortBy = "name_up" | "name_down" | "created_at_up" | "created_at_down" | "org_name_up" | "org_name_down";
type ItemType = "endpoint" | "lookup_table";
type IconType = "endpoint_email" | "endpoint_rest_api" | "lookup_table";

type IntegrationItem = {
    type: ItemType;
    icon: IconType;
    uuid: string;
    name: string;
    created_at: number;
    org_uuid: string;
}

interface IFilter {
    type: "name" | "org" | "type";
    value?: string;
    uuid?: string;
    icon?: IconType;
}

export function Endpoints() {
    const navigate = useNavigate();

    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const is_admin = user.role === "admin";
    const memberships = useSelector(selectMemberships);

    const [endpoints, setEndpoints] = useState<t.IEndpointBase[] | undefined>(undefined);
    const [lookup_tables, setLookupTables] = useState<t.ILookupTableBase[] | undefined>(undefined);
    const [items, setItems] = useState<IntegrationItem[] | undefined>(undefined);
    const [sort_by, _setSortBy] = useState<SortBy>(localStorage.getItem("sort_endpoints_by") as SortBy ?? "name");
    const [filters, _setFilters] = useState<IFilter[]>(localStorage.getItem("filters_endpoints") ? JSON.parse(localStorage.getItem("filters_endpoints") as string) : []);
    const [is_filter_dialog_open, setIsFilterDialogOpen] = useState(false);

    useEffect(() => {
        BackendObj.extractions.listEndpoints({}).then(({ endpoints }) => {
            setEndpoints(endpoints);
        });
        Backend.getLookupTables().then((lookup_tables) => {
            setLookupTables(lookup_tables);
        });
    }, []);

    useEffect(() => {
        const new_items: IntegrationItem[] = [];
        if (endpoints !== undefined) {
            new_items.push(...endpoints.map((endpoint) => ({
                type: "endpoint" as ItemType,
                icon: (endpoint.type === "email" ? "endpoint_email" : "endpoint_rest_api") as IconType,
                uuid: endpoint.uuid,
                name: endpoint.name,
                created_at: endpoint.created_at,
                org_uuid: endpoint.org_uuid
            })));
        }
        if (lookup_tables !== undefined) {
            new_items.push(...lookup_tables.map((lookup_table) => ({
                type: "lookup_table" as ItemType,
                icon: "lookup_table" as IconType,
                uuid: lookup_table.uuid,
                name: lookup_table.name,
                created_at: lookup_table.created_at,
                org_uuid: lookup_table.org_uuid
            })));
        }
        const orgs = memberships.map((membership) => membership.org);
        new_items.sort((a, b) => {
            if (sort_by === "name_up") {
                return a.name.localeCompare(b.name);
            } else if (sort_by === "name_down") {
                return b.name.localeCompare(a.name);
            } else if (sort_by === "created_at_up") {
                return a.created_at - b.created_at;
            } else if (sort_by === "created_at_down") {
                return b.created_at - a.created_at;
            } else if (sort_by === "org_name_up") {
                const org_a = orgs.find(org => org.uuid === a.org_uuid)
                const org_b = orgs.find(org => org.uuid === b.org_uuid);
                return org_a?.name.localeCompare(org_b?.name ?? "") ?? 0;
            } else if (sort_by === "org_name_down") {
                const org_a = orgs.find(org => org.uuid === a.org_uuid)
                const org_b = orgs.find(org => org.uuid === b.org_uuid);
                return org_b?.name.localeCompare(org_a?.name ?? "") ?? 0;
            }
            // default is by name
            return a.name.localeCompare(b.name);
        });
        setItems(new_items);
    }, [endpoints, lookup_tables, sort_by, memberships]);

    const setSortBy = (sort_by: SortBy) => {
        localStorage.setItem("sort_endpoints_by", sort_by);
        _setSortBy(sort_by);
    }

    const selectItem = async (type: "endpoint" | "lookup_table", uuid: string, event?: React.MouseEvent) => {
        const new_url = type === "endpoint" ? `/endpoint/${uuid}` : `/lookup_table/${uuid}`;
        if (event?.metaKey || event?.ctrlKey) {
            window.open(new_url, "_blank");
        } else {
            navigate(new_url);
        }
    };

    const setFilters = (new_filters: IFilter[]) => {
        _setFilters(new_filters);
        localStorage.setItem("filters_endpoints", JSON.stringify(new_filters));
    }

    const addFilter = (filter: IFilter) => {
        setFilters([...filters, filter]);
    }

    const removeFilter = (idx: number) => {
        setFilters(filters.filter((_, i) => i !== idx));
    }

    const onFilterDialogClose = (result: boolean, input_text?: string) => {
        if (result) {
            addFilter({ type: "name", value: input_text });
        }
        setIsFilterDialogOpen(false);
    }

    if (endpoints === undefined || lookup_tables === undefined || items === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:h-16 lg:flex lg:flex-row border-b-sea_blue-700 border-b", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (endpoints.length === 0 && lookup_tables.length === 0) {
        return <EmptyList />;
    }
    const org_map = new Map<string, t.IOrganization>();
    for (const org of memberships.map((membership) => membership.org)) {
        org_map.set(org.uuid, org);
    }

    const org_menu_items: IDropdownMenuItem[] = memberships.map(({ org }) => ({
        title: org.name,
        onClick: () => addFilter({ type: "org", value: org.name, uuid: org.uuid }),
    }));

    const type_menu_items: IDropdownMenuItem[] = [
        { title: "Email Endpoint", onClick: () => addFilter({ type: "type", value: "Email Endpoint", icon: "endpoint_email" }) },
        { title: "REST API Endpoint", onClick: () => addFilter({ type: "type", value: "REST API Endpoint", icon: "endpoint_rest_api" }) },
        { title: "Lookup Table", onClick: () => addFilter({ type: "type", value: "Lookup Table", icon: "lookup_table" }) },
    ];

    const filtered_items = items.filter(item => {
        for (const filter of filters) {
            if (filter.type === "name" && filter.value !== undefined) {
                if (!item.name.toLowerCase().includes(filter.value.toLowerCase())) {
                    return false;
                }
            }
            if (filter.type === "type" && filter.icon !== undefined) {
                if (item.icon !== filter.icon) {
                    return false;
                }
            }
            if (filter.type === "org" && filter.uuid !== undefined) {
                if (item.org_uuid !== filter.uuid) {
                    return false;
                }
            }
        }
        return true;
    });

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-16 w-full bg-white border-b border-b-sea_blue-700">
            <div className="px-10 py-4 flex flex-row items-center max-w-5xl">
                <h2 className="text-xl font-semibold leading-7 text-gray-600 sm:truncate sm:text-2xl sm:tracking-tight">
                    Integrations
                </h2>
                <div className="flex-grow" />
                <Button highlight={true} icon={hi.PlusIcon} text="New" href="/endpoint/new" />
            </div>
        </div>
        <div className="max-w-5xl">
            <div className="p-10 py-4">
            <div className="flex flex-row items-center pb-4 gap-x-4">
                    <div className="flex-grow pr-10">
                        <MultiselectInputField
                            values={filters.map(filter => filter.value ?? "")}
                            placeholder={is_admin ? "Filter by name, type, or organization" : "Filter by name or type"}
                            separator="space"
                            onAdd={() => setIsFilterDialogOpen(true)}
                            onClick={(idx) => removeFilter(idx)}
                            onRemove={(idx) => removeFilter(idx)} />
                    </div>
                    <DropdownMenu title="Types" items={type_menu_items} align="right" />
                    {is_admin && <DropdownMenu title="Organizations" items={org_menu_items} align="right" />}
                    <TextboxModal
                        open={is_filter_dialog_open}
                        title="Add filter by name"
                        init_text={""}
                        cancel="Cancel"
                        confirm="Add"
                        validate={(text) => text.length > 0}
                        onClose={onFilterDialogClose} />
                </div>
                <div className="overflow-hidden rounded-lg shadow ring-1 ring-black ring-opacity-5">
                    <table className="w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th></th>
                                <th className="text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "name_up" ? "name_down" : "name_up")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline  mr-1", sort_by === "name_up" || sort_by === "name_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Name
                                </th>
                                <th className="w-32 text-left px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "created_at_down" ? "created_at_up" : "created_at_down")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline mr-1", sort_by === "created_at_up" || sort_by === "created_at_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Created
                                </th>
                                <th className="w-40 text-right px-3 py-4 text-sm cursor-pointer font-semibold text-gray-900"
                                    onClick={() => setSortBy(sort_by === "org_name_up" ? "org_name_down" : "org_name_up")}>
                                    <hi.ArrowsUpDownIcon
                                        className={classNames("h-4 w-4 inline mr-1", sort_by === "org_name_up" || sort_by === "org_name_down" ? "text-gray-900" : "text-gray-400")}
                                    /> Organization
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                            {filtered_items.map((item) => (
                                <tr key={item.uuid}
                                    className="hover:bg-space_blue-100 cursor-pointer hover:rounded-md"
                                    onClick={(e) => selectItem(item.type, item.uuid, e)}>
                                    <td className="p-3 w-14 text-gray-400 text-center align-middle">
                                        {item.icon === "endpoint_email" && <hi.EnvelopeIcon className="h-5 w-5 inline" />}
                                        {item.icon === "endpoint_rest_api" && <hi.Cog8ToothIcon className="h-5 w-5 inline" />}
                                        {item.icon === "lookup_table" && <hi.TableCellsIcon className="h-5 w-5 inline" />}
                                    </td>
                                    <td className="p-3 text-sm text-gray-900">
                                        <LongText text={item.name} line_limit={1} />
                                    </td>
                                    <td className="p-3 text-sm text-gray-400">
                                        {prettySmartDateTime(item.created_at)}
                                    </td>
                                    <td className="p-3 flex justify-end">
                                        {org_map.has(item.org_uuid) && <OrgPill
                                            name={org_map.get(item.org_uuid)?.name ?? ""}
                                            type={org_map.get(item.org_uuid)?.type ?? ORG_TYPES.personal} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>;
}