export function SecurityDocs() {
    return <div className="grid grid-cols-2 gap-4 items-center justify-center">
        <div className="w-full flex flex-row">
            <div className="p-4">
                <img
                    className="border border-gray-400 shadow-xl w-12 min-w-[48px]"
                    src="/security/icon_data_processing_whitepaper.png"
                    alt="Data Processing whitepaper" />
            </div>
            <div className="flex-grow flex items-center text-sm font-semibold">
                <a href="/security/nordoon_data_processing_whitepaper.pdf" target="_blank">
                    Data Processing whitepaper
                </a>
            </div>
        </div>
        <div className="w-full flex flex-row">
            <div className="p-4">
                <img
                    className="border border-gray-400 shadow-xl w-12 min-w-[48px]"
                    src="/security/icon_model_training_and_customer_data.png"
                    alt="Data Processing whitepaper" />
            </div>
            <div className="flex-grow flex items-center text-sm font-semibold">
                <a href="/security/nordoon_model_training_and_customer_data.pdf" target="_blank">
                    Model Training and Customer Data
                </a>
            </div>
        </div>
        <div className="w-full flex flex-row">
            <div className="p-4">
                <img
                    className="border border-gray-400 shadow-xl w-12 min-w-[48px]"
                    src="/security/icon_nordoon_configure_entra_id.png"
                    alt="Nordoon Configure Entra ID" />
            </div>
            <div className="flex-grow flex items-center text-sm font-semibold">
                <a href="/security/nordoon_configure_entra_id.pdf" target="_blank">
                How  to  Configure  Microsoft Entra  ID  for  OpenID  Connect (OIDC)  Single  Sign-On  (SSO) with Nordoon
                </a>
            </div>
        </div>
    </div>;
}