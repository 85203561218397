import { Fragment } from "react";
import { TrashIcon, PlusIcon } from "@heroicons/react/24/outline";

import {
    IContextClassifier,
    IContextClassifierLLM,
    IContextClassifierRegex,
    IContextClassifierClassLLM,
    IContextClassifierClassRegex
} from "../lib/types";

import { Button } from "./Button";
import { Dropdown } from "./Dropdown";
import { Textbox } from "./Textbox";

type ClassifiersProps = {
    classifiers: IContextClassifier[];
    isDisabled: boolean;
    setClassifiers: (classifiers: IContextClassifier[]) => void;
}

export function Classifiers({ classifiers, isDisabled, setClassifiers }: ClassifiersProps) {
    const addClassifier = (type: "llm" | "regex" | "file_type") => {
        const new_classifiers = [...classifiers];
        if (type === "llm") {
            new_classifiers.push({
                type: "llm",
                name: "",
                classifier_type: "multiclass",
                focus: "content",
                unit: "page",
                classes: [],
                examples: [],
                guidelines: []
            });
        } else if (type === "regex") {
            new_classifiers.push({
                type: "regex",
                name: "",
                classifier_type: "multilabel",
                focus: "content",
                unit: "page",
                classes: []
            });
        } else if (type === "file_type") {
            // there can be only one
            if (classifiers.some(classifier => classifier.type === "file_type")) {
                return;
            }
            new_classifiers.push({
                type: "file_type",
                name: "File Type",
                classifier_type: "multilabel",
                focus: "title",
                unit: "document",
                classes: [
                    { name: "document" },
                    { name: "email_attachment" },
                    { name: "email_body" },
                    { name: "pdf" },
                    { name: "image" },
                    { name: "excel" },
                    { name: "simple" }
                ]
            });
        }
        setClassifiers(new_classifiers);
    };

    const updateClassifier = (idx: number, updates: Partial<IContextClassifier>) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[idx];
        if (classifier.type === "llm") {
            new_classifiers[idx] = { ...classifier, ...updates } as IContextClassifierLLM;
        } else if (classifier.type === "regex") {
            new_classifiers[idx] = { ...classifier, ...updates } as IContextClassifierRegex;
        } else if (classifier.type === "file_type") {
            // default classifier cannot be modified
            return;
        }
        setClassifiers(new_classifiers);
    };

    const deleteClassifier = (idx: number) => {
        const new_classifiers = [...classifiers];
        new_classifiers.splice(idx, 1);
        setClassifiers(new_classifiers);
    };

    const addClassifierClass = (classifier_idx: number) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx];

        if (classifier.type === "llm") {
            (classifier as IContextClassifierLLM).classes.push({
                name: "",
                guidelines: []
            });
        } else if (classifier.type === "regex") {
            (classifier as IContextClassifierRegex).classes.push({
                name: "",
                regex: ""
            });
        } else if (classifier.type === "file_type") {
            // default classifier cannot be modified
            return;
        }
        setClassifiers(new_classifiers);
    };

    const updateClassifierClass = (classifier_idx: number, class_idx: number, updates: Partial<IContextClassifierClassLLM | IContextClassifierClassRegex>) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx];
        if (classifier.type === "file_type") {
            // default classifier cannot be modified
            return;
        }
        classifier.classes[class_idx] = { ...classifier.classes[class_idx], ...updates };
        setClassifiers(new_classifiers);
    };

    const deleteClassifierClass = (classifier_idx: number, class_idx: number) => {
        const new_classifiers = [...classifiers];
        new_classifiers[classifier_idx].classes.splice(class_idx, 1);
        setClassifiers(new_classifiers);
    };

    const addArrayItem = (classifier_idx: number, field: "examples" | "guidelines", value: string = "") => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx] as IContextClassifierLLM;
        if (classifier[field]) {
            // If array is empty, just set the first item instead of pushing
            if (classifier[field].length === 0) {
                classifier[field] = [""];
            } else {
                classifier[field].push(value);
            }
        }
        setClassifiers(new_classifiers);
    };

    const updateArrayItem = (classifier_idx: number, field: "examples" | "guidelines", item_idx: number, value: string) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx] as IContextClassifierLLM;
        if (classifier[field]) {
            classifier[field][item_idx] = value;
        }
        setClassifiers(new_classifiers);
    };

    const deleteArrayItem = (classifier_idx: number, field: "examples" | "guidelines", item_idx: number) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx] as IContextClassifierLLM;
        if (classifier[field]) {
            classifier[field].splice(item_idx, 1);
        }
        setClassifiers(new_classifiers);
    };

    const addClassGuideline = (classifier_idx: number, class_idx: number, value: string = "") => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx] as IContextClassifierLLM;
        const guidelines = classifier.classes[class_idx].guidelines;

        // If array is empty, just set the first item instead of pushing
        if (guidelines.length === 0) {
            classifier.classes[class_idx].guidelines = [""];
        } else {
            classifier.classes[class_idx].guidelines.push(value);
        }
        setClassifiers(new_classifiers);
    };

    const updateClassGuideline = (classifier_idx: number, class_idx: number, guideline_idx: number, value: string) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx] as IContextClassifierLLM;
        classifier.classes[class_idx].guidelines[guideline_idx] = value;
        setClassifiers(new_classifiers);
    };

    const deleteClassGuideline = (classifier_idx: number, class_idx: number, guideline_idx: number) => {
        const new_classifiers = [...classifiers];
        const classifier = new_classifiers[classifier_idx] as IContextClassifierLLM;
        classifier.classes[class_idx].guidelines.splice(guideline_idx, 1);
        setClassifiers(new_classifiers);
    };

    return <div className="sm:grid sm:grid-cols-3 max-w-5xl sm:items-start sm:gap-4 sm:py-6 border-t border-gray-900/10">
        <div className="mt-2 sm:col-span-3 sm:mt-0 space-y-6">
            {classifiers.map((classifier, classifier_idx) => (
                <div key={classifier_idx} className="border rounded-md bg-gray-100 p-4 space-y-4">
                    <div className="flex justify-between items-center">
                        <h3 className="text-lg font-medium">
                            {classifier.type === "llm" ? "LLM Classifier" : (classifier.type === "regex" ? "Regex Classifier" : "File Type Classifier")}
                        </h3>
                        <Button
                            icon={TrashIcon}
                            disabled={isDisabled}
                            onClick={() => deleteClassifier(classifier_idx)}
                        />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-sm font-medium mb-1">Name</label>
                            <Textbox
                                value={classifier.name}
                                disabled={isDisabled || classifier.type === "file_type"}
                                onChange={(value) => updateClassifier(classifier_idx, { name: value })}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Classifier Type</label>
                            <Dropdown
                                values={["Multiclass", "Multilabel"]}
                                ids={["multiclass", "multilabel"]}
                                selected={classifier.classifier_type}
                                disabled={isDisabled || classifier.type === "regex" || classifier.type === "file_type"}
                                onChange={(value) => updateClassifier(classifier_idx, { classifier_type: value as "multiclass" | "multilabel" })}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Focus</label>
                            <Dropdown
                                values={["Title", "Content", "Title and Content"]}
                                ids={["title", "content", "title_and_content"]}
                                selected={classifier.focus}
                                disabled={isDisabled || classifier.type === "file_type"}
                                onChange={(value) => updateClassifier(classifier_idx, { focus: value as "title" | "content" | "title_and_content" })}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium mb-1">Unit</label>
                            <Dropdown
                                values={["Page", "Document", "Corpus"]}
                                ids={["page", "document", "corpus"]}
                                selected={classifier.unit}
                                disabled={isDisabled || classifier.type === "file_type"}
                                onChange={(value) => updateClassifier(classifier_idx, { unit: value as "page" | "document" | "corpus" })}
                            />
                        </div>
                    </div>

                    {classifier.type === "llm" && (
                        <Fragment>
                            <div className="space-y-2">
                                <label className="block text-sm font-medium">Examples</label>
                                {((classifier as IContextClassifierLLM).examples.length === 0
                                    ? [""]
                                    : (classifier as IContextClassifierLLM).examples
                                ).map((example, example_idx) => {
                                    const is_last = example_idx === (classifier as IContextClassifierLLM).examples.length - 1;
                                    return (
                                        <div key={example_idx} className="flex gap-2">
                                            <Textbox
                                                value={example}
                                                placeholder={example === "" ? "No examples" : ""}
                                                disabled={isDisabled}
                                                onChange={(value) => updateArrayItem(classifier_idx, "examples", example_idx, value)}
                                            />
                                            <Button
                                                icon={is_last ? PlusIcon : TrashIcon}
                                                disabled={isDisabled}
                                                onClick={() => is_last
                                                    ? addArrayItem(classifier_idx, "examples")
                                                    : deleteArrayItem(classifier_idx, "examples", example_idx)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="space-y-2">
                                <label className="block text-sm font-medium">Guidelines</label>
                                {((classifier as IContextClassifierLLM).guidelines.length === 0
                                    ? [""]
                                    : (classifier as IContextClassifierLLM).guidelines
                                ).map((guideline, guideline_idx) => {
                                    const is_last = guideline_idx === (classifier as IContextClassifierLLM).guidelines.length - 1;
                                    return (
                                        <div key={guideline_idx} className="flex gap-2">
                                            <Textbox
                                                value={guideline}
                                                placeholder={guideline === "" ? "No guidelines" : ""}
                                                disabled={isDisabled}
                                                onChange={(value) => updateArrayItem(classifier_idx, "guidelines", guideline_idx, value)}
                                            />
                                            <Button
                                                icon={is_last ? PlusIcon : TrashIcon}
                                                disabled={isDisabled}
                                                onClick={() => is_last
                                                    ? addArrayItem(classifier_idx, "guidelines")
                                                    : deleteArrayItem(classifier_idx, "guidelines", guideline_idx)
                                                }
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    )}

                    <label className="block text-sm font-medium">{classifier.classifier_type === "multiclass" ? "Classes" : "Labels"}</label>

                    <div className="space-y-2">
                        {classifier.classes.map((class_item, class_idx) => (
                            <div key={class_idx} className="border rounded bg-gray-50 p-3 space-y-3">
                                <div className="flex gap-2 items-center">
                                    <h4 className="text-sm font-medium whitespace-nowrap">{classifier.classifier_type === "multiclass" ? "Class" : "Label"} {class_idx + 1}</h4>
                                    <Textbox
                                        placeholder="Class name"
                                        value={class_item.name}
                                        disabled={isDisabled || classifier.type === "file_type"}
                                        onChange={(value) => updateClassifierClass(classifier_idx, class_idx, { name: value })}
                                    />
                                    <Button
                                        icon={TrashIcon}
                                        disabled={isDisabled || classifier.type === "file_type"}
                                        onClick={() => deleteClassifierClass(classifier_idx, class_idx)}
                                    />
                                </div>

                                {(classifier.type === "regex" || classifier.type === "llm") && <div className="space-y-2">
                                    {classifier.type === "regex" ? (
                                        <Textbox
                                            placeholder="Regex pattern"
                                            value={(class_item as IContextClassifierClassRegex).regex}
                                            disabled={isDisabled}
                                            onChange={(value) => updateClassifierClass(classifier_idx, class_idx, { regex: value })}
                                        />
                                    ) : (
                                        <div className="space-y-2">
                                            <label className="block text-sm font-medium">Guidelines</label>
                                            {((class_item as IContextClassifierClassLLM).guidelines.length === 0
                                                ? [""]
                                                : (class_item as IContextClassifierClassLLM).guidelines
                                            ).map((guideline, guideline_idx) => {
                                                const is_last = guideline_idx === (class_item as IContextClassifierClassLLM).guidelines.length - 1;
                                                return (
                                                    <div key={guideline_idx} className="flex gap-2">
                                                        <Textbox
                                                            value={guideline}
                                                            placeholder={guideline === "" ? "No guidelines" : ""}
                                                            disabled={isDisabled}
                                                            onChange={(value) => updateClassGuideline(classifier_idx, class_idx, guideline_idx, value)}
                                                        />
                                                        <Button
                                                            icon={is_last ? PlusIcon : TrashIcon}
                                                            disabled={isDisabled}
                                                            onClick={() => is_last
                                                                ? addClassGuideline(classifier_idx, class_idx)
                                                                : deleteClassGuideline(classifier_idx, class_idx, guideline_idx)
                                                            }
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>}
                            </div>
                        ))}
                        <div className="flex justify-end items-center">
                            <Button
                                text={classifier.classifier_type === "multiclass" ? "Add Class" : "Add Label"}
                                icon={PlusIcon}
                                disabled={isDisabled || classifier.type === "file_type"}
                                onClick={() => addClassifierClass(classifier_idx)}
                            />
                        </div>
                    </div>
                </div>
            ))}
            <div className="flex flex-row gap-2">
                <Button
                    text="Add LLM Classifier"
                    icon={PlusIcon}
                    disabled={isDisabled}
                    onClick={() => addClassifier("llm")}
                />
                <Button
                    text="Add Regex Classifier"
                    icon={PlusIcon}
                    disabled={isDisabled}
                    onClick={() => addClassifier("regex")}
                />
                <Button
                    text="Add File Type Classifier"
                    icon={PlusIcon}
                    disabled={isDisabled || classifiers.some(classifier => classifier.type === "file_type")}
                    onClick={() => addClassifier("file_type")}
                />
            </div>
        </div>
    </div>;
}