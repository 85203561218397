import {
    useState,
    useEffect,
    useRef
} from "react";
import { useSelector } from "react-redux";

import {
    Link,
    useNavigate,
    useParams
} from "react-router-dom";
import * as hi from "@heroicons/react/24/outline";
import { TbTable } from "react-icons/tb";

import * as t from "../lib/types";
import { Backend, BackendObj } from "../lib/backend";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectUser
} from "../lib/scraper.slice";
import { UnitOfProcessing } from "../lib/backend/extractions.types.generated";
import {
    classNames,
    removeFileNameExtension,
    sleep
} from "../lib/utils";
import {
    EXTRACT_CONFIRMATION_STATUS,
    EXTRACT_JOB_EVENT_STATUS,
    MAX_PAGE_SHEET_UNITS,
    USER_ROLES
} from "../lib/consts";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { Button } from "../components/Button";
import { ViewScrapeTable } from "../components/ItemTables";
import { IRichDropdownValue, RichDropdown } from "../components/RichDropdown";
import { DragDropArea } from "../components/DragDropArea";
import { Dropdown } from "../components/Dropdown";
import { Checkbox } from "../components/Checkbox";
import { DidYouKnow } from "../components/DidYouKnow";
import { ErrorMessageBar } from "../components/ErrorMessageBar";
import { Pill } from "../components/Pill";
import { ProcessFlowHead } from "../components/ProcessFlow";
import { LockClosedIcon } from "@heroicons/react/20/solid";

function NoTemplates() {
    const is_sidebar_large = useSelector(selectIsSidebarLarge);

    return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="flex justify-center items-center h-screen w-full">
            <div className="text-center">
                <hi.FolderIcon className="mx-auto h-12 w-12 text-gray-400" />
                <h3 className="mt-2 text-sm font-semibold text-gray-900">No templates</h3>
                <p className="mt-1 text-sm text-gray-500">Before you can extract data, you need to create a template.</p>
                <div className="mt-6">
                    <Button icon={hi.FolderPlusIcon} text="Create Template" href="/template/new" />
                </div>
            </div>
        </div>
    </div >;
}

function ConfirmButton(props: { confirmation: t.IExtractConfirmation | null }) {
    const confirmation = props.confirmation;
    if (confirmation === null) {
        return <Pill text="Confirmed" type="success" />;
        // return <span className="text-sm text-mint-600">Confirmed</span>;
    } else if (confirmation.status === EXTRACT_CONFIRMATION_STATUS.pending) {
        return <Button text="Go to Confirmation" highlight={true} href={`/confirm/${confirmation.item_uuid}`} />;
    }
    return null;
}

const EXAMPLE = {
    scrape_name: "Contact information",
    input_text: ["Blaz Č. Fortuna", "Co-founder", "nordoon Inc., 4 Palo Alto Square, Palo Alto, CA 94306", "P: +1 (650) 283-1854"].join("\n")
};

export function NewExtraction() {
    const navigate = useNavigate();

    const env = useSelector(selectEnv);
    const user = useSelector(selectUser);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const memberships = useSelector(selectMemberships);
    const is_admin = user.role === USER_ROLES.admin;

    const all_orgs = memberships.map((membership) => membership.org);

    const { selected_uuid, item_uuid } = useParams<{ selected_uuid: string | undefined, item_uuid: string | undefined }>();

    const [dialog_state, setDialogState] = useState<"input_text" | "check">("input_text");
    const [templates, setTemplates] = useState<t.ITemplateBase[] | undefined>(undefined);
    const [endpoints, setEndpoints] = useState<t.IEndpointBase[] | undefined>(undefined);
    const [scrape_name, setScrapeName] = useState<string>("");
    const [input_text, setInputText] = useState<string | undefined>(undefined);
    const [input_files, setInputFiles] = useState<File[] | undefined>(undefined);
    const [input_files_pages, setInputFilesPages] = useState<number[] | undefined>(undefined);
    const [input_files_selected_pages, setInputFilesSelectedPages] = useState<number[][] | undefined>(undefined);
    const [input_files_page_delimiters, setInputFilesPageDelimiters] = useState<number[][] | undefined>(undefined);
    const [store_extractions, setStoreExtractions] = useState<boolean>(true);
    const [unit_of_processing, setUnitOfProcessing] = useState<UnitOfProcessing>("file");
    const [is_loading_item, setIsLoadingItem] = useState<boolean>(false);
    const [is_processing, setIsProcessing] = useState<boolean>(false);
    const [scrape_check, setScrapeCheck] = useState<t.IScrapeCheck | undefined>(undefined);
    const [error_message, setErrorMessage] = useState<string | undefined>(undefined);
    const [do_redirect, setDoRedirect] = useState<boolean>(false);
    const [select_pages_mode, setSelectPagesMode] = useState<t.SelectPagesMode>("all");

    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        BackendObj.extractions.listTemplates({})
            .then(({ templates }) => {
                setTemplates(templates);
            });
        if (user.role !== USER_ROLES.free) {
            BackendObj.extractions.listEndpoints({})
                .then(({ endpoints }) => { setEndpoints(endpoints); })
                .catch(() => { setEndpoints([]); });
        } else {
            setEndpoints([]);
        }
    }, [selected_uuid, user.role, navigate]);

    useEffect(() => {
        const init = async () => {
            if (item_uuid === undefined) { return; }
            setIsLoadingItem(true);
            const { item } = await BackendObj.extractions.getItem({ item_uuid });
            if (item === undefined) { return; }
            // preload extraction input with item data
            setScrapeName(item.name);
            // get attachments and add them to the input
            setInputText(undefined);
            const item_files: File[] = [];
            for (const attachment of item.attachments) {
                const item_file = await Backend.getAttachment({
                    uuid: attachment.uuid,
                    filename: attachment.filename,
                    mimetype: attachment.mimetype
                });
                item_files.push(item_file);
            }
            setInputFiles(item_files);
            setIsLoadingItem(false);
        };
        init().catch((err) => {
            setErrorMessage("Error loading previous extraction input.");
            setIsLoadingItem(false);
        });
    }, [item_uuid]);

    useEffect(() => {
        if (do_redirect && scrape_check && scrape_check.items && scrape_check.items.length > 0) {
            try {
                // first item
                const item_uuid = scrape_check.items[0].uuid;
                if (scrape_check.endpoint?.details.require_confirmation === "always") {
                    // item requires confirmation, navigate to the confirmation page
                    navigate(`/confirm/${item_uuid}`);
                } else if (scrape_check.endpoint?.details.require_confirmation === "on_error" && scrape_check.confirmations !== undefined) {
                    // potentially some items require confirmation
                    const pending_confirmation = scrape_check.confirmations
                        .find(c => c !== null && c.status === EXTRACT_CONFIRMATION_STATUS.pending);
                    if (pending_confirmation !== undefined && pending_confirmation !== null) {
                        navigate(`/confirm/${pending_confirmation.item_uuid}`);
                    } else {
                        navigate(`/item/${item_uuid}`);
                    }
                } else {
                    navigate(`/item/${item_uuid}`);
                }
            } catch (err: any) {
                setErrorMessage("Error navigating to the extracted item.");
            }
        }
    }, [do_redirect, scrape_check, navigate]);

    useEffect(() => {
        if (selected_uuid !== undefined) {
            // check if template or endpoint
            const template = templates?.find((template) => template.uuid === selected_uuid);
            if (template !== undefined) {
                // template, we just set defaults
                setStoreExtractions(true);
                setUnitOfProcessing("file");
            } else {
                // integration, we set defaults from endpoint
                const endpoint = endpoints?.find((endpoint) => endpoint.uuid === selected_uuid);
                if (endpoint !== undefined) {
                    setStoreExtractions(endpoint.details.store_extractions);
                    // in case we have an email integration, we count "file" same as "all"
                    setUnitOfProcessing(endpoint.details.unit_of_processing);
                }
            }
        }
    }, [selected_uuid, templates, endpoints]);

    const setSelectedUuid = (uuid: string) => {
        navigate(`/extraction/new/${uuid}`);
    }

    const updateText = (text: string) => {
        setInputText(text);
        setInputFiles(undefined);
        setErrorMessage(undefined);
    }

    const addFiles = (files: File[], skip_set_subject: boolean) => {
        setInputFiles((old_input_files) => {
            if (old_input_files === undefined) {
                return [...files];
            } else {
                return [...old_input_files, ...files];
            }
        });
        // we can change the subject if we have no subject set yet or if the subject is the same as the first file name (i.e. it was set automatically)
        const can_change_subject = !skip_set_subject && scrape_name === removeFileNameExtension(input_files?.[0].name ?? "");
        if (can_change_subject) {
            const no_of_files = files.length + (input_files?.length ?? 0);
            if (no_of_files === 1) {
                setScrapeName(removeFileNameExtension(files[0].name));
            } else {
                // multiple files, we clear the subject so will default to just the individual file name
                setScrapeName("");
            }
        }
        setInputText(undefined);
        setErrorMessage(undefined);
    }

    const setFilePages = (idx: number, pages: number) => {
        setInputFilesPages((old_input_files_pages) => {
            if (old_input_files_pages === undefined) {
                return [pages];
            } else {
                if (old_input_files_pages.length === idx) {
                    return [...old_input_files_pages, pages];
                } else {
                    const new_input_files_pages = [...old_input_files_pages];
                    new_input_files_pages[idx] = pages;
                    return new_input_files_pages;
                }
            }
        });
    }

    const setFileSelectedPages = (idx: number, selected_pages: number[], page_delimiters: number[]) => {
        setInputFilesSelectedPages((old_input_files_selected_pages) => {
            if (old_input_files_selected_pages === undefined) {
                return [selected_pages];
            } else {
                if (old_input_files_selected_pages.length === idx) {
                    return [...old_input_files_selected_pages, selected_pages];
                } else {
                    const new_input_files_selected_pages = [...old_input_files_selected_pages];
                    new_input_files_selected_pages[idx] = selected_pages;
                    return new_input_files_selected_pages;
                }
            }
        });
        setInputFilesPageDelimiters((old_input_files_page_delimiters) => {
            if (old_input_files_page_delimiters === undefined) {
                return [page_delimiters];
            } else {
                if (old_input_files_page_delimiters.length === idx) {
                    return [...old_input_files_page_delimiters, page_delimiters];
                } else {
                    const new_input_files_page_delimiters = [...old_input_files_page_delimiters];
                    new_input_files_page_delimiters[idx] = page_delimiters;
                    return new_input_files_page_delimiters;
                }
            }
        });
    }

    const clearInput = () => {
        setInputText(undefined);
        setInputFiles(undefined);
        setErrorMessage(undefined);
    }

    const setSubject = (subject: string) => {
        setScrapeName(subject);
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            if (e.target.files) {
                addFiles([...e.target.files], false);
            }
        } catch (err) {
            console.error("Error processing changed file", err);
        }
    };

    const handleUploadButtonClick = () => {
        fileInputRef.current?.click();
    };

    const clearFile = (idx: number) => {
        if (input_files !== undefined) {
            const new_files = [...input_files];
            new_files.splice(idx, 1);
            if (new_files.length === 0) {
                setInputFiles(undefined);
            } else {
                setInputFiles(new_files);
            }
        }
        if (input_files_pages !== undefined) {
            const new_pages = [...input_files_pages];
            new_pages.splice(idx, 1);
            if (new_pages.length === 0) {
                setInputFilesPages(undefined);
            } else {
                setInputFilesPages(new_pages);
            }
        }
        if (input_files_selected_pages !== undefined) {
            const new_selected_pages = [...input_files_selected_pages];
            new_selected_pages.splice(idx, 1);
            if (new_selected_pages.length === 0) {
                setInputFilesSelectedPages(undefined);
            } else {
                setInputFilesSelectedPages(new_selected_pages);
            }
        }
        if (input_files_page_delimiters !== undefined) {
            const new_page_delimiters = [...input_files_page_delimiters];
            new_page_delimiters.splice(idx, 1);
            if (new_page_delimiters.length === 0) {
                setInputFilesPageDelimiters(undefined);
            } else {
                setInputFilesPageDelimiters(new_page_delimiters);
            }
        }
    }

    const onCheck = async () => {
        setErrorMessage(undefined);
        setIsProcessing(true);
        // make sure we have a valid uuid
        const template_uuid = templates?.find((template) => template.uuid === selected_uuid)?.uuid;
        const endpoint_uuid = endpoints?.find((endpoint) => endpoint.uuid === selected_uuid)?.uuid;
        if (template_uuid === undefined && endpoint_uuid === undefined) {
            setErrorMessage("Please select a template or integration.");
            setIsProcessing(false);
            return;
        }
        // try to extract
        try {
            const job_uuid = await Backend.extractScrapeStart({
                template_uuid,
                endpoint_uuid,
                scrape_name,
                input_text,
                input_files,
                input_files_selected_pages,
                input_files_page_delimiters,
                input_item_uuid: item_uuid,
                store_extractions,
                unit_of_processing
            });
            const MAX_RETRY_COUNT = 25;
            let retry_count = 0;
            while (retry_count < MAX_RETRY_COUNT) {
                try {
                    const result = await Backend.extractScrapeGet({ job_uuid });
                    if (result.status === "done") {
                        // check if we have any extractions at all
                        const has_items = result.items !== undefined && result.items.length > 0;
                        const has_scrapes = has_items && (result.items?.some((item) => item.scrapes.length > 0) ?? false);
                        if (has_items && has_scrapes) {
                            setScrapeCheck(result);
                            setDialogState("check");
                            setIsProcessing(false);
                            if (store_extractions) {
                                setDoRedirect(true);
                            }
                            break;
                        } else {
                            // check if job has any errors
                            const job = await Backend.getExtractJob({ job_uuid });
                            if (job.events.some((event) => event.status === EXTRACT_JOB_EVENT_STATUS.error)) {
                                // we had errors, show the error message
                                const event_error_message = job.events.find((event) => event.status === EXTRACT_JOB_EVENT_STATUS.error)?.message;
                                setErrorMessage(`${event_error_message}. Please try again.`);
                            } else {
                                // no errors, but no extractions either
                                setErrorMessage("No extractions found. Please try again.");
                            }
                            setDialogState("input_text");
                            setIsProcessing(false);
                            break;
                        }
                    } else if (result.status === "error") {
                        // in case of error, go back one step
                        setErrorMessage(result.message);
                        setDialogState("input_text");
                        setIsProcessing(false);
                        break;
                    }
                } catch (err) {
                    retry_count++;
                    if (retry_count >= MAX_RETRY_COUNT) { throw err; }
                }
                await sleep(500);
            }
        } catch (err: any) {
            setErrorMessage("Error checking extraction. Please try again.");
            setDialogState("input_text");
            setIsProcessing(false);
        }
    }

    const onStartAnother = () => {
        setInputText(undefined);
        setInputFiles(undefined);
        setDialogState("input_text");
    };

    // when we are checking there if we have templates, show spinner
    // if undefined we are still loading
    if (templates === undefined || endpoints === undefined || is_loading_item) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    if (templates.length === 0) {
        return <NoTemplates />;
    }

    const is_input_text_invalid = (input_text === undefined || input_text === "") && (input_files === undefined);
    const is_more_orgs = all_orgs.length > 1;
    const is_both_templates_and_endpoints = templates.length > 0 && endpoints.length > 0;

    // get list of all templates that have endpoints
    const template_with_endpoints_set: Set<string> = new Set();
    for (const endpoint of endpoints) {
        template_with_endpoints_set.add(endpoint.template_uuid);
    }
    const extract_options: IRichDropdownValue[] = [];
    // first collect templates that do not have endpoints
    for (const template of templates) {
        if (!template_with_endpoints_set.has(template.uuid)) {
            extract_options.push({
                name: template.name,
                id: template.uuid,
                icon: TbTable,
                org: is_more_orgs ? all_orgs.find((org) => org.uuid === template.org_uuid) : undefined
            });
        }
    }
    // then add endpoints
    for (const endpoint of endpoints) {
        extract_options.push({
            name: endpoint.name,
            id: endpoint.uuid,
            icon: hi.PuzzlePieceIcon,
            org: is_more_orgs ? all_orgs.find((org) => org.uuid === endpoint.org_uuid) : undefined
        });
    }
    // sort by name
    extract_options.sort((a, b) => a.name.localeCompare(b.name));
    // finally, add templates without endpoints as admin only
    if (is_admin) {
        for (const template of templates) {
            if (template_with_endpoints_set.has(template.uuid)) {
                extract_options.push({
                    name: template.name,
                    id: template.uuid,
                    icon: LockClosedIcon,
                    org: is_more_orgs ? all_orgs.find((org) => org.uuid === template.org_uuid) : undefined,
                });
            }
        }
    }

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        {!is_processing && <div className="h-16 border-b-sea_blue-700 border-b">
            <div className="px-10 py-4 lg:max-w-4xl">
                <div className="flex flex-row items-center">
                    <h2 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {item_uuid === undefined ? "New Job" : "Retry Past Job"}
                    </h2>
                    <div className="flex-grow" />
                </div>
            </div>
        </div>}

        {dialog_state === "input_text" && !is_processing && <div className="px-10 pb-20">
            <div className="my-3">
                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4 max-w-4xl">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        {is_both_templates_and_endpoints ? "Template or Integration" : "Template"}
                    </label>
                    <div className="sm:col-span-3 w-full">
                        <RichDropdown
                            values={extract_options}
                            selected={selected_uuid || ""}
                            onChange={(uuid) => setSelectedUuid(uuid)}
                            auto_select_first={true}
                        />
                    </div>
                </div>

                <div className="sm:grid sm:grid-cols-4 sm:items-start sm:gap-4 sm:py-4 max-w-4xl">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                        Subject
                    </label>
                    <div className="mt-2 sm:col-span-3 sm:mt-0">
                        <div className="flex w-full rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-space_blue-600">
                            <input
                                id="title"
                                name="title"
                                type="text"
                                className="block flex-1 border-0 bg-transparent p-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                value={scrape_name}
                                onChange={(e) => setScrapeName(e.target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="sm:items-start sm:gap-4 sm:py-3 text-gray-600 text-sm">
                    <div className="py-1 grid items-center">
                        {input_files === undefined && <DragDropArea
                            name="body"
                            rows={15}
                            text={input_text}
                            allow_multiple={true}
                            show_section_split={unit_of_processing === "section"}
                            select_pages_mode={select_pages_mode}
                            setText={updateText}
                            setFiles={addFiles}
                            setFilePages={(pages: number) => setFilePages(0, pages)}
                            setFileSelectedPages={(selected_pages: number[], page_delimiters: number[]) => setFileSelectedPages(0, selected_pages, page_delimiters)}
                            setSubject={setSubject}
                            setErrorMessage={setErrorMessage}
                            setClear={clearInput}
                        />}
                        {input_files !== undefined && input_files.map((file, idx) => (
                            <DragDropArea
                                key={idx}
                                name="body"
                                rows={15}
                                file={file}
                                allow_multiple={true}
                                show_section_split={unit_of_processing === "section"}
                                select_pages_mode={select_pages_mode}
                                setFiles={addFiles}
                                setFilePages={(pages: number) => setFilePages(idx, pages)}
                                setFileSelectedPages={(selected_pages: number[], page_delimiters: number[]) => setFileSelectedPages(idx, selected_pages, page_delimiters)}
                                setSubject={setSubject}
                                setErrorMessage={setErrorMessage}
                                setClear={() => clearFile(idx)}
                            />
                        ))}
                    </div>
                </div>

            </div>

            <ErrorMessageBar message={error_message} clearMessage={() => setErrorMessage(undefined)} />

            <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                multiple={true}
                onChange={handleFileChange}
            />

            <div className="sm:grid xl:grid-cols-4 sm:items-start gap-4 sm:gap-8 max-w-4xl">
                <div className="xl:hidden flex flex-row items-center justify-end sm:mt-0 w-full">
                    {input_text !== undefined && <Button text="Clear text" onClick={clearInput} />}
                    {input_files !== undefined && <Button text="Upload file" onClick={handleUploadButtonClick} />}
                    {input_files !== undefined && input_files.length === 1 && <Button text="Remove file" onClick={clearInput} />}
                    {input_files !== undefined && input_files.length > 1 && <Button text="Remove files" onClick={clearInput} />}
                    <Button
                        text="Start"
                        highlight={true}
                        disabled={is_input_text_invalid}
                        onClick={onCheck} />
                </div>

                <div className="xl:col-span-3">
                    <div className="sm:flex sm:flex-row items-center sm:items-start sm:gap-4 sm:pb-4 sm:px-6 pt-4 sm:pt-0">
                        {<label htmlFor="unit_of_processing" className="sm:col-span-2 block font-medium text-sm text-gray-900 sm:pt-1.5">
                            How to process the file:
                        </label>}
                        {input_files && <div className="sm:col-span-2 mt-2 sm:mt-0">
                            <Dropdown
                                values={[
                                    "All files/pages/sheets together",
                                    "Each file separately",
                                    "Each section of pages separately",
                                    "Each page/sheet separately"
                                ]}
                                ids={["all", "file", "section", "page"]}
                                selected={unit_of_processing}
                                onChange={(unit) => setUnitOfProcessing(unit as UnitOfProcessing)} />
                        </div>}
                    </div>
                    {input_files && <div className="sm:flex sm:flex-row items-center sm:items-start sm:gap-4 sm:pb-4 sm:px-6 pt-4 sm:pt-0 text-sm">
                        <div className="font-medium text-gray-900">Select pages:</div>
                        <div className="cursor-pointer text-space_blue-600" onClick={() => setSelectPagesMode("all")}> all</div>
                        <div className="cursor-pointer text-space_blue-600" onClick={() => setSelectPagesMode("first_page")}>first page</div>
                        <div className="cursor-pointer text-space_blue-600" onClick={() => setSelectPagesMode("last_page")}>last page</div>
                    </div>}
                    <div className="flex flex-row items-start gap-4 pb-4 px-6 pt-4 sm:pt-0">
                        <div className="mt-0">
                            <Checkbox checked={store_extractions} setChecked={setStoreExtractions} id="endpoint_store_extractions" />
                        </div>
                        <div className="flex flex-col">
                            <label htmlFor="endpoint_store_extractions" className="block font-medium text-sm text-gray-900 pt-0.5">
                                Store Extractions
                                {store_extractions && <p className="mt-2 text-sm leading-6 font-normal text-gray-600">
                                    Results will be stored in our database and visible under the <Link to="/items" className="text-space_blue-600">Results</Link> page.
                                </p>}
                                {!store_extractions && <p className="mt-2 text-sm leading-6 font-normal text-gray-600">
                                    Results will not be stored in our database. They will only be available on this this page right after extraction.
                                </p>}
                            </label>
                        </div>
                    </div>
                    <div className="sm:flex sm:flex-row items-center sm:items-start sm:gap-4 sm:pb-4 sm:px-6">
                        {input_files_pages && Math.max(...input_files_pages) > MAX_PAGE_SHEET_UNITS && unit_of_processing === "page" &&
                            <label className="sm:col-span-2 block text-sm text-red-600 sm:pt-1.5">
                                Warning: maximal number of pages/sheets that can be processed separately is {MAX_PAGE_SHEET_UNITS}.<br />
                                Documents with more pages/sheets and will be truncated to {MAX_PAGE_SHEET_UNITS} pages/sheets before processing.
                            </label>}
                    </div>
                </div>
                <div className="hidden xl:flex flex-row items-center justify-end sm:mt-0 w-full">
                    {input_text !== undefined && <Button text="Clear text" onClick={clearInput} />}
                    {input_files !== undefined && <Button text="Upload file" onClick={handleUploadButtonClick} />}
                    {input_files !== undefined && input_files.length === 1 && <Button text="Remove file" onClick={clearInput} />}
                    {input_files !== undefined && input_files.length > 1 && <Button text="Remove files" onClick={clearInput} />}
                    <Button
                        text="Start"
                        highlight={true}
                        disabled={is_input_text_invalid}
                        onClick={onCheck} />
                </div>
            </div>
            {/* In DEV mode, we provide easy way to populate input data with contact information example */}
            {env === "dev" && <div className="pt-16 flex flex-row items-center justify-end sm:mt-0 w-full max-w-4xl">
                <Button
                    text="DEV"
                    onClick={() => {
                        setScrapeName(EXAMPLE.scrape_name);
                        setInputText(EXAMPLE.input_text);
                        setInputFiles(undefined);
                        setInputFilesPages(undefined);
                    }} />
            </div>}
        </div>}

        {dialog_state === "input_text" && is_processing && <div className="flex flex-col min-h-screen max-w-5xl">
            <div className="flex-grow px-10 py-16 mx-auto">
                <div className="flex flex-col items-center justify-center py-8">
                    <h1 className="text-4xl font-bold mb-4 text-gray-600">Processing<span className="dots" /></h1>
                </div>
                <div className="flex flex-col items-center justify-center w-full py-8">
                    <div className="max-w-lg relative">
                        <DidYouKnow change_seconds={10} />
                        <div className="absolute inset-0 m-auto pb-20" style={{ width: "50px", height: "50px" }}>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center py-8 text-gray-400 text-sm">
                {store_extractions && <p className="max-w-xl py-2 text-center">
                    You can navigate away from this page or start another extraction.
                </p>}
                {store_extractions && <p className="max-w-xl py-2 text-center">
                    Result will be available under the <Link to="/items/" className="text-space_blue-600">Results</Link> page.
                </p>}

                {!store_extractions && <p className="max-w-xl py-2 text-center">
                    Do not navigate away from this page until the extraction is done.
                </p>}
            </div>
        </div>}

        {dialog_state === "check" && <div className="py-3">
            {scrape_check?.items && scrape_check?.items.length === 1 && <div className="my-3">
                <div className="flex flex-row items-center w-full pb-4 px-10 max-w-5xl">
                    <h3 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
                        {scrape_check.items[0].name}
                    </h3>
                    <div className="flex-grow" />
                    {scrape_check !== undefined && scrape_check.confirmations !== undefined && <ConfirmButton confirmation={scrape_check.confirmations[0]} />}
                </div>
                <div className="px-10">
                    {scrape_check.items[0].scrapes.map((scrape, idx) => (<div key={idx}>
                        {scrape_check?.template && <ViewScrapeTable
                            context_idx={idx}
                            context={scrape_check.template.contexts.find((context) => context.uuid === scrape.context_uuid)}
                            scrape={scrape} input_documents={[]} />}
                    </div>))}
                    <ProcessFlowHead label="Done" />
                </div>
            </div>}

            {scrape_check?.items && scrape_check?.items.length > 1 && <div className="mb-3 flex flex-col divide-y border-b">
                {scrape_check.items.map((item, idx) => (<div key={idx} className="py-10">
                    <div className="flex flex-row items-center w-full pb-4 px-10 max-w-5xl">
                        <h3 className="text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
                            {item.name}
                        </h3>
                        <div className="flex-grow" />
                        {scrape_check !== undefined && scrape_check.confirmations !== undefined && <ConfirmButton confirmation={scrape_check.confirmations[idx]} />}
                    </div>
                    {item.scrapes.map((scrape, idx) => (<div key={idx} className="px-10">
                        {scrape_check?.template && <ViewScrapeTable
                            context_idx={idx}
                            context={scrape_check.template.contexts.find((context) => context.uuid === scrape.context_uuid)}
                            scrape={scrape} input_documents={[]} />}
                    </div>))}
                    <ProcessFlowHead label="Done" />
                </div>))}
            </div>}

            <div className="pt-3 px-10 max-w-5xl grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6 mt-6">
                <div className="mb-20 flex items-center justify-start gap-x-1 text-xs text-gray-400">
                    {scrape_check?.extraction_time_ts && <span>
                        Extraction time: {Math.round((scrape_check?.extraction_time_ts || 0) / 100) / 10}s
                    </span>}
                </div>
                <div className="mb-20 flex items-center justify-end gap-x-1 ">
                    {dialog_state === "check" && <Button text="Do Another Extraction"
                        onClick={onStartAnother} />}
                </div>
            </div>
        </div>}
    </div>
}
