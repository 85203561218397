import { useState } from "react";

import * as hi from "@heroicons/react/24/outline";

import {
    IItemValidationSummary,
    IItemValidationAnalyticsTag,
    IValidationResultEx
} from "../lib/backend/extractions.types.generated";
import { classNames } from "../lib/utils";

const STATUS_ICONS = {
    pass: hi.CheckIcon,
    warn: hi.XMarkIcon,
    fail: hi.XMarkIcon,
    reject: hi.XMarkIcon
};

interface IValidationResultProps {
    result: IValidationResultEx;
}

function ValidationResult({ result }: IValidationResultProps) {
    const context_name = result.context_name.length > 0 ? result.context_name : `Step ${result.context_idx + 1}`;

    const result_title = (result.type === "context") ?
        `${context_name}` :
        (result.type === "record") ?
            `${context_name} [ row ${(result.record_idx ?? 0) + 1} ]` :
            `${context_name} [ row ${(result.record_idx ?? 0) + 1} ] ➜ ${result.field_name}`;

    return (
        <div className="pl-8 pr-4 py-2 text-xs flex flex-row items-center gap-x-2">
            <div className={classNames("font-mono",
                result.status === "fail" ? "text-red-600" :
                    result.status === "reject" ? "text-rose-600" :
                        result.status === "warn" ? "text-orange-600" :
                            "text-mint-600")}>
                {result_title}
            </div>
            <span>{result.name}</span>
            {result.reason && <span>- {result.reason}</span>}
            {result.status === "fail" && <span className="text-gray-600"> [Must pass before proceeding]</span>}
            {result.status === "reject" && <span className="text-gray-600"> [Qualifies for rejection]</span>}
        </div>
    );
}

interface IAnalyticsTagProps {
    tag: IItemValidationAnalyticsTag;
    last_tag: boolean;
}

function AnalyticsTag({ tag, last_tag }: IAnalyticsTagProps) {
    const STATUS_ICON = STATUS_ICONS[tag.tag_status];

    const [is_expanded, setIsExpanded] = useState(false);

    return (
        <div className="">
            <div
                className={classNames(
                    "flex items-center py-2 pl-6 pr-3 cursor-pointer",
                    tag.tag_status === "pass" ? "bg-mint-50 text-mint-600" :
                        tag.tag_status === "warn" ? "bg-orange-50 text-orange-600" :
                            tag.tag_status === "fail" ? "bg-red-50 text-red-600" :
                                tag.tag_status === "reject" ? "bg-rose-50 text-rose-600" : "",
                    (last_tag && !is_expanded) ? "rounded-b" : ""
                )}
                onClick={() => setIsExpanded(!is_expanded)}
            >
                <STATUS_ICON className="h-5 w-5 mr-2" />
                <span className="text-sm">{tag.analytics_tag}</span>
                <div className="flex-grow" />
                <hi.ChevronDownIcon
                    className={`h-5 w-5 transform transition-transform ${is_expanded ? "rotate-180" : ""}`}
                />
            </div>
            {is_expanded && (
                <div className="mt-1 pl-3">
                    {tag.results.map((result, idx) => (
                        <ValidationResult key={idx} result={result} />
                    ))}
                </div>
            )}
        </div>
    );
}

interface IValidationSummaryProps {
    summary: IItemValidationSummary;
    hide_empty?: boolean;
}

export function ValidationSummary({ summary, hide_empty }: IValidationSummaryProps) {
    const STATUS_ICON = STATUS_ICONS[summary.final_status];

    const [is_expanded, setIsExpanded] = useState(false);

    const did_check = summary.analytics_tags.length > 0;

    const list_of_no_pass_tags = summary.analytics_tags.filter(tag => tag.tag_status !== "pass").map(tag => tag.analytics_tag);

    return did_check ?
        <div className="py-0">
            <div className="flex flex-col items-center w-full rounded border">
                <div
                    className={classNames(
                        "w-full flex items-center p-3 rounded-t border-b cursor-pointer",
                        summary.final_status === "pass" ? "bg-mint-50 text-mint-600" :
                            summary.final_status === "warn" ? "bg-orange-50 text-orange-600" :
                                summary.final_status === "fail" ? "bg-red-50 text-red-600" :
                                    summary.final_status === "reject" ? "bg-rose-50 text-rose-600" : "",
                    )}
                    onClick={() => setIsExpanded(!is_expanded)}
                >
                    <STATUS_ICON className="h-5 w-5 mr-2" />
                    <span className="text-sm">
                        {summary.final_status === "pass" ? "Everything OK" : `Failed Validations: ${list_of_no_pass_tags.join(", ")}`}
                    </span>
                    <div className="flex-grow" />
                    <hi.ChevronDownIcon
                        className={`h-5 w-5 transform transition-transform ${is_expanded ? "rotate-180" : ""}`}
                    />
                </div>
                {is_expanded && <div className="w-full">
                    {summary.analytics_tags.map((tag, idx) => (
                        <AnalyticsTag key={idx} tag={tag} last_tag={idx === summary.analytics_tags.length - 1} />
                    ))}
                </div>}
            </div>

        </div > :
        hide_empty ?
            null :
            <div className="px-2 text-gray-500 text-xs">
                No validation checks were performed
            </div>;
}