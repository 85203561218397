import {
    Fragment,
    useEffect,
    useState
} from "react";

import { SIMPLE_MIME_TYPES } from "../lib/consts";
import {
    IItemAttachmentBase,
    IItemBase
} from "../lib/types";
import { flattenScrapeDocuments } from "../lib/utils";

import { ExcelViewer } from "./ExcelViewer";
import { LongText } from "./LongText";
import { PdfViewerVertical } from "./PdfViewer";
import { CompactTabs, ITab } from "./Tabs";
import { IContextNoUUID, IPreprocessParams } from "../lib/backend/extractions.types.generated";
import { Dropdown } from "./Dropdown";
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectUser } from "../lib/scraper.slice";

function EmailContent(props: { item: IItemBase }) {
    const { item } = props;
    const is_html = item.details.email_html !== undefined;
    return <div className="text-sm p-5 mt-5 border border-gray-200 rounded shadow bg-gray-50">
        <div className="pb-2"><span className="font-bold">From:</span> {item.details.from_email_address}</div>
        {item.details.to_email_addresses && <div className="pb-2 truncate"><span className="font-bold">To:</span> {item.details.to_email_addresses?.join(", ")}</div>}
        {item.details.email_subject && <div className="pb-2"><span className="font-bold">Subject:</span> {item.details.email_subject}</div>}
        {/* render html from item.details.email_html in iframe */}
        {is_html && <iframe
            title="email"
            srcDoc={item.details.email_html ?? ""}
            style={{ width: "100%", height: "400px" }}
            className="border bg-white mt-2"
            sandbox=""
        />}
        {/* render text from item.text */}
        {!is_html && <LongText text={flattenScrapeDocuments(item.documents)} />}
    </div>
}

function AttachmentContent(props: { attachment: IItemAttachmentBase }) {
    const { attachment } = props;

    const url = `/api/attachment/get?uuid=${attachment.uuid}`;
    const link = <a
        className="text-xs flex truncate text-space_blue-600 cursor-pointer hover:underline"
        href={url} target="_blank" rel="noreferrer">
        [download]
    </a>

    const lc_filename = attachment.filename.toLowerCase();
    if (
        attachment.mimetype.startsWith("image/") ||
        lc_filename.endsWith(".jpg") ||
        lc_filename.endsWith(".jpeg") ||
        lc_filename.endsWith(".png") ||
        lc_filename.endsWith(".tiff") ||
        lc_filename.endsWith(".bmp") ||
        lc_filename.endsWith(".gif")
    ) {
        return <div className="pt-3">
            <img src={url} alt={attachment.filename} />
            {link}
        </div>;
    }

    if (attachment.mimetype === "application/pdf" || lc_filename.endsWith(".pdf")) {
        return <div className="pt-3">
            <PdfViewerVertical file={url} />
            {link}
        </div>;
    }

    if (lc_filename.endsWith(".xlsx") || lc_filename.endsWith(".xls") || lc_filename.endsWith(".ods")) {
        return <div className="pt-3">
            <ExcelViewer attachment_uuid={attachment.uuid} />
            {link}
        </div>;
    }

    if (SIMPLE_MIME_TYPES.includes(attachment.mimetype)) {
        return <div className="pt-3">
            <div className="text-sm mb-2 px-2 py-4 border border-gray-200 rounded shadow bg-gray-50">
                <LongText text={flattenScrapeDocuments([attachment.document])} />
            </div>
            {link}
        </div>;
    }

    return <div className="p-5">{link}</div>
}

function DocumentContent(props: { item: IItemBase }) {
    const { item } = props;

    return <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
        {item.details.document_subject && <div className="pb-2 mb-2 border-b"><span className="font-bold">Subject:</span> {item.details.document_subject}</div>}
        <LongText text={flattenScrapeDocuments(item.documents)} />
    </div>;
}

function InputTextContent(props: { item: IItemBase, contexts: (IContextNoUUID & { uuid: string })[] }) {
    const { item, contexts } = props;

    return <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
        <ViewItemScrapeDocuments item={item} contexts={contexts} />
    </div>;
}

export function ViewItemScrapeDocuments(props: { item: IItemBase, contexts: (IContextNoUUID & { uuid: string })[] }) {
    const { item, contexts } = props;

    const [renderings, setRenderings] = useState<{ name: string, context_uuid: string, preprocess_params?: IPreprocessParams }[]>([]);
    const [selected_context_uuid, setSelectedContextUuid] = useState<string>("");

    useEffect(() => {
        const getContextName = (context_uuid: string) => {
            return contexts.find((context) => context.uuid === context_uuid)?.name || "Unknown";
        }

        const renderings: { name: string, context_uuid: string, preprocess_params?: IPreprocessParams }[] = [];
        // check if any document/page has additional renderings
        for (const document of item.documents) {
            for (const page of document.pages) {
                // first page we see collect default preprocess params
                if (renderings.length === 0) {
                    renderings.push({ name: "Default", context_uuid: "", preprocess_params: page.preprocess_params });
                }
                // then we collect all additional renderings
                if (page.additional_renderings && page.additional_renderings.length > 0) {
                    for (const rendering of page.additional_renderings) {
                        // make sure we do not have this context already
                        if (renderings.find((r) => r.context_uuid === rendering.context_uuid) === undefined) {
                            renderings.push({ name: getContextName(rendering.context_uuid), context_uuid: rendering.context_uuid, preprocess_params: rendering.preprocess_params });
                        }
                    }
                }
            }
        }

        setRenderings(renderings);
        setSelectedContextUuid("");
    }, [item, contexts]);

    const onCopy = () => {
        navigator.clipboard.writeText(flattenScrapeDocuments(item.documents));
    }

    const render_docs = item.documents.map((document) => ({
        name: document.name,
        pages: document.pages.map((page) => ({
            name: page.name,
            classification: page.classification,
            preprocess_params: page.additional_renderings?.find((r) => r.context_uuid === selected_context_uuid)?.preprocess_params ?? page.preprocess_params,
            text: page.additional_renderings?.find((r) => r.context_uuid === selected_context_uuid)?.text ?? page.text
        }))
    }));

    // we have email, render it as such
    return <Fragment>
        <div className="px-2 flex flex-row items-center gap-x-2">
            <div className="text-sm text-gray-400">Rendering:</div>
            <div className="max-w-sm w-full">
                <Dropdown
                    values={renderings.map((r) => r.name)}
                    ids={renderings.map((r) => r.context_uuid)}
                    selected={selected_context_uuid}
                    onChange={(context_uuid) => setSelectedContextUuid(context_uuid)}
                />
            </div>
            <div className="flex-grow" />
            <button
                type="button"
                className="mr-4 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-space_blue-500 focus:ring-offset-2"
                onClick={onCopy}
            >
                <span className="sr-only">Copy to clipboard</span>
                <ClipboardDocumentIcon className="h-6 w-6" aria-hidden="true" />
            </button>
        </div>

        {render_docs.map((document, i) => (
            <div key={i}>
                <h3 className="text-sm p-2 text-gray-600">Document {i + 1}: {document.name}</h3>
                {document.pages.map((page, j) => (
                    <div key={j}>
                        <h4 className="text-xs p-2 text-gray-600 flex flex-row gap-2 items-center">
                            <span className="font-semibold">{page.name}</span>
                            {page.preprocess_params !== undefined && <Fragment>
                                <span>(</span>
                                <div className="flex flex-row gap-1">
                                    {Object.keys(page.preprocess_params).map((key, idx) => <Fragment key={key}>
                                        <span className="font-mono text-xs text-gray-400">{(page.preprocess_params as any)[key]}</span>
                                        {idx < Object.keys(page.preprocess_params ?? {}).length - 1 && <span className="font-mono text-xs text-gray-400">|</span>}
                                    </Fragment>)}
                                </div>
                                <span>)</span>
                            </Fragment>}
                            {page.classification && page.classification.length > 0 && <Fragment>
                                <span>-</span>
                                {page.classification.map((classifier_results, idx) => <Fragment key={idx}>
                                    {idx > 0 && <span>-</span>}
                                    <span className="text-gray-500">
                                        Classifier {idx + 1}: {classifier_results.length > 0 ? classifier_results.join(", ") : "∅"}
                                    </span>
                                </Fragment>)}
                            </Fragment>}
                        </h4>
                        <div className="font-mono text-xs border bg-white">
                            <LongText text={page.text} />
                        </div>
                    </div>
                ))}
            </div>
        ))}
    </Fragment>;
}

type ItemContentProps = {
    item: IItemBase;
    attachments: IItemAttachmentBase[];
    contexts: (IContextNoUUID & { uuid: string })[];
}

export function ItemContent(props: ItemContentProps) {
    const { item, attachments, contexts } = props;

    const user = useSelector(selectUser);
    const is_admin = user.role === "admin";

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selected_tab, setSelectedTab] = useState<string>("");

    useEffect(() => {
        if (item === undefined) { return; }
        const tabs: ITab[] = [];
        if (item.details.input_type === "email") {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.input_type === "documents") {
            if (attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.from_email_address !== undefined) {
            tabs.push({ name: "Email", key: "email" });
        } else if (item.details.document_subject !== undefined) {
            if (attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else if (item.details.document_filename !== undefined) {
            if (attachments.length === 0) { tabs.push({ name: "Document", key: "doc" }); }
        } else {
            tabs.push({ name: "Text", key: "text" });
        }
        for (const attachment of attachments) {
            tabs.push({ name: attachment.filename, key: attachment.uuid });
        }
        if (is_admin) {
            tabs.push({ name: "Input Text", key: "input_text" });
        }
        setTabs(tabs);
        const first_content_tab = tabs.find(tab => tab.name.endsWith(".pdf")) || tabs[0];
        if (first_content_tab !== undefined) {
            setSelectedTab(first_content_tab.key);
        }
    }, [item, attachments, is_admin]);

    return <Fragment>
        <CompactTabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={setSelectedTab} />
        {selected_tab === "email" && <EmailContent item={item} />}
        {selected_tab === "doc" && <DocumentContent item={item} />}
        {selected_tab === "text" && <div className="text-sm p-5 mt-5 border-gray-200 bg-gray-50 rounded shadow">
            <LongText text={flattenScrapeDocuments(item.documents)} />
        </div>}
        {attachments.map((attachment, idx) => {
            if (selected_tab === attachment.uuid) {
                return <AttachmentContent key={idx} attachment={attachment} />;
            }
            return null;
        })}
        {selected_tab === "input_text" && <InputTextContent item={item} contexts={contexts} />}
    </Fragment >;
}