import {
    Fragment,
    useEffect,
    useState
} from "react";
import { useSelector } from "react-redux";

import {
    useParams,
    useNavigate,
    Link,
    useLocation
} from "react-router-dom";

import * as hi from "@heroicons/react/24/outline";
import * as hs from "@heroicons/react/20/solid";
import {
    BrowserIcon,
    FileCodeIcon,
    MarkGithubIcon
} from "@primer/octicons-react";

import {
    classNames,
    getLocalHour,
    isValidIpAddress,
    prettyDateTime,
    prettySmartDateTime,
    setDocumentTitle
} from "../lib/utils";
import * as t from "../lib/types";
import {
    ENDPOINT_TYPE,
    ORG_TYPES,
    USER_ROLES
} from "../lib/consts";
import {
    selectEnv,
    selectIsSidebarLarge,
    selectMemberships,
    selectSelfUrl,
    selectUser
} from "../lib/scraper.slice";
import {
    Backend,
    BackendObj
} from "../lib/backend";
import {
    ExtractConfirmationStatus,
    IEndpointForwardEmailItem
} from "../lib/backend/extractions.types.generated";

import { LoadingSpinner } from "../components/LoadingSpinner";
import { Button, ButtonGroup } from "../components/Button";
import { ConfirmModal } from "../components/ConfirmModal";
import { CopyTextbox } from "../components/CopyTextbox";
import { OrgPill } from "../components/OrgPill";
import { ITab, Tabs } from "../components/Tabs";
import { SidePanel } from "../components/SidePanel";
import AuditLogHistory from "./AuditLogHistory";
import { ExtractJobs } from "../components/ExtractJobs";
import { CodeBox } from "../components/CodeBox";
import { EndpointToLookupTable } from "../components/EndpointToLookupTable";
import { ConfirmationPill } from "../components/ConfirmationPill";


type EndpointExtractJobsProps = {
    endpoint: t.IEndpoint;
    initial_page: number;
}

function EndpointExtractJobs(props: EndpointExtractJobsProps) {
    const { endpoint, initial_page } = props;
    const navigate = useNavigate();
    const location = useLocation();

    const handlePageChange = (page: number) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("page", page.toString());
        navigate({ search: new_params.toString() });
    };

    return <div className="px-4 py-6">
        <ExtractJobs
            type="endpoint"
            endpoint_uuid={endpoint.uuid}
            org_uuid={endpoint.org_uuid}
            initial_page={initial_page}
            onPageChange={handlePageChange}
        />
    </div>;
}

type EndpointDocsProps = {
    endpoint: t.IEndpoint;
}

function EndpointDocs(props: EndpointDocsProps) {
    const { endpoint } = props;

    const self_url = useSelector(selectSelfUrl);
    const user = useSelector(selectUser);
    const membership = useSelector(selectMemberships);

    const is_personal = membership.some((m) => m.org.uuid === endpoint.org_uuid && m.org.type === ORG_TYPES.personal);

    return <Fragment>
        <h3 className="px-4 pt-4 leading-7 text-lg font-medium">API Keys</h3>
        <div className="text-sm text-gray-500 flex-grow py-4">
            {!is_personal && <span>You can manage API keys in the <Link to={`/org/${endpoint.org_uuid}`} className="text-space_blue-600 underline">organization settings</Link>.</span>}
            {is_personal && <span>You can manage API keys in the <Link to={`/user/${user.uuid}`} className="text-space_blue-600 underline">user settings</Link>.</span>}
        </div>
        <h3 className="px-4 pt-4 leading-7 text-lg font-medium">REST API</h3>
        {endpoint.details.rest_api_version === "v1" && <Fragment>
            <div className="mx-4 px-4 mt-3 pt-3 pb-3 sm:px-0 text-sm bg-space_blue-50 rounded-lg">
                <div className="m-4 mb-4">
                    Asynchronous endpoint for extracting data from text using templates.
                    POST request creates a job and returns a job token. GET request retrieves job status and results.
                    If integration has a webhook, results are also sent to the webhook URL.
                </div>
                <div className="m-4 mb-4">
                    <Button
                        icon={hi.ArrowTopRightOnSquareIcon}
                        href="https://gist.github.com/blazf/979940346e66f65ac048734f9e507ac4"
                        text="OpenAPI"
                        open_in_new_tab={true} />
                    <Button
                        icon={hi.ArrowTopRightOnSquareIcon}
                        href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                        text="Node.JS example"
                        open_in_new_tab={true} />
                </div>
            </div>
            <div className="px-4 py-6 sm:px-0 text-sm">
                <div className="mx-4 mb-2 font-medium">Create job - POST request</div>
                <div className="m-4 mb-2">
                    Header Parameters:
                    <ul className="list-disc list-inside ml-4">
                        <li><code>Content-Type: application/json</code> - payload in body must be a JSON</li>
                    </ul>
                </div>
                <div className="m-4 mb-2">
                    Body must be a valid JSON with the following keys:
                    <ul className="list-disc list-inside ml-4">
                        <li><code>name: string</code> - name of the job</li>
                        <li><code>input_text: string</code> - <i>[optional]</i> raw text to process</li>
                        <li><code>input_file: object</code> - <i>[optional]</i> raw text to process</li>
                        <li><code>passthrough_data</code> - <i>[optional]</i> any additional data to pass through to the webhook call</li>
                    </ul>
                </div>
                <div className="m-4 mb-2">
                    Keys <code>input_text</code> and <code>input_file</code> are mutually exclusive. Exactly one must be present.
                </div>
                <div className="m-4 mb-2">
                    Input file is an object with the following keys:
                    <ul className="list-disc list-inside ml-4">
                        <li><code>filename: string</code> - name of the file</li>
                        <li><code>mimetype: string</code> - MIME type of the file, if unknown use <code>octet/stream</code></li>
                        <li><code>base64: string</code> - base64 encoded file content</li>
                    </ul>
                </div>
                <div className="m-4 mb-2">
                    Result is a JSON with the following key:
                    <ul className="list-disc list-inside ml-4">
                        <li><code>job_uuid</code> - job token</li>
                    </ul>
                </div>
                <div className="mx-4 mt-8 mb-2 font-medium">Example for <code>input_text</code></div>
                <div className="m-4 mb-2">Input:</div>
                <CodeBox language={"JSON"} code={`{\n    "name": "Job Text",\n    "input_text": "Content"\n}`} />
                <div className="m-4 mb-2">Call using curl:</div>
                <CodeBox language={"curl"} code={`curl -X POST "${endpoint.url}" \\\n    -H "Content-Type: application/json" \\\n    -H "x-api-key: API_KEY" \\\n    -d "{\\"name\\": \\"Job 01\\", \\"input_text\\": \\"Content\\"}"`} />
                <div className="m-4 mb-2">Result:</div>
                <CodeBox language={"JSON"} code={`{\n    "job_uuid": "aaa111bbb222ccc333ddd444"\n}`} />

                <div className="mx-4 mt-8 mb-2 font-medium">Example for <code>input_file</code></div>
                <div className="m-4 mb-2">Input:</div>
                <CodeBox language={"JSON"} code={`{\n    "name": "Job File",\n    "input_file": {\n        filename: "example.pdf",\n        mimetype: "application/pdf",\n        base64: "JVBERi0xLjQKJcfsj6IKNS..."\n    },\n    passthrough_data: {\n        some_key: "some_value"\n    }\n}`} />
                <div className="m-4 mb-2">Result:</div>
                <CodeBox language={"JSON"} code={`{\n    "job_uuid": "aaa111bbb222ccc333ddd444"\n}`} />

            </div>
            <div className="px-4 py-6 sm:px-0 text-sm">
                <div className="mx-4 mb-2 font-medium">Retrieve job - GET request</div>
                <div className="m-4 mb-2">
                    URL Parameters:
                    <ul className="list-disc list-inside ml-4">
                        <li><code>job_uuid</code> - job token</li>
                    </ul>
                </div>
                <div className="m-4 mb-2">
                    Result:
                    <ul className="list-disc list-inside ml-4">
                        <li><code>status</code> - current job status</li>
                        <li><code>result</code> - job results, present when job is done</li>
                    </ul>
                </div>
                <div className="mx-4 mt-8 mb-2 font-medium">Example </div>
                <div className="m-4 mb-2">Call using curl:</div>
                <CodeBox language={"curl"} code={`curl "${endpoint.url}?job_uuid=aaa111bbb222ccc333ddd444 \\\n    -H "x-api-key: API_KEY"`} />
                <div className="m-4 mb-2">Result:</div>
                <CodeBox language={"JSON"} code={`{
  PO: [
    {
      sender: "jane.demaggio@tier2.com",
      SKU: "100356000",
      quantity: "10500",
      delivery_date: "10-08-2023",
      status: "confirmed"
    },
    {
      sender: "jane.demaggio@tier2.com",
      SKU: "100356000",
      quantity: "20000",
      delivery_date: "2023-09-30",
      status: "pending"
    }
  ]
}`} />
            </div>
        </Fragment>}
        {endpoint.details.rest_api_version === "v2" &&
            <div className="my-4 flex flex-row gap-2 items-center">
                <Button
                    icon={FileCodeIcon}
                    text="OpenAPI"
                    href={`${self_url}/public/open-api.json`}
                    open_in_new_tab={true} />
                <Button
                    icon={BrowserIcon}
                    text="Swagger"
                    href={`${self_url}/public/swagger`}
                    open_in_new_tab={true} />
                <Button
                    icon={MarkGithubIcon}
                    href="https://gist.github.com/blazf/3bb9abf9bab1d968b06413c36005337e"
                    text="Node.JS example"
                    open_in_new_tab={true} />
            </div>}
    </Fragment>
}

function WebhookDocs() {
    return <div className="px-4 pt-3 pb-3 sm:px-0 text-sm ">
        <h3 className="px-4 pt-4 leading-7 text-lg font-medium">Webhook</h3>
        <div className="m-4 mb-4">
            Webhook is called when a job is done. It sends the results in the body of the POST request.
        </div>
        <div className="m-4 mb-4">
            If API key is set, it is sent in the header as <code>X-API-KEY</code>.
        </div>

        <div className="px-4 py-6 sm:px-0 text-sm">
            <div className="mx-4 mb-2 font-medium">Webhook payload</div>
            <div className="m-4 mb-2">
                Payload contains the following keys:
                <ul className="list-disc list-inside ml-4">
                    <li><code>id</code> - unique identifier for each payload</li>
                    <li><code>job_uuid</code> - uuid of the job that triggered the webhook</li>
                    <li><code>created</code> - timestamp when the payload was created</li>
                    <li><code>api_version</code> - version of the API that triggered the webhook</li>
                    <li><code>type</code> - type of the payload, always <code>new_extraction</code></li>
                    <li><code>data</code> - results of the job</li>
                </ul>
            </div>
            <div className="m-4 mb-2">
                Data field contains the results of the job. It is a JSON object with the following keys:
                <ul className="list-disc list-inside ml-4">
                    <li><code>value</code> - JSON object representing extracted data</li>
                    <li><code>input</code> - <i>[optional]</i> input data that was processed</li>
                    <li><code>passthrough_data</code> - <i>[optional]</i> data passed through from the REST API call that started the job</li>
                </ul>
            </div>
            <div className="mx-4 mt-8 mb-2 font-medium">Example payload</div>
            <CodeBox language={"JSON"} code={`{
    "id": "0b69ad32fb82425293d198da643efed2",
    "job_uuid": "74b866b393fe41c3a09a9cd23595eb94",
    "created": 1713089109354,
    "api_version": "v2",
    "type": "new_extraction",
    "data": {
        "value": {
            "Contact Address": [
                {
                    "First Name": "John",
                    "Last Name": "Smith",
                    "Address": "Street 123, City, Country",
                }
            ]
        },
        "input": {
            "from": "",
            "subject": "example.xlsx",
            "body": "...",
            "attachments": [
                {
                    "filename": "example.xlsx",
                    "mimetype": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "content": "UEsDBBQABgAIA..."
                }
            ]
        },
        "passthrough_data": {
            "some_key": "some_value"
        }
    }
}`} />
        </div>

        <div className="px-4 py-6 sm:px-0 text-sm">
            <div className="mx-4 mb-2 font-medium">Webhook call IP address</div>
            <div className="m-4 mb-2">
                Requests come from one of the following IP addresses:
            </div>
            <div className="m-4 mb-2">
                <Button
                    icon={hi.ArrowTopRightOnSquareIcon}
                    href="https://gist.github.com/blazf/8b4d7e0969120cbdd28960ef47d08572"
                    text="Webhook IP whitelist"
                    open_in_new_tab={true} />
            </div>
        </div>
    </div>;
}

type IpWhiteListProps = {
    endpoint: t.IEndpoint;
    updateWhitelist: (ip_whitelist: string[]) => Promise<void>;
}

function IpWhiteList(props: IpWhiteListProps) {
    const { endpoint, updateWhitelist } = props;

    const [ip_whitelist, setIpWhitelist] = useState<string>(endpoint.ip_whitelist.join("\n"));
    const [is_edited, setIsEdited] = useState<boolean>(false);
    const [is_valid, setIsValid] = useState<boolean>(false);
    const [validation_message, setValidationMessage] = useState<string | undefined>(undefined);
    const [is_saving, setIsSaving] = useState<boolean>(false);

    const setIpWhitelistAndMarkEdited = (value: string) => {
        setIpWhitelist(value);
        setIsEdited(true);
        setIsValid(false);
        setValidationMessage(undefined);
    };

    const getIpWhitelist = () => {
        return ip_whitelist
            .split("\n")
            .map((ip_address) => ip_address.trim())
            .filter((ip_address) => ip_address.length > 0);
    }

    const updateEndpoint = async () => {
        setIsSaving(true);
        await updateWhitelist(getIpWhitelist());
        setIsEdited(false);
        setIsSaving(false);
    }

    const validate = () => {
        // empty whitelist is valid
        if (ip_whitelist.length === 0) {
            setIsValid(true);
            setValidationMessage(undefined);
            return;
        }
        // validate each IP address
        const ip_addresses = getIpWhitelist();
        for (const ip_address of ip_addresses) {
            if (ip_address.includes("-")) {
                const ip_range = ip_address.split("-");
                if (ip_range.length !== 2) {
                    setValidationMessage(`Invalid IP address range: '${ip_address}'`);
                    setIsValid(false);
                    return;
                }
                if (!isValidIpAddress(ip_range[0])) {
                    setValidationMessage(`Invalid IP address: '${ip_range[0]}'`);
                    setIsValid(false);
                    return;
                }
                if (!isValidIpAddress(ip_range[1])) {
                    setValidationMessage(`Invalid IP address: '${ip_range[1]}'`);
                    setIsValid(false);
                    return;
                }
            } else if (!isValidIpAddress(ip_address)) {
                setValidationMessage(`Invalid IP address: '${ip_address}'`);
                setIsValid(false);
                return;
            }
        }
        setIsValid(true);
        setValidationMessage(undefined);
    }

    const can_save = is_edited && is_valid;

    return <div className="px-4 pt-3 pb-3 sm:px-0 text-sm ">
        <div className="m-4 mb-4">
            List of IP addresses that can call the Rest API endpoint.
        </div>
        <div className="m-4 mb-4">
            Please enter one IP address per line. If empty, all IP addresses are allowed.
        </div>
        <div className="m-4 mb-4">
            For IP ranges use the following format: <code>192.168.1.30-192.168.1.40</code>.
        </div>
        <div className="m-4 mb-4">
            <textarea
                className={classNames("w-full h-32 p-2 rounded-lg text-sm font-mono", ip_whitelist.length === 0 ? "bg-gray-50" : "bg-white")}
                value={ip_whitelist}
                placeholder="Enter IP addresses here"
                onChange={(e) => setIpWhitelistAndMarkEdited(e.target.value)} />
        </div>
        {validation_message && <div className="m-4 mb-4 text-red-500">{validation_message}</div>}
        <div className="m-4 mb-4 text-right">
            <Button text="Validate" onClick={validate} />
            <Button text="Save" disabled={!can_save} onClick={updateEndpoint} loading={is_saving} highlight={true} />
        </div>
    </div>;
}

function ForwardEmailItems(props: { items: IEndpointForwardEmailItem[] }) {
    const { items } = props;

    return <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
            <tr>
                <th className="w-1" />
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Date</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">From</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Subject</th>
                <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
            </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
            {items.map((item, idx) => (
                <tr key={idx}>
                    <td><ConfirmationPill status={(item.extract_confirmations_status ?? "") as ExtractConfirmationStatus} /></td>
                    <td className="px-4 py-2 text-sm">{prettySmartDateTime(item.created_at)}</td>
                    <td className="px-4 py-2 text-sm">{item.user_first_name} {item.user_last_name} ({item.user_email})</td>
                    <td className="px-4 py-2 text-sm">{item.name}</td>
                    <td className="px-4 py-2 text-sm">{item.extract_confirmations_status ?? ""}</td>
                </tr>
            ))}
        </tbody>
    </table>;
}

export function EndpointDetail() {
    const { endpoint_uuid } = useParams<{ endpoint_uuid: string }>();
    const location = useLocation();
    const navigate = useNavigate();

    // Get tab and page from URL query params, defaulting to "extract_jobs" and 0
    const query_params = new URLSearchParams(location.search);
    const initial_tab = query_params.get("tab") || "extract_jobs";
    const initial_page = parseInt(query_params.get("page") || "0");

    // Update selected_tab initialization
    const [selected_tab, setSelectedTab] = useState<string>(initial_tab);

    // Add handler for tab changes
    const handleTabChange = (tab: string) => {
        const new_params = new URLSearchParams(location.search);
        new_params.set("tab", tab);
        navigate({ search: new_params.toString() });
        setSelectedTab(tab);
    };

    const env = useSelector(selectEnv);
    const is_sidebar_large = useSelector(selectIsSidebarLarge);
    const user = useSelector(selectUser);
    const membership = useSelector(selectMemberships);

    const [endpoint, setEndpoint] = useState<t.IEndpoint | undefined>(undefined);
    const [has_lookup_tables, setHasLookupTables] = useState<boolean>(false);
    const [endpoint_to_lookup_table_mappings, setEndpointToLookupTableMappings] = useState<t.IEndpointToLookupTable[] | undefined>(undefined);
    const [is_deleting, setIsDeleting] = useState<boolean>(false);
    const [is_cloning, setIsCloning] = useState(false);
    const [is_sending_email_digest, setIsSendingEmailDigest] = useState<boolean>(false);
    const [show_email_digest_modal, setShowEmailDigestModal] = useState(false);
    const [show_admin_details, setShowAdminDetails] = useState<boolean>(false);
    const [show_confirm, setShowConfirm] = useState(false);

    useEffect(() => {
        if (endpoint_uuid === undefined) { return; }
        BackendObj.extractions.getEndpoint({ endpoint_uuid }).then(({ endpoint }) => {
            if (endpoint !== undefined) {
                setEndpoint(endpoint);
                BackendObj.extractions.listEndpointToLookupTableMappings({ endpoint_uuid })
                    .then(({ mappings }) => { setEndpointToLookupTableMappings(mappings); })
                    .catch((error) => { console.error(error); setEndpointToLookupTableMappings(undefined); });
                Backend.getLookupTables().then((lookup_tables) => {
                    setHasLookupTables(lookup_tables.filter(lt => lt.org_uuid === endpoint.org_uuid).length > 0);
                });
            }
        });
    }, [endpoint_uuid]);

    useEffect(() => {
        if (endpoint === undefined) {
            setDocumentTitle("Endpoint", env);
        } else {
            setDocumentTitle(`Endpoint - ${endpoint.name}`, env);
        }
    }, [endpoint, env]);

    const updateWhitelist = async (ip_whitelist: string[]) => {
        if (endpoint === undefined) { return; }
        await BackendObj.extractions.updateEndpointIPWhitelist({
            endpoint_uuid: endpoint.uuid,
            ip_whitelist
        });
        setEndpoint({
            ...endpoint,
            ip_whitelist
        });
    };

    const cloneEndpoint = async () => {
        if (endpoint === undefined) { return; }
        // commit as new endpoint
        setIsCloning(true);
        try {
            const { endpoint_uuid: cloned_endpoint_uuid } = await BackendObj.extractions.createEndpoint({
                org_uuid: endpoint.org_uuid,
                user_uuid: endpoint.user_uuid,
                type: endpoint.type,
                name: `Copy of ${endpoint.name}`,
                description: endpoint.description,
                ip_whitelist: endpoint.ip_whitelist,
                details: endpoint.details,
                custom_output: endpoint.custom_output,
                output_name: endpoint.output_name,
                template_uuid: endpoint.template_uuid
            });
            if (cloned_endpoint_uuid !== undefined) {
                navigate(`/endpoint/${cloned_endpoint_uuid}`);
            }
        } catch (error) {
            console.error(error);
        }
        setIsCloning(false);
    };

    const deleteMapping = async (endpoint_to_lookup_table_uuid: string) => {
        setIsDeleting(true);
        await BackendObj.extractions.deleteEndpointToLookupTableMapping({ endpoint_to_lookup_table_uuid });
        if (endpoint_to_lookup_table_mappings !== undefined) {
            const new_endpoint_to_lookup_table_mappings = endpoint_to_lookup_table_mappings.filter((mapping) => mapping.uuid !== endpoint_to_lookup_table_uuid);
            setEndpointToLookupTableMappings(new_endpoint_to_lookup_table_mappings);
        }
        setIsDeleting(false);
    }

    const onRemoveClose = async (is_remove: boolean) => {
        setShowConfirm(false);
        if (is_remove && endpoint_uuid !== undefined) {
            setIsDeleting(true);
            // delete from backend
            await BackendObj.extractions.deleteEndpoint({ endpoint_uuid });
            // navigate back to list
            navigate("/endpoints");
        }
    };

    const sendEmailDigest = async () => {
        if (endpoint === undefined) { return; }
        setIsSendingEmailDigest(true);
        await BackendObj.extractions.sendEmailDigest({ endpoint_uuid: endpoint.uuid });
        const { endpoint: new_endpoint } = await BackendObj.extractions.getEndpoint({ endpoint_uuid: endpoint.uuid });
        if (new_endpoint !== undefined && new_endpoint.uuid === endpoint.uuid) {
            setEndpoint(new_endpoint);
        }
        setIsSendingEmailDigest(false);
    }

    if (endpoint === undefined) {
        return <div className={classNames("hidden lg:fixed lg:right-0 lg:inset-y-0 lg:flex lg:flex-row", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
            <LoadingSpinner />
        </div>;
    }

    const split_by_field_data = (endpoint.custom_output.split_by_field ?? []).map((split) => {
        const context = endpoint.template.contexts.find(c => c.uuid === split.context_uuid);
        if (context === undefined) { return undefined; }
        const field = context?.fields.find(f => f.uuid === split.field_uuid);
        const context_name = context.name.length > 0 ? context.name : `Step ${context.weight_score + 1}`;
        return `${context_name}/${field?.name}`;
    }).filter((x) => x !== undefined).join(", ");

    const is_admin = user.role === USER_ROLES.admin;
    const is_org_admin = membership.filter((m) => m.org.uuid === endpoint?.org_uuid && m.role === "admin").length > 0;

    const tabs: ITab[] = [
        { name: "Call Log", key: "extract_jobs" },
        { name: "Details", key: "details" },
        { name: "IP Whitelist", key: "ip_whitelist", hide: !(endpoint.type === ENDPOINT_TYPE.rest_api && is_org_admin) },
        { name: "API Docs", key: "api_docs", hide: !(endpoint.type === ENDPOINT_TYPE.rest_api && is_org_admin) },
        { name: "Webhook Docs", key: "webhook_docs", hide: endpoint.type === ENDPOINT_TYPE.rest_api || !is_org_admin },
        { name: "Connected Tables", key: "connected_tables", hide: !has_lookup_tables },
        { name: "History", key: "history", only_admin: true }
    ];

    const endpoint_org = membership.find(({ org }) => org.uuid === endpoint.org_uuid);

    return <div className={classNames("flex-row lg:fixed lg:right-0 lg:inset-y-0 overflow-y-auto", is_sidebar_large ? "lg:left-64" : "lg:left-20")}>
        <div className="h-20 w-full bg-white border-b border-b-sea_blue-700">
            <div className="pl-4 pr-10 py-4 flex flex-row items-start max-w-5xl">
                <Button icon={hi.ArrowLeftIcon} onClick={() => navigate("/endpoints")} />
                <div className="pl-4 flex flex-col justify-start gap-1">
                    <h2 className="flex flex-row items-center  gap-4 text-xl font-semibold leading-7 text-gray-900 sm:truncate sm:text-2xl sm:tracking-tight">
                        {endpoint.name}
                        {endpoint_org && <OrgPill name={endpoint_org.org.name} type={endpoint_org.org.type} />}
                    </h2>
                    <h2 className="text-sm text-gray-400 tracking-tight">
                        {endpoint.type === ENDPOINT_TYPE.email && "Email Integration"}
                        {endpoint.type === ENDPOINT_TYPE.rest_api && "Rest API Integration"}
                    </h2>
                </div>
                <div className="grow"></div>
                <div className="flex items-center">
                    {endpoint && is_org_admin &&
                        <ButtonGroup disabled={is_deleting || is_cloning}
                            buttons={[
                                { icon: hi.DocumentDuplicateIcon, text: "", onClick: cloneEndpoint, tooltip: "Clone" },
                                { icon: hi.PencilSquareIcon, text: "", href: `/endpoint/${endpoint.uuid}/edit`, tooltip: "Edit" },
                                { icon: hi.TrashIcon, text: "", onClick: () => setShowConfirm(true), tooltip: "Remove" }
                            ]} />}
                    {endpoint && <Button text="New Job" href={`/extraction/new/${endpoint?.uuid}`} highlight={true} />}
                    {!endpoint && <Button text="New Job" href={`/extraction/new/`} highlight={true} />}
                </div>
            </div>
        </div>

        <div className="px-10 py-7 max-w-5xl">
            <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Type</dt>
                <dd className="flex fle-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    {endpoint.type === ENDPOINT_TYPE.email && <hs.EnvelopeIcon className="h-4 w-4 mr-2 text-slate-300" />}
                    {endpoint.type === ENDPOINT_TYPE.email && "Email"}
                    {endpoint.type === ENDPOINT_TYPE.rest_api && <hs.Cog8ToothIcon className="h-4 w-4 mr-2 text-slate-300" />}
                    {endpoint.type === ENDPOINT_TYPE.rest_api && "Rest API"}
                </dd>
            </div>
            {endpoint.type === ENDPOINT_TYPE.rest_api && <div className="py-3 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Owner</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    {endpoint.user && `${endpoint.user.first_name} ${endpoint.user.last_name} (${endpoint.user.email})`}
                    {!endpoint.user && <span className="text-red-600">Owner of the integration is no longer part of organization</span>}
                </dd>
            </div>}
            <div className="pt-3 pb-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 border-b border-gray-200">
                <dt className="text-sm font-medium leading-6 text-gray-900">Template</dt>
                <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-4 sm:mt-0">
                    <Link to={`/template/${endpoint.template.uuid}`} className="hover:underline">{endpoint.template.name}</Link>
                </dd>
            </div>
            {endpoint.type === ENDPOINT_TYPE.email && <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email connector</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-4 sm:mt-0">
                    <CopyTextbox text={endpoint.email_address} email_pretty_name={endpoint.name} is_email={true} />
                </dd>
            </div>}
            {endpoint.type === ENDPOINT_TYPE.rest_api && <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Endpoint URL</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-4 sm:mt-0">
                    <CopyTextbox text={endpoint.url} is_email={false} />
                </dd>
            </div>}

            <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0  items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Forward email</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-4 sm:mt-0">
                    {endpoint.details.forward_email_address.length > 0 && <CopyTextbox text={endpoint.details.forward_email_address} is_email={true} />}
                    {endpoint.details.forward_email_address.length === 0 && "/"}
                </dd>
            </div>

            {endpoint.details.passthrough_email_address !== "" && <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0  items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Passthrough email</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-4 sm:mt-0">
                    {endpoint.details.passthrough_email_address.length > 0 && <CopyTextbox text={endpoint.details.passthrough_email_address} is_email={true} />}
                    {endpoint.details.passthrough_email_address.length === 0 && "/"}
                </dd>
            </div>}

            <div className="py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0 items-center">
                <dt className="text-sm font-medium leading-6 text-gray-900">Webhook URL</dt>
                <dd className="pr-4 text-sm leading-6 text-space_blue-600 sm:col-span-4 sm:mt-0">
                    {endpoint.details.webhook_url.length > 0 && <CopyTextbox text={endpoint.details.webhook_url} is_email={false} />}
                    {endpoint.details.webhook_url.length === 0 && "/"}
                </dd>
            </div>

            <div className="pt-6">
                <Tabs tabs={tabs} selected_tab_key={selected_tab} setSelectedTab={handleTabChange} />
            </div>

            {selected_tab === "extract_jobs" && <EndpointExtractJobs endpoint={endpoint} initial_page={initial_page} />}
            {selected_tab === "details" && <div>
                <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:gap-y-8 sm:px-0">
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Split by field</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-7 sm:mt-0">
                        {endpoint.custom_output.split_by_field === undefined && "No"}
                        {endpoint.custom_output.split_by_field !== undefined && split_by_field_data}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Store extractions</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.store_extractions ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Require confirmation</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.require_confirmation === "never" && "No"}
                        {endpoint.details.require_confirmation === "on_error" && "Only on Error"}
                        {endpoint.details.require_confirmation === "always" && "Yes"}
                    </dd>
                    {endpoint.details.require_confirmation !== "never" && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Notify on pending</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.require_confirmation_notify_on_pending === "none" && "None"}
                            {endpoint.details.require_confirmation_notify_on_pending === "users" && "Confirmation users"}
                            {endpoint.details.require_confirmation_notify_on_pending === "sender" && "Sender"}
                            {endpoint.details.require_confirmation_notify_on_pending === "all" && "All"}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Confirmation users</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.require_confirmation_users.length > 0 ? "Yes" : "No"}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Confirmation log</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.require_confirmation_log_lookup_table_uuid.length > 0 && <Link to={`/lookup_table/${endpoint.details.require_confirmation_log_lookup_table_uuid}`} className="cursor-pointer text-space_blue-600">Yes</Link>}
                            {endpoint.details.require_confirmation_log_lookup_table_uuid.length === 0 && "/"}
                        </dd>
                    </Fragment>}
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Automatic template example</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.automatic_template_example === "never" && "No"}
                        {endpoint.details.automatic_template_example === "on_error" && "Only on Error"}
                        {endpoint.details.automatic_template_example === "always" && "Yes"}
                    </dd>
                    {endpoint.type === ENDPOINT_TYPE.rest_api && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">
                            Unit of processing
                        </dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.unit_of_processing === "all" && "Whole"}
                            {endpoint.details.unit_of_processing === "page" && "Page/Sheet"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Only process attachments</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.only_process_attachments ? "Yes" : "No"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Unit of processing</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {!endpoint.details.only_process_attachments && "/"}
                            {endpoint.details.only_process_attachments && endpoint.details.unit_of_processing === "all" && "All files"}
                            {endpoint.details.only_process_attachments && endpoint.details.unit_of_processing === "file" && "File"}
                            {endpoint.details.only_process_attachments && endpoint.details.unit_of_processing === "page" && "Page/Sheet"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Reply to the sender</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_to_sender ? "Yes" : "No"}
                        </dd>
                    </Fragment>}
                    {endpoint.type === ENDPOINT_TYPE.email && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Include input with reply email</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_include_input === "no" && "No"}
                            {endpoint.details.reply_include_input === "attachments" && "Attachments only"}
                            {endpoint.details.reply_include_input === "all" && "Email and attachments"}
                        </dd>
                    </Fragment>}
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Result attachment format</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {[
                            { key: "excel", name: "Excel" },
                            { key: "csv", name: "CSV (comma)" },
                            { key: "csv-semicolon", name: "CSV (semicolon)" },
                            { key: "tsv", name: "TSV" },
                            { key: "pdf", name: "PDF" },
                            { key: "json", name: "JSON" },
                            { key: "custom", name: "Custom" }
                        ].find((f) => f.key === endpoint.details.reply_file_format)?.name || "/"}
                    </dd>
                    {endpoint.details.reply_file_format === "custom" && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Result file extension</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_file_extension}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Result file mimetype</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.reply_file_mimetype}
                        </dd>
                    </Fragment>}

                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Custom extractions filename</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.output_name.length > 0 ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Custom result template</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.custom_output.output_sheets.length > 0 ? "Yes" : "No"}
                    </dd>
                    {endpoint.type === ENDPOINT_TYPE.rest_api && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">REST API version</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.rest_api_version}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-900">Check API key</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.details.rest_api_check_api_key ? "Yes" : "No"}
                        </dd>
                    </Fragment>}
                </div>

                {endpoint.details.forward_email_address.length > 0 && <div className="px-4 pb-6 py-3 sm:grid sm:grid-cols-10 sm:gap-6 sm:gap-y-8 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-bold leading-6 text-gray-900 sm:col-span-10">Forward email details:</dt>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Daily digest</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.forward_email_frequency === "daily" ? "Yes" : "No"}
                    </dd>
                    {endpoint.forward_email_status !== undefined && <Fragment>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-2">Next digest</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-3 sm:mt-0">
                            {prettyDateTime(endpoint.forward_email_status.next_ts)}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Hour of day</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {getLocalHour(endpoint.details.forward_email_digest_hour_utc)}:00h
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Group by field</dt>
                        <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.template.contexts.flatMap((context) => context.fields)
                                .find((field) => field.uuid === endpoint.details.forward_email_digest_group_field_uuid)?.name || "/"}
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Items ready for next digest</dt>
                        <dd className="flex flex-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.forward_email_status.items_confirmed}
                            <hi.ArrowTopRightOnSquareIcon
                                className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                onClick={() => setShowEmailDigestModal(true)} />
                        </dd>
                        <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Items awaiting confirmation</dt>
                        <dd className="flex flex-row items-center pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                            {endpoint.forward_email_status.items_pending}
                            <hi.ArrowTopRightOnSquareIcon
                                className="h-4 w-4 ml-2 text-gray-400 hover:text-gray-600 cursor-pointer"
                                onClick={() => setShowEmailDigestModal(true)} />
                        </dd>
                        {is_org_admin && <Fragment>
                            <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Initiate daily digest</dt>
                            <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                                <Button text="Send now" loading={is_sending_email_digest} onClick={sendEmailDigest} />
                            </dd>
                        </Fragment>}
                    </Fragment>}
                </div>}

                {endpoint.details.passthrough_email_address.length > 0 && <div className="px-4 pb-6 py-3 sm:grid sm:grid-cols-10 sm:gap-6 sm:gap-y-8 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-bold leading-6 text-gray-900 sm:col-span-10">Passthrough email details:</dt>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Custom email subject</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.passthrough_email_subject.length > 0 ? "Yes" : "No"}
                    </dd>
                </div>}

                {endpoint.details.webhook_url.length > 0 && <div className="px-4 pb-6 py-3 sm:grid sm:grid-cols-10 sm:gap-6 sm:gap-y-8 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-bold leading-6 text-gray-900 sm:col-span-10">Webhook details:</dt>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Include input with webhook call</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_include_input ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Webhook format version</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_version}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Webhook retry count</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_retry_count}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Debug logging for webhook call</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_store_payload === "all" && "All"}
                        {endpoint.details.webhook_store_payload === "none" && "None"}
                        {endpoint.details.webhook_store_payload === "only_failed" && "Only Failed"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">Fail notify email</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {endpoint.details.webhook_notify_on_fail_email_address}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 text-gray-900 sm:col-span-3">API key in HTTP header</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-500 sm:col-span-2 sm:mt-0">
                        {(endpoint.details.webhook_api_key === undefined || endpoint.details.webhook_api_key.length === 0) && "/"}
                        {endpoint.details.webhook_api_key !== undefined && endpoint.details.webhook_api_key.length > 0 && "Yes"}
                    </dd>
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 sm:grid sm:grid-cols-10 sm:gap-4 sm:px-0 border-t border-gray-200">
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400">Truncate Long Text</dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                        {endpoint.details.truncate_long_text ? "Yes" : "No"}
                    </dd>
                    <dt className="pl-4 text-sm font-medium leading-6 sm:col-span-3 text-gray-400"></dt>
                    <dd className="pr-4 text-sm leading-6 text-gray-300 sm:col-span-2 sm:mt-0">
                    </dd>
                </div>}

                {!show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Show admin details" onClick={() => setShowAdminDetails(true)} icon={hi.ChevronDownIcon} />
                </div>}

                {show_admin_details && is_admin && <div className="px-4 py-6 border-t border-gray-200">
                    <Button text="Hide admin details" onClick={() => setShowAdminDetails(false)} icon={hi.ChevronUpIcon} />
                </div>}
            </div>}

            {selected_tab === "ip_whitelist" && <IpWhiteList endpoint={endpoint} updateWhitelist={updateWhitelist} />}
            {selected_tab === "api_docs" && <div>
                <EndpointDocs endpoint={endpoint} />
                <WebhookDocs />
            </div>}
            {selected_tab === "webhook_docs" && <WebhookDocs />}
            {selected_tab === "history" && <div className="px-4 py-4">
                <AuditLogHistory entities={[{ uuid: endpoint.uuid, type: "endpoint", name: endpoint.name }]} />
            </div>}
            {selected_tab === "connected_tables" && <div className="px-4 py-6">
                <div className="pb-3 flex flex-row items-center w-full">
                    <div className="flex-1" />
                    {is_org_admin && <Button icon={hi.PlusCircleIcon} text="Connect" href={`/endpoint-lookup-table-mapping/create/endpoint/${endpoint.uuid}`} />}
                </div>
                <EndpointToLookupTable
                    can_edit={is_org_admin}
                    source_type="endpoint"
                    source_uuid={endpoint.uuid}
                    endpoint_to_lookup_table_mappings={endpoint_to_lookup_table_mappings}
                    is_deleting={is_deleting}
                    deleteMapping={deleteMapping} />
            </div>}
        </div>

        {endpoint.forward_email_status && <SidePanel title="Digest items" size="2xl" open={show_email_digest_modal} onClose={() => setShowEmailDigestModal(false)}>
            <ForwardEmailItems items={endpoint.forward_email_status.items} />
        </SidePanel>}

        <ConfirmModal open={show_confirm}
            title="Remove en"
            message={["Are you sure you want to remove this integration?"]}
            confirm="Remove"
            onClose={onRemoveClose} />
    </div>;
}